import { FaBriefcaseMedical } from "react-icons/fa";


function SpecialitiesHome() {
    const allDoctors = [
        { id: 1, name: 'Dr. Emad Mohamed', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'Specialist Dermatologist', languages: ['Arabic', 'English'] },
        { id: 2, name: 'Dr. Omar AlGethami', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 3, name: 'Dr. Neha Sachdev', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        // { id: 4, name: 'Dr. Ritul Agarwal', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'Prosthodontist And Implantologist', languages: ['Arabic', 'English'] },
        { id: 5, name: 'Dr. Anil Saragur', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'Specialist Orthodontics', languages: ['Arabic', 'English'] },
        // { id: 6, name: 'Dr. Mina Charopeme', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'Specialist Pediatric Dentist', languages: ['Arabic', 'English'] },
        // { id: 7, name: 'Dr. Mirna Abdelmesieh', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 8, name: 'Jennylyn Evangelista', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'Beauty and Laser Therapist', languages: ['Arabic', 'English'] },

        { id: 9, name: 'Dr. Pierfrancesco Mancini', image: '/Doctors/user.png', branch: "Jumeirah Branch", speciality: 'Consultant Plastic Surgery', languages: ['Arabic', 'English'] },
        { id: 10, name: 'Dr. Carmelo Crisafulli', image: '/Doctors/user.png', branch: "Jumeirah Branch", speciality: 'Specialist Plastic Surgery', languages: ['Arabic', 'English'] },
        { id: 11, name: 'Dr. Rokea Alrifaee', image: '/Doctors/user.png', branch: "Jumeirah Branch", speciality: 'Specialist Dermatology', languages: ['Arabic', 'English'] },
        { id: 12, name: 'SP. Alena Kuzima', image: '/Doctors/user.png', branch: "Jumeirah Branch", speciality: 'Specialist Dermatology', languages: ['Arabic', 'English'] },

        { id: 13, name: 'Dr. Khaled Rezk', image: '/Doctors/user.png', branch: "Marina Branch", speciality: 'Specialist Plastic Surgery', languages: ['Arabic', 'English'] },
        // { id: 14, name: 'Dr. Ahmad Maadarani', image: '/Doctors/user.png', branch: "Marina Branch", speciality: 'General Practitioner Aesthetic', languages: ['Arabic', 'English'] },
        { id: 15, name: 'Dr. Lina Saleh', image: '/Doctors/user.png', branch: "Marina Branch", speciality: 'General Practitioner Aesthetic', languages: ['Arabic', 'English'] },
        { id: 16, name: 'SP. Alla Abu Baker', image: '/Doctors/user.png', branch: "Marina Branch", speciality: 'Beauty and Laser Therapist', languages: ['Arabic', 'English'] },
        { id: 17, name: 'SP. Joy Cadias', image: '/Doctors/user.png', branch: "Marina Branch", speciality: 'Beauty and Laser Therapist', languages: ['Arabic', 'English'] },

        { id: 18, name: 'Dr. Said Hassan', image: '/Doctors/user.png', branch: "Ras Al Khaima Branch", speciality: 'General and Laparoscopic Surgeon', languages: ['Arabic', 'English'] },
        { id: 19, name: 'Dr. Tareq Boghdady', image: '/Doctors/user.png', branch: "Ras Al Khaima Branch", speciality: 'Specialist Dermatology', languages: ['Arabic', 'English'] },
        { id: 20, name: 'Dr. Hussam Kayali', image: '/Doctors/user.png', branch: "Ras Al Khaima Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },

        { id: 21, name: 'Dr. Khaled Shahin', image: '/Doctors/user.png', branch: "Sharjah Branch", speciality: 'Specialist Dermatology', languages: ['Arabic', 'English'] },
        { id: 22, name: 'Dr. Asmaa Hendawi', image: '/Doctors/user.png', branch: "Sharjah Branch", speciality: 'Specialist Orthodontist', languages: ['Arabic', 'English'] },
        { id: 23, name: 'Dr. Razan Sedki', image: '/Doctors/user.png', branch: "Sharjah Branch", speciality: 'Specialist Prosthodontics', languages: ['Arabic', 'English'] },
        { id: 24, name: 'Dr. Nijad Naeisseh', image: '/Doctors/user.png', branch: "Sharjah Branch", speciality: 'Specialist Implantologist', languages: ['Arabic', 'English'] },
        { id: 25, name: 'Dr. Hazem Kayali', image: '/Doctors/user.png', branch: "Sharjah Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 26, name: 'Dr. Muna Abu Baker', image: '/Doctors/user.png', branch: "Sharjah Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 27, name: 'Dr. Riza Khirandish', image: '/Doctors/user.png', branch: "Sharjah Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 28, name: 'Dr. Ahmad Sirafi', image: '/Doctors/user.png', branch: "Sharjah Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 29, name: 'Dr. Nouraldin Al Farra', image: '/Doctors/user.png', branch: "Sharjah Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },

        { id: 30, name: 'Dr. Rehab Mzoughi', image: '/Doctors/user.png', branch: "Ajman Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 31, name: 'Dr. Tareq Alkurd', image: '/Doctors/user.png', branch: "Ajman Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 32, name: 'Dr. Sara Hussein', image: '/Doctors/user.png', branch: "Ajman Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
    ];

    const icons = [

    ]

    const specialities = Array.from(new Set(allDoctors.map((doctor) => doctor.speciality)));
    return (
    <div className="services" >
        <div className="container">
            <div className="row">
                <div className="col text-center">
                    <div className="section_title_container">
                        <div className="section_title"><h2>Our specialities</h2></div>
                    </div>
                </div>
            </div>
            <div className="row services_row">

                {/* Service 1 */}
               {specialities.map((speciality, index) => 
                <div className="col-xl-4 col-md-6 service_col">
                    <div className="service text-center">
                        <div className="service">
                            <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                <div className="icon"><FaBriefcaseMedical color="white" size={30}/></div>
                            </div>
                               <div className="service_title">{speciality}</div>
                            {/* <div className="service_text">
                                <p>Odio ultrices ut. Etiam ac erat ut enim maximus accumsan vel ac nisl. Duis feugiat bibendum orci, non elementum urna.</p>
                            </div> */}
                        </div>
                    </div>
                </div>
                 )}
                {/* Service 2 */}

            </div>
        </div>
    </div>)
}
export default SpecialitiesHome