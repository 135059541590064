import AboutHero from "../AboutHero/AboutHero"
import WhyUs from "../WhyUs/WhyUs"
function AboutUs() {
    return (
        <div style={{marginTop: "50px"}}>
            <img src="/images/aboutUsBanner.jpeg" style={{ width: "100%"}}/>
            <AboutHero />
            <WhyUs />
        </div>
       
    )

}
export default AboutUs