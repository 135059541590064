import logo from './logo.svg';
import './App.css';
import Slider from './Components/Slider/Slider';
import Home from './Components/Home/Home';
import Navbar from './Components/Navbar/Navbar';
import AboutUs from './Components/AboutUs/AboutUs';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import Contact from './Components/Contact/Contact';
import DoctrPage from './Components/DoctorPage/DoctorPage';
import SpecialitiesPage from './Components/SpecialitiesPage/SpecialitiesPage';
import Services from './Components/Services/Services';
import Footer from './Components/Footer/Footer';
import DocInfo from './Components/DocInfo/DocInfo';
import DentistryServices from './Components/DentistryServices/DentistryServices';
import DentistryInfo from './Components/DentistryInfo/DentistryInfo';
import Treatments from './Components/Treatments/Treatments';
import Privacy from './Components/Privacy/Privacy';
import StickyButton from './Components/StickyButton/StickyButton';
import { FaWhatsapp } from 'react-icons/fa';
import { FaCalendarAlt } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import Appointment from './Components/Appointment/Appointment';
import ThankYouPage from './Components/ThankYouPage/ThankYouPage';
import { Helmet } from 'react-helmet';


function App() {

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "MedicalOrganization",
    "name": "Cosmo Health Medical Center",
    "url": "https://www.cosmohealthmc.ae/",
    "logo": "https://cosmohealthmc.ae/logo.png",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+971 50 200 8364",
      "contactType": "Customer Service"
    },
    "sameAs": [
      "https://www.instagram.com/cosmohealthmc/",
      "https://www.tiktok.com/@cosmohealthmc",
      "https://www.facebook.com/CosmoHealthMedicalCenter"
    ],
    "openingHours": "9 am to 9 pm",
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday"
        ]
      }
    ],
    "location": [
      {
        "@type": "MedicalClinic",
        "name": "Cosmo Health Medical Center- Marina",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Dusit Princess Residence",
          "addressLocality": "Marina",
          "addressRegion": "Al Emreef Street",
          "postalCode": "00000",
          "addressCountry": "UAE"
        },
        "telephone": "+971 44 150 500"
      },
      {
        "@type": "MedicalClinic",
        "name": "Cosmo Health Medical Center- Mirdif",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "2nd floor, Avenue Mall",
          "addressLocality": "Mirdif",
          "addressRegion": "Dubai",
          "postalCode": "0000",
          "addressCountry": "UAE"
        },
        "telephone": "+971 44 150 550"
      },
      {
        "@type": "MedicalClinic",
        "name": "Cosmo Health Medical Center- Sharjah",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Villa B 568",
          "addressLocality": "Culture Roundabout",
          "addressRegion": "Sharjah",
          "postalCode": "00000",
          "addressCountry": "UAE"
        },
        "telephone": "+971 65 669 910"
      },
      {
        "@type": "MedicalClinic",
        "name": "Cosmo Health Medical Center- Ajman",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "V5, Al Ittihad road",
          "addressLocality": "47755 11687 Jurf 2",
          "addressRegion": "Ajman",
          "postalCode": "00000",
          "addressCountry": "UAE"
        },
        "telephone": "+971 44 150 515"
      },
      {
        "@type": "MedicalClinic",
        "name": "Cosmo Health Medical Center- RAK",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Al Ettihad Street",
          "addressLocality": "Alrafaea",
          "addressRegion": "Ras Al Khaimah",
          "postalCode": "00000",
          "addressCountry": "UAE"
        },
        "telephone": "+971 44 150 544"
      }
    ]
  };

  const [activeComponent, setActiveComponent] = useState('home');
  const [activeService, setActiveService] = useState('uno dos tres quatro chinqo');
  const [activeDoctor, setActiveDoctor] = useState();
  const [activeDentistry, setActiveDentistry] = useState();
  const [activeTreatment, setActiveTreatment] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const navigate = useNavigate();
  const handleComponentChange = (component, doctor, dentistry, treatment) => {
    console.log(treatment)
    setActiveTreatment(treatment)
    console.log(dentistry)
    setActiveComponent(component);
    setActiveDoctor(doctor)
    setActiveDentistry(dentistry)
    window.scrollTo(0, 0)
  };

  const changeService = (s) => {
    if (s == "DENTISTRY") {
      console.log("ggg")
      navigate("/dentistry");
    }
    else {
      setActiveService(s)
      navigate("/services");
      setActiveComponent('services');
    }
    window.scrollTo(0, 0)
    // console.log(s)
  }
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.cosmohealthmc.ae" />
        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>
      <div className="super_container">
        <Appointment show={show} handleClose={handleClose} />
        {/* <Navigation /> */}
        <Navbar onComponentChange={handleComponentChange} activeComponent={activeComponent} />
        {/* First Sticky Button */}
        <div className="sticky-button">
          <a href="https://wa.me/971502008364" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp size={30} />
          </a>
        </div>

        {/* Second Sticky Button */}
        <div className="sticky-button second">
          <a href="#" rel="noopener noreferrer" onClick={() => handleShow()}>
            <FaCalendarAlt size={30} />
          </a>
        </div>

        {/* Third Sticky Button */}
        <div className="sticky-button third">
          <a href="tel:+971 58 510 0850" target="_blank" rel="noopener noreferrer">
            <IoCall size={30} />
          </a>
        </div>

        <Routes>
          <Route path="/" element={<Home onComponentChange={handleComponentChange} changeService={changeService} />} />
          <Route path="/about" element={<AboutUs onComponentChange={handleComponentChange} />} />
          <Route path="/contact" element={<Contact onComponentChange={handleComponentChange} />} />
          {/* <Route path="/doctor" element={<DoctrPage onComponentChange={handleComponentChange} />} /> */}
          <Route path="/specialities" element={<SpecialitiesPage onComponentChange={handleComponentChange} changeService={changeService} />} />
          <Route path="/services" element={<Services onComponentChange={handleComponentChange} service={activeService} />} />
          <Route path="/info" element={<DocInfo onComponentChange={handleComponentChange} doctor={activeDoctor} />} />
          <Route path="/dentistry" element={<DentistryServices onComponentChange={handleComponentChange} />} />
          <Route path="/dentistryInfo" element={<DentistryInfo activeDentistry={activeDentistry} />} />
          <Route path="/treatment/:service" element={<Treatments activeTreatment={activeTreatment} />} />
          <Route path="/privacy" element={<Privacy onComponentChange={handleComponentChange} />} />
          <Route path="/thankyou" element={<ThankYouPage />} />

          <Route path="*" element={<Navigate to="/" />} />


        </Routes>
        <Footer />
      </div>


    </>
  );
}

export default App;
