import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { FaTiktok } from "react-icons/fa";
import { FaSnapchatGhost } from "react-icons/fa";

import logo from './Cosmo.png'

function Footer() {
    return (
        <footer className="footer" style={{ marginTop: "50px" }}>
            <div className="footer_content">
                <div className="container" >
                    <div className="row" style={{ marginTop: "-100px" }}>
                        <div className="col-lg-3 footer_col">
                            <div className="footer_about">
                                <div className="footer_logo">
                                    <img src={logo} style={{ width: "300px", marginBottom: '-40px' }} />

                                </div>

                            </div>

                            <div className="footer_contact" style={{ marginBottom: "-40px" }}>
                                <Link to="/privacy" style={{ color: "white", marginBottom: "50px", marginTop: "-30px" }}>Privacy Policy</Link>

                                <div style={{marginTop: '30px'}}>
                                    <div className="footer_title">Social Channels</div>
                                    <div className="social" style={{ marginTop: "20px" }}>
                                        <ul className="d-flex flex-row align-items-center justify-content-start">
                                            <li><a target="_blank" href="https://www.instagram.com/cosmohealthmc/"><i className="fa fa-facebook" aria-hidden="true"><FaInstagram /></i></a></li>
                                            <li><a target="_blank" href="https://www.facebook.com/CosmoHealthMedicalCenter/"><i className="fa fa-facebook" aria-hidden="true"><FaFacebookF /></i></a></li>
                                            <li><a target="_blank" href="https://www.snapchat.com/add/cosmohealthmc?locale=bn-BD"><i className="fa fa-facebook" aria-hidden="true"><FaSnapchatGhost /></i></a></li>
                                            <li><a target="_blank" href="https://www.tiktok.com/@cosmohealthmc?_t=8nsBzvLUgp5&_r=1"><i className="fa fa-facebook" aria-hidden="true"><FaTiktok /></i></a></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </div>
                        {/* <div className="col-lg-3 footer_col">
                            <div className="footer_contact">
                                <div className="footer_title">Contact Info</div>
                                <div className="social " style={{ marginTop: "20px" }}>
                                    <ul className="d-flex flex-row align-items-center justify-content-start">
                                        <li><a target="_blank" href="https://www.instagram.com/cosmohealthmc/"><i className="fa fa-facebook" aria-hidden="true"><FaInstagram /></i></a></li>
                                        <li><a target="_blank" href="https://www.facebook.com/CosmoHealthMedicalCenter/"><i className="fa fa-facebook" aria-hidden="true"><FaFacebookF /></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-9 footer_col">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="footer_location">
                                        {/* <div className="footer_title">Our Locations</div> */}
                                        <ul className="locations_list">
                                            <li style={{ height: "100px", marginBottom: "45px" }}>
                                                <div className="location_title">Mirdif Branch</div>
                                                <div className="location_text">2nd floor, Avenue Mall, Mirdif</div>
                                                <div className="location_text">+ 971 44 150 550
                                                </div>

                                            </li>
                                            <li style={{ height: "100px", marginBottom: "45px" }}>
                                                <div className="location_title">Jumeirah Branch</div>
                                                <div className="location_text">890 Al Wasl Road, Umm Suqeim, Umm Suqeim 1</div>
                                                <div className="location_text">+ 971 44 150 500
                                                </div>

                                            </li>
                                            {/* <li style={{ height: "100px", marginBottom: "45px" }}>
                                                <div className="location_title">Marina Branch</div>
                                                <div className="location_text">Dusit Princess Residence, Marina, Al Emreef Street</div>
                                                <br></br>
                                                <div className="location_text">+ 971 44 150 500
                                                </div>

                                            </li> */}

                                        </ul>

                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="footer_location">
                                        {/* <div className="footer_title" style={{ visibility: "hidden" }}>Our Locations</div> */}
                                        <ul className="locations_list">
                                            <li style={{ height: "100px", marginBottom: "45px" }}>
                                                <div className="location_title">Ajman Branch</div>
                                                <div className="location_text"> V5, Al Ittihad road
                                                    47755 11687
                                                    Jurf 2, Ajman</div>
                                                <div className="location_text">+971 44 150 515
                                                </div>

                                            </li>
                                            <li style={{ height: "100px", marginBottom: "45px" }}>
                                                <div className="location_title">RAK Branch</div>
                                                <div className="location_text">Al Ettihad Street - Alrafaea - Ras Al Khaimah</div>

                                                <div className="location_text">+ 971 44 150 544
                                                </div>

                                            </li>
                                            {/* <li style={{ height: "100px", marginBottom: "45px" }}>
                                                <div className="location_title">Sharjah Branch</div>
                                                <div className="location_text">Villa B 568, Culture Roundabout, Al Ramla - Sharjah</div>
                                                <div className="location_text">+971 65 669 910
                                                </div>

                                            </li> */}

                                        </ul>

                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="footer_location">
                                        {/* <div className="footer_title" style={{ visibility: "hidden" }}>Our Locations</div> */}
                                        <ul className="locations_list">
                                            <li style={{ height: "100px", marginBottom: "45px" }}>
                                                <div className="location_title">Sharjah Branch</div>
                                                <div className="location_text">Villa B 568, Culture Roundabout, Al Ramla </div>
                                                <div className="location_text">+971 65 669 910
                                                </div>

                                            </li>
                                            <li style={{ height: "100px", marginBottom: "45px" }}>
                                                <div className="location_title">Marina Branch</div>
                                                <div className="location_text">Dusit Princess Residence, Marina, Al Emreef Street</div>
                                                {/* <br></br> */}
                                                <div className="location_text">+ 971 44 150 500
                                                </div>

                                            </li>
                                            {/* <li style={{ height: "100px", marginBottom: "45px" }}>
                                                <div className="location_title">Sharjah Branch</div>
                                                <div className="location_text">Villa B 568, Culture Roundabout, Al Ramla - Sharjah</div>
                                                <div className="location_text">+971 65 669 910
                                                </div>

                                            </li> */}

                                        </ul>

                                    </div>
                                </div>


                            </div>

                        </div>

                    </div>
                    <div className="row" style={{ marginTop: "50px" }}>
                        <p>© Copyright 2024 Cosmo Health Medical Center. All Rights Reserved</p>
                    </div>
                </div>
            </div>

        </footer>
    )

}
export default Footer