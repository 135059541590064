import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaWeight } from "react-icons/fa";
import "./CardsHome.css"
import React, { useState, useRef, useEffect } from 'react';

function CardsHome() {

    const [showCard, setShowCard] = useState(false);
    const [showCard2, setShowCard2] = useState(false);
    const [showCard3, setShowCard3] = useState(false);
    const cardRef = useRef(null);
    const cardRef2 = useRef(null);
    const cardRef3 = useRef(null);

    const handleClick = () => {
        setShowCard(!showCard);
        setShowCard2(false);
        setShowCard3(false);

    };

    useEffect(() => {
        if (showCard && cardRef.current) {
          const cardPosition = cardRef.current.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = cardPosition - 200; // Adjusted for a 10px offset
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }

        if (showCard2 && cardRef2.current) {
            const cardPosition = cardRef2.current.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = cardPosition - 200; // Adjusted for a 10px offset
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            });
          }

          if (showCard3 && cardRef3.current) {
            const cardPosition = cardRef3.current.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = cardPosition - 200; // Adjusted for a 10px offset
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            });
          }
      }, [showCard, showCard2, showCard3]);

    const handleClick2 = () => {
        setShowCard2(!showCard2);
        setShowCard(false);
        setShowCard3(false);
    };

    const handleClick3 = () => {
        setShowCard3(!showCard3);
        setShowCard(false);
        setShowCard2(false);
    };

    return (
        <Container >
            <div className="row " style={{ marginTop: "250px" }}>
                <div className="col text-center">
                    <div className="section_title_container">
                        <div className="section_title" style={{ marginBottom: "-100px" }}>
                            <h2 style={{ fontSize: '25px', color: "#ab7f21" }}>
                                Our Core Aesthetic and Medical Functions</h2></div>
                    </div>
                </div>
            </div>
            <Row style={{ marginBottom: "50px", marginTop: "-50px" }} className='justify-content-md-center'>
                <Col lg={4} onClick={handleClick}>

                    <figure className="snip" >
                        <img
                            className="snip__image"
                            src="Treatments/1.jpg"
                            alt="card"
                            // style={{width: "600px"}}

                        />
                        <figcaption className="snip__figcaption">
                            <h3 className="snip__title">Bariatric Surgery, Gastric Balloon, and Weight Loss At Cosmo Health
                            </h3>
                            {/* <p className="snip__text">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            </p> */}
                        </figcaption>
                        <a className="snip__link" ></a>
                    </figure>


                </Col>
                <Col lg={4} onClick={handleClick2}>
                    <figure className="snip" >
                        <img
                            className="snip__image"
                            src="Treatments/2.jpg"
                            alt="card"

                        />
                        <figcaption className="snip__figcaption">
                            <h3 className="snip__title">Plastic Surgery, Dermatology, and Anti-Aging Solutions
                            </h3>
                            {/* <p className="snip__text">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            </p> */}
                        </figcaption>
                        <a className="snip__link"></a>
                    </figure>
                </Col>
                <Col lg={4} onClick={handleClick3}>
                    <figure className="snip" >
                        <img
                            className="snip__image"
                            src="Treatments/3.jpg"
                            alt="card"

                        />
                        <figcaption className="snip__figcaption">
                            <h3 className="snip__title">Comprehensive Dentistry Services
                            </h3>
                            {/* <p className="snip__text">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            </p> */}
                        </figcaption>
                        <a className="snip__link" ></a>
                    </figure>
                </Col>
            </Row>
            <Row style={{ marginBottom: "150px" }}>
                {showCard && (
                    <Card ref={cardRef} style={{ width: '100%', marginBottom: "25px", backgroundColor: "#ab7f21" }}>
                        <Card.Body>
                            {/* <Card.Subtitle className="mb-2" style={{ color: "white" }}><FaWeight size={45}/></Card.Subtitle> */}
                            <Card.Title style={{ color: "white" }}>Bariatric Surgery, Gastric Balloon, and Weight Loss At Cosmo Health</Card.Title>
                            <Card.Text style={{ color: "white" }}>
                                We understand the challenges of achieving sustainable weight loss. Our comprehensive bariatric surgery and gastric balloon programs are designed to provide effective, long-term solutions for weight management. Our experienced team of bariatric specialists offers personalized care to guide patients through their weight loss journey, ensuring safety and success. Whether considering gastric balloon procedures or other bariatric surgery options, Cosmo Health is dedicated to helping individuals achieve their weight loss goals and improve their overall health and well-being.
                            </Card.Text>
                            {/* <Card.Link href="#">Card Link</Card.Link>
                        <Card.Link href="#">Another Link</Card.Link> */}
                        </Card.Body>
                    </Card>
                )}
                {showCard2 && (
                    <Card ref={cardRef2} style={{ width: '100%', marginBottom: "25px", backgroundColor: "#ab7f21" }}>
                        <Card.Body>
                            {/* <Card.Subtitle className="mb-2" style={{ color: "#ab7f21" }}>Card Subtitle</Card.Subtitle> */}
                            <Card.Title style={{ color: "white" }}>Plastic Surgery, Dermatology, and Anti-Aging Solutions </Card.Title>
                            <Card.Text style={{ color: "white" }}>
                                Cosmo Health is a premier destination for individuals seeking top-notch plastic surgery, dermatology, and anti-aging treatments. Our board-certified plastic surgeons and dermatologists are committed to delivering exceptional results, utilizing the latest techniques and technologies to address a wide range of aesthetic concerns. From surgical procedures to non-invasive treatments such as Botox and fillers, our team provides personalized care to help clients achieve their desired rejuvenation and anti-aging goals with natural-looking outcomes.
                            </Card.Text>
                            {/* <Card.Link href="#">Card Link</Card.Link>
                        <Card.Link href="#">Another Link</Card.Link> */}
                        </Card.Body>
                    </Card>
                )}
                {showCard3 && (
                    <Card ref={cardRef3} style={{ width: '100%', marginBottom: "-35px", backgroundColor: "#ab7f21" }}>
                        <Card.Body>
                            {/* <Card.Subtitle className="mb-2" style={{ color: "#ab7f21" }}>Card Subtitle</Card.Subtitle> */}
                            <Card.Title style={{ color: "white" }}>Comprehensive Dentistry Services</Card.Title>
                            <Card.Text style={{ color: "white" }}>
                                In addition to our renowned medical and aesthetic offerings, Cosmo Health is proud to provide comprehensive dentistry services. Our skilled dental professionals offer a full spectrum of dental care, including preventive, restorative, and cosmetic treatments. With a focus on patient comfort and oral health, our dental team delivers exceptional care, utilizing advanced techniques to address a variety of dental needs. Whether seeking routine check-ups, teeth whitening, or advanced restorative procedures, patients can trust Cosmo Health for top-quality dental services in a welcoming and patient-centric environment.
                            </Card.Text>
                            {/* <Card.Link href="#">Card Link</Card.Link>
                        <Card.Link href="#">Another Link</Card.Link> */}
                        </Card.Body>
                    </Card>
                )}
            </Row>
        </Container>

    )
}
export default CardsHome