import { Col, Row, Tab } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import React from 'react';

function Services({ service }) {

    const data = {
        'PLASTIC SURGERY': {
            '': ["Transform your appearance and boost your confidence with Cosmo Health Medical Centers' advanced plastic surgery options. Our board-certified plastic surgeons specialize in a wide range of procedures, including body contouring, breast augmentation, and facial rejuvenation. With a focus on achieving natural-looking results, we are dedicated to understanding your aesthetic goals and providing personalized care. At Cosmo Health Medical Centers, our commitment to precision and care ensures that each patient receives the highest quality of service and achieves their desired transformation."],
            'THE FACE': [
                'Fat Transfer to the face',
                'Face lift',
                'Eyelids (Blepharoplasty)',
                'Brow lift',
                'Neck Lift',
                'Double Chin correction',
                'Rhinoplasty',
                'Cheek Augmentation',
                'Dimples creation',
                'Buccal Fat Removal',
                'Otoplasty (Ear Reshaping)',
                'Dermabrasion',
                'Permanent fillers removal',
                'Lip lift',
                'Lip reduction',
                'Neck Bands "Platysma” Correction',
            ],
            'THE BODY': [
                'Body contouring / Body reshaping',
                'Lower Body Lift',
                'Liposuction',
                'Arm lift',
                'Abdominoplasty (Tummy tuck)',
                'Lipoabdominoplasty (Advanced tummy tuck)',
                'Thigh lift',
                'Fat Hump Removal (Buffalo Hump)',
                'Calf improvement by implant/fat graft',
                'Buttock Augmentation',
                'Gluteoplasty (Butt lift)',
                'Fat grafting',
                'High definition liposculpture & vaser',
            ],
            'THE BREAST': [
                'Breast augmentation with implants or fat grafting',
                'Breast lift with or without implants',
                'Breast reduction',
                'Breast implants exchange',
                'Nippleplasty',
                'Areola reduction',
                'Breast reconstruction post mastectomy',
                'Male breast reduction (gynecomastia)',
                'Pectoral implants',
            ],

        },
        'DERMATOLOGY & ANTI AGING': {
            '': ["At Cosmo Health Medical Centers, we offer advanced Botox and filler treatments as part of our comprehensive dermatology and anti-aging solutions. Our skilled dermatologists and skincare specialists are adept at using these non-invasive procedures to address various skin concerns and promote a youthful, radiant appearance. Whether it's the reduction of fine lines and wrinkles or enhancing facial contours, our Botox and filler treatments are tailored to meet individual needs, ensuring natural-looking and satisfying results. With a focus on patient satisfaction and visible outcomes, Cosmo Health Medical Centers stand as a trusted destination for those seeking effective anti-aging and skincare treatments. We aim to provide patients with a holistic approach to achieving their aesthetic goals, backed by the latest technologies and expert care. Whether it's for wrinkle reduction, facial rejuvenation, or overall skin enhancement, our Botox and filler treatments are designed to help individuals regain confidence in their appearance and embrace a more youthful and rejuvenated look."],
            'THE FACE': [
                'Injectables',
                'HA Fillers',
                'Collagen biostimulators (Sculptra, Radiesse)',
                'Skin Boosters',
                'Profhilo',
                'Profhydro',
                '3D facelift',
                'Mesotherapy (Amber, Aminoacids, Peptides, HA)',
                'Botox',
                'Thread Lift',
                'Texas - jawline contouring',
                'Non-surgical nose reshaping',
                'Platelet-rich plasma therapy (PRP)',
                'Nefertiti - neck rejuvenation',
                'Chemical peels',
                'Snow White treatment for pigmentation',
                'Mona Lisa - smile enhancement',
                'Dermapen skin rejuvenation',
            ],

            'GENERAL DERMATOLOGY': [
                'Examination and treatment of skin conditions',
                'Electrocauterization',
                'Birthmark removal',
                'Treatment of vascularities',
                'Dermoscopy mole examination / excision',
                'Scar treatment',
            ],

        },
        'HAIR LOSS': [
            "Combat hair loss effectively with Cosmo Health Medical Centers’ tailored treatments and therapies. Our dedicated team of hair restoration experts offers innovative solutions to restore your hair's vitality and appearance. Through personalized treatment plans and advanced techniques such as PRP therapy and hair transplants, we ensure natural-looking and long-lasting results. At Cosmo Health Medical Centers, we understand the impact of hair loss and are committed to helping our patients regain their confidence and achieve a fuller head of hair.",
            'Hair loss treatment (Crown on your head)',
            'Mesotherapy for hair',
            'Revage laser therapy',
            'PRP hair treatment (Platelet rich plasma)',
            'Dry or oily hair and scalp',
            'Excessive scalp itching',
            'Damage from coloring, chemicals, or straightening of the hair',
            'Ketox treatment',
            'Rigenera ™ Hair loss treatment',
            'Hair loss treatment "Crown on your head"',
        ],
        'MEDICAL SPA': {
            "": ["Indulge in the ultimate relaxation and rejuvenation experience at Cosmo Health Medical Spa. Our luxurious spa offers a wide range of therapeutic and cosmetic services designed to enhance your beauty, relieve stress, and improve overall wellness. From rejuvenating facials to body sculpting treatments, our skilled aestheticians and therapists provide personalized care in a serene and welcoming environment. Our Medical Spa is dedicated to helping you look and feel your best, inside and out."],
            'THE FACE': [
                'HydraFacial MD',
                'Cleopatra facial',
                'Al Shehana signature facial',
                'Medical facial (customized acne treatment)',
                'Red carpet facial',
                'Stamping facial',
                'Derma roller facial',
                'Microdermabrasion',
                'Caci quantum face lift',
                'Ketox hair treatment',
                'Hydra Gold',
                'White Linen Facial',
                'The Signature Facial',
                'Crystal Peel',
                'Exilis Face',
                'Laser hair removal',
                'Laser tattoo removal',
                'Treatment for face rejuvenation',
                'Treatment of spider & varicose veins',
                'Rioblush - carboxy therapy for dark circles and localized fat adiposits',
                'Mesotherapy (Stretch marks, Fat dissolving, cellulite)',
                'Cellina fractionated radiofrequency skin tightening',
                'Treatment of axillary & palms-hyperhidrosis',
            ],
            'THE BODY/SKIN': [
                'Laser hair removal',
                'Laser tattoo removal',
                'Treatment for face rejuvenation',
                'Treatment of spider & varicose veins',
                'Rioblush – carboxy therapy for dark circles and localised fat adiposits',
                'Mesotherapy (Stretch marks, Fat dissolving, cellulite)',
                'Cellina fractionated radiofrequency skin tightening',
                'Treatment of axillary & palms-hyperhidrosis'
            ],
            'THE BODY': [
                'CoolSculpting non-surgical fat reduction',
                'CACI quantum',
                'Body wrap',
                'Body massage',
                'Lymphatic drainage massage',
                'Skin pigmentation body treatments',
                'Exilis Body',
                'X-WaveTM',
                'Mag Sculpt',
            ],
            'POST SURGICAL REHABILITATION': [
                'Post liposuction surgery treatment',
                'Post rhinoplasty surgery treatment packages',
                'Post tummy tuck surgery treatment packages',
                'Post body lift surgery treatment Packages',
                'Post augmentation surgery treatment packages',
            ],
        },
        'WEIGHT LOSS & NUTRITION': {
            "": ["Achieve your weight loss goals and nurture your body with Merve Clinic's personalized nutrition plans and weight management programs. Our team of experts guides you through a holistic approach to healthy living, combining dietary adjustments, physical activity, and supportive therapies. Whether you're seeking to lose weight or improve your overall health, our comprehensive approach is tailored to meet your individual needs. At Merve Clinic, we are committed to empowering our patients to make sustainable lifestyle changes and achieve long-term wellness. We offer advanced gastric balloon and bariatric surgery options designed to help individuals achieve their weight loss goals and improve overall health. Our experienced medical team specializes in providing personalized care and support throughout the transformative weight loss journey. Whether you opt for the non-surgical approach of the gastric balloon or the surgical route of bariatric procedures, our experts are dedicated to understanding your unique needs and goals to deliver the best possible outcomes. We are committed to employing future-forward strategies and holistic approaches to cater to the diverse needs of our patients, ensuring sustainable weight management and long-term wellness."],
            'Weight Loss Surgery': [
                'Gastric sleeve',
                'Gastric balloon',
                'Mini gastric bypass',
                'Classic (Roux en y) gastric bypass',
                'Duodena I switch',
                'Gastric plication',
                'Endoscopic sleeve gastroplasty',
            ],
            'NUTRITION': [
                'Nutrition support',
                'Weight management',
                'Diabetes (including gestational diabetes)',
                'Cholesterol and triglyceride lowering',
                'Irritable bowel syndrome (IBS)',
                'Low FODMAP diet for IBS',
                'Nutrition support in: Oncology, Stroke, Neuro-rehabilitation, Pre and post-surgery nutrition, Maternal diet and nutrition, Pre and post-bariatric surgery, Endocrinology Disorders of glucose-insulin Metabolism and body weight Disorders of blood pressure & lipids metabolism Disorders of bone metabolism & parathyroid glands Disorders of thyroid Disorders of hypophysis, adrenals, ovary and testis glands Diabetes, metabolic syndrome, obesity & insulin resistance, also in pregnancy Polycystic Ovary Syndrome, acne, hirsutism, altered menses and erectile dysfunction Thyroid goiter, nodules and thyroiditis, hyper/hypo-thyroidism, also in pregnancy Osteoporosis, vitamin D and calcium abnormalities, hyper/hypo-parathyroidism Pituitary, Adrenals and Gonads Disorders Idiopathic and hormonal hypertension; hyper-cholesterolemia / triglyceridemia',
            ],
        },
        'DENTISTRY': [
            "Smile brighter with Cosmo Health Medical Centers’ comprehensive dental services. From routine check-ups to advanced cosmetic procedures, our dental professionals utilize the latest techniques to ensure optimal oral health and aesthetics. Whether you require preventive care, teeth whitening, or restorative treatments, our skilled dentists and hygienists deliver personalized care in a comfortable setting. We prioritize patient education and satisfaction, providing a full spectrum of dental services to help you maintain a healthy and beautiful smile.",
            'Orthodontic Braces',
            'Dental Implants',
            'Sedation Dentistry',
            '3D Guided Implant Surgery',
            'Invisalign',
            'Invisalign First',
            'Same Day Implant',
            'All-on-4 Implants',
            'Invisalign Teen',
            'Cosmetic Dentistry',
            'TMJ Treatment',
            'Porcelain Veneers',
            'Teeth Whitening',
            'Dental Bridges and Crowns',
            'Root Canal Treatment',
            'Laser Dentistry',
            'Sleep Apnoea and Snoring',
            'Gum Disease Treatment',
            'Routine Dental Checkups'
        ],



    };


    return (
        <section className="about_section layout_padding" style={{ marginTop: "180px" }}>
            <div className="container">
                <div className="row">
                    <Tab.Container id="left-tabs-example" defaultActiveKey={service}>
                        <Col sm={3}>
                            <Nav variant="none" className="flex-column" >
                                {Object.keys(data).map((category, index) => (
                                    <Nav.Item key={category} >
                                        <Nav.Link eventKey={category} style={{ color: "black" }}>{category}</Nav.Link>

                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Col>

                        <Col lg={9}>
                            <Tab.Content>
                                {Object.entries(data).map(([category, subcategories], index) => (
                                    <Tab.Pane key={category} eventKey={category}>
                                        <Row>
                                            <Col lg={8}>
                                                <div>
                                                    <h4>{category}</h4>
                                                    {Array.isArray(subcategories) ? (
                                                        subcategories.map((item, subIndex) => (
                                                            <p key={category} style={{ marginTop: "-5px" }}>{item}</p>
                                                        ))
                                                    ) : (
                                                        Object.entries(subcategories).map(([subcategory, procedures], subIndex) => (
                                                            <div key={category}>
                                                                <h5 style={{ marginTop: "20px", color: "#ab7f21" }}>{subcategory}</h5>
                                                                <ul>
                                                                    {procedures.map((procedure, procedureIndex) => (
                                                                        <li key={category} style={{ marginTop: "5px" }}>{procedure}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={4} className='py-3'>
                                                <img src={`/Services/${category}.jpg`} style={{ width: "100%", marginTop: "-15px" }} />
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Col>



                    </Tab.Container>
                </div>
            </div>
        </section>
    );
}

export default Services;
