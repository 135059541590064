// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section_title_container {
  margin-top: -150px;
}

.section_title h2 {
  font-size: 25px;
  color: #ab7f21;
}

.services_row {
  display: flex;
  flex-wrap: wrap;
  margin-top: -50px;
}

.service_col {
  cursor: pointer;
  flex: 1 1 30%;
  /* Adjust to fit 3 items per row */
  margin: 10px;
}

.service {
  background-color: #ab7f21;
  /* Example background color */
  color: white;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 10px;
}

.service_title {
  color: inherit;
}

.service:hover {
  background-color: #f0f0f0;
  color: #333;
}

.icon_container_new {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.icon {
  margin-bottom: 10px;
}

.service_title {
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ServicesHome/ServiceHome.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,kCAAkC;EAClC,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,6BAA6B;EAC7B,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,kBAAkB;EAClB,6CAA6C;EAC7C,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".section_title_container {\r\n  margin-top: -150px;\r\n}\r\n\r\n.section_title h2 {\r\n  font-size: 25px;\r\n  color: #ab7f21;\r\n}\r\n\r\n.services_row {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  margin-top: -50px;\r\n}\r\n\r\n.service_col {\r\n  cursor: pointer;\r\n  flex: 1 1 30%;\r\n  /* Adjust to fit 3 items per row */\r\n  margin: 10px;\r\n}\r\n\r\n.service {\r\n  background-color: #ab7f21;\r\n  /* Example background color */\r\n  color: white;\r\n  text-align: center;\r\n  padding: 20px;\r\n  border-radius: 8px;\r\n  transition: background-color 0.3s, color 0.3s;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.service_title {\r\n  color: inherit;\r\n}\r\n\r\n.service:hover {\r\n  background-color: #f0f0f0;\r\n  color: #333;\r\n}\r\n\r\n.icon_container_new {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  margin-left: auto;\r\n  margin-right: auto;\r\n}\r\n\r\n.icon {\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.service_title {\r\n  font-size: 15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
