import Button from 'react-bootstrap/Button';
import React, { useEffect, useState, useRef } from 'react';
import Appointment from '../Appointment/Appointment';
import "./Treatments.css"
import { PiGenderIntersexBold } from "react-icons/pi";
import { GrAchievement } from "react-icons/gr";
import laserRemoval from "./images/laserreomval.jpeg";
import bari1 from "./images/pic1bartiatric.png";
import { useParams } from 'react-router-dom';
import surgeriesPic from './surgeries.jpg'
import pediatricPic from './images/Pediatric.jpg'
import morpheus from './images/morpheus.jpg'
import gastric from './images/Gastric.jpg'
import rino from './images/rhinoplasty.jpeg'
import { TbRazor } from "react-icons/tb";
import { FaHandHoldingHeart } from "react-icons/fa";
import { CiCircleCheck } from "react-icons/ci";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { CiTimer } from "react-icons/ci";
import { GiHairStrands } from "react-icons/gi";
import { CiPercent } from "react-icons/ci";
import { GiSandsOfTime } from "react-icons/gi";
import duoPro from './images/laserremovehero.jpeg'
import evlaser from './images/evlaser.png'
import image1 from './images/lipo360.jpeg'
import ImageSlider from "../ImageSlider/ImageSlider"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import beforeAfter1 from './beforeAfter/1.jpg'
import beforeAfter2 from './beforeAfter/2.jpg'
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import { FaDollarSign } from "react-icons/fa";
import { FaUserMd } from "react-icons/fa";
import { FaSmileWink } from "react-icons/fa";
import liposuction from './images/liposuction.jpg'
import pediatricHero from './images/pediatricHero.jpeg'
import morpheusHero from './images/morpheushero.jpeg'
import { LuSparkles } from "react-icons/lu";
import { MdOutlineFaceRetouchingNatural } from "react-icons/md";
import gyne from './images/gyne.jpg'
import { MdOutlineMoneyOff } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { FaMapMarkedAlt } from "react-icons/fa";
import { FaAward } from "react-icons/fa";
import { FaCoins } from "react-icons/fa";
import { FaHospital } from "react-icons/fa";
import { FcMoneyTransfer } from "react-icons/fc";
import { FcLike } from "react-icons/fc";
import { FcBiomass } from "react-icons/fc";
import { FcSearch } from "react-icons/fc";
import { FcBinoculars } from "react-icons/fc";
import { FcApproval } from "react-icons/fc";
import { FaWhatsapp } from "react-icons/fa";
import { FcMindMap } from "react-icons/fc";
import { FcGlobe } from "react-icons/fc";
import { FaClinicMedical } from "react-icons/fa";
import { Helmet } from 'react-helmet';
import { GiStomach } from "react-icons/gi";
import { MdFace4 } from "react-icons/md";
import { TbCheckupList } from "react-icons/tb";
import { FaShieldAlt } from "react-icons/fa";
import { PiTooth } from "react-icons/pi";
import { LiaTeethOpenSolid } from "react-icons/lia";
import { RiFirstAidKitLine } from "react-icons/ri";
import { TbMoodKid } from "react-icons/tb";
import { MdDiscount } from "react-icons/md";
import { LiaMoneyBillWaveAltSolid } from "react-icons/lia";
import { PiNeedleLight } from "react-icons/pi";
import invislign from './images/Invisalign0.jpg'
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import dentalPic from './images/dental.jpeg'
import ivDripBanner from './images/iv.jpeg'
import invi3 from './images/invi3.jpeg'
import invi4 from './images/invi4.jpeg'
import anil from './images/docAnil.jpeg'
import inviBanner from './invisilign.jpeg'


function Treatments({ activeTreatment }) {
    const countries = [
        { label: "Afghanistan", value: "93" },
        { label: "Åland Islands", value: "358" },
        { label: "Albania", value: "355" },
        { label: "Algeria", value: "213" },
        { label: "American Samoa", value: "1-684" },
        { label: "Andorra", value: "376" },
        { label: "Angola", value: "244" },
        { label: "Anguilla", value: "1-264" },
        { label: "Antarctica", value: "672" },
        { label: "Antigua and Barbuda", value: "1-268" },
        { label: "Argentina", value: "54" },
        { label: "Armenia", value: "374" },
        { label: "Aruba", value: "297" },
        { label: "Australia", value: "61" },
        { label: "Austria", value: "43" },
        { label: "Azerbaijan", value: "994" },
        { label: "Bahamas", value: "1-242" },
        { label: "Bahrain", value: "973" },
        { label: "Bangladesh", value: "880" },
        { label: "Barbados", value: "1-246" },
        { label: "Belarus", value: "375" },
        { label: "Belgium", value: "32" },
        { label: "Belize", value: "501" },
        { label: "Benin", value: "229" },
        { label: "Bermuda", value: "1-441" },
        { label: "Bhutan", value: "975" },
        { label: "Bolivia (Plurinational State of)", value: "591" },
        { label: "Bonaire, Sint Eustatius and Saba", value: "599" },
        { label: "Bosnia and Herzegovina", value: "387" },
        { label: "Botswana", value: "267" },
        { label: "Bouvet Island", value: "47" },
        { label: "Brazil", value: "55" },
        { label: "British Indian Ocean Territory", value: "246" },
        { label: "Brunei Darussalam", value: "673" },
        { label: "Bulgaria", value: "359" },
        { label: "Burkina Faso", value: "226" },
        { label: "Burundi", value: "257" },
        { label: "Cabo Verde", value: "238" },
        { label: "Cambodia", value: "855" },
        { label: "Cameroon", value: "237" },
        { label: "Canada", value: "1" },
        { label: "Cayman Islands", value: "1-345" },
        { label: "Central African Republic", value: "236" },
        { label: "Chad", value: "235" },
        { label: "Chile", value: "56" },
        { label: "China", value: "86" },
        { label: "Christmas Island", value: "61" },
        { label: "Cocos (Keeling) Islands", value: "61" },
        { label: "Colombia", value: "57" },
        { label: "Comoros", value: "269" },
        { label: "Congo", value: "242" },
        { label: "Congo (Democratic Republic of the)", value: "243" },
        { label: "Cook Islands", value: "682" },
        { label: "Costa Rica", value: "506" },
        { label: "Croatia", value: "385" },
        { label: "Cuba", value: "53" },
        { label: "Curaçao", value: "599" },
        { label: "Cyprus", value: "357" },
        { label: "Czech Republic", value: "420" },
        { label: "Denmark", value: "45" },
        { label: "Djibouti", value: "253" },
        { label: "Dominica", value: "1-767" },
        { label: "Dominican Republic", value: "1-809, 1-829, 1-849" },
        { label: "Ecuador", value: "593" },
        { label: "Egypt", value: "20" },
        { label: "El Salvador", value: "503" },
        { label: "Equatorial Guinea", value: "240" },
        { label: "Eritrea", value: "291" },
        { label: "Estonia", value: "372" },
        { label: "Eswatini", value: "268" },
        { label: "Ethiopia", value: "251" },
        { label: "Falkland Islands (Malvinas)", value: "500" },
        { label: "Faroe Islands", value: "298" },
        { label: "Fiji", value: "679" },
        { label: "Finland", value: "358" },
        { label: "France", value: "33" },
        { label: "French Guiana", value: "594" },
        { label: "French Polynesia", value: "689" },
        { label: "French Southern Territories", value: "262" },
        { label: "Gabon", value: "241" },
        { label: "Gambia", value: "220" },
        { label: "Georgia", value: "995" },
        { label: "Germany", value: "49" },
        { label: "Ghana", value: "233" },
        { label: "Gibraltar", value: "350" },
        { label: "Greece", value: "30" },
        { label: "Greenland", value: "299" },
        { label: "Grenada", value: "1-473" },
        { label: "Guadeloupe", value: "590" },
        { label: "Guam", value: "1-671" },
        { label: "Guatemala", value: "502" },
        { label: "Guernsey", value: "44-1481" },
        { label: "Guinea", value: "224" },
        { label: "Guinea-Bissau", value: "245" },
        { label: "Guyana", value: "592" },
        { label: "Haiti", value: "509" },
        { label: "Heard Island and McDonald Islands", value: "672" },
        { label: "Holy See", value: "379" },
        { label: "Honduras", value: "504" },
        { label: "Hong Kong", value: "852" },
        { label: "Hungary", value: "36" },
        { label: "Iceland", value: "354" },
        { label: "India", value: "91" },
        { label: "Indonesia", value: "62" },
        { label: "Iran (Islamic Republic of)", value: "98" },
        { label: "Iraq", value: "964" },
        { label: "Ireland", value: "353" },
        { label: "Isle of Man", value: "44-1624" },
        { label: "Israel", value: "972" },
        { label: "Italy", value: "39" },
        { label: "Jamaica", value: "1-876" },
        { label: "Japan", value: "81" },
        { label: "Jersey", value: "44-1534" },
        { label: "Jordan", value: "962" },
        { label: "Kazakhstan", value: "7" },
        { label: "Kenya", value: "254" },
        { label: "Kiribati", value: "686" },
        { label: "Korea (Democratic People's Republic of)", value: "850" },
        { label: "Korea (Republic of)", value: "82" },
        { label: "Kuwait", value: "965" },
        { label: "Kyrgyzstan", value: "996" },
        { label: "Lao People's Democratic Republic", value: "856" },
        { label: "Latvia", value: "371" },
        { label: "Lebanon", value: "961" },
        { label: "Lesotho", value: "266" },
        { label: "Liberia", value: "231" },
        { label: "Libya", value: "218" },
        { label: "Liechtenstein", value: "423" },
        { label: "Lithuania", value: "370" },
        { label: "Luxembourg", value: "352" },
        { label: "Macao", value: "853" },
        { label: "Madagascar", value: "261" },
        { label: "Malawi", value: "265" },
        { label: "Malaysia", value: "60" },
        { label: "Maldives", value: "960" },
        { label: "Mali", value: "223" },
        { label: "Malta", value: "356" },
        { label: "Marshall Islands", value: "692" },
        { label: "Martinique", value: "596" },
        { label: "Mauritania", value: "222" },
        { label: "Mauritius", value: "230" },
        { label: "Mayotte", value: "262" },
        { label: "Mexico", value: "52" },
        { label: "Micronesia (Federated States of)", value: "691" },
        { label: "Moldova (Republic of)", value: "373" },
        { label: "Monaco", value: "377" },
        { label: "Mongolia", value: "976" },
        { label: "Montenegro", value: "382" },
        { label: "Montserrat", value: "1-664" },
        { label: "Morocco", value: "212" },
        { label: "Mozambique", value: "258" },
        { label: "Myanmar", value: "95" },
        { label: "Namibia", value: "264" },
        { label: "Nauru", value: "674" },
        { label: "Nepal", value: "977" },
        { label: "Netherlands", value: "31" },
        { label: "New Caledonia", value: "687" },
        { label: "New Zealand", value: "64" },
        { label: "Nicaragua", value: "505" },
        { label: "Niger", value: "227" },
        { label: "Nigeria", value: "234" },
        { label: "Niue", value: "683" },
        { label: "Norfolk Island", value: "672" },
        { label: "North Macedonia", value: "389" },
        { label: "Northern Mariana Islands", value: "1-670" },
        { label: "Norway", value: "47" },
        { label: "Oman", value: "968" },
        { label: "Pakistan", value: "92" },
        { label: "Palau", value: "680" },
        { label: "Palestine, State of", value: "970" },
        { label: "Panama", value: "507" },
        { label: "Papua New Guinea", value: "675" },
        { label: "Paraguay", value: "595" },
        { label: "Peru", value: "51" },
        { label: "Philippines", value: "63" },
        { label: "Pitcairn", value: "64" },
        { label: "Poland", value: "48" },
        { label: "Portugal", value: "351" },
        { label: "Puerto Rico", value: "1-787, 1-939" },
        { label: "Qatar", value: "974" },
        { label: "Réunion", value: "262" },
        { label: "Romania", value: "40" },
        { label: "Russian Federation", value: "7" },
        { label: "Rwanda", value: "250" },
        { label: "Saint Barthélemy", value: "590" },
        { label: "Saint Helena, Ascension and Tristan da Cunha", value: "290" },
        { label: "Saint Kitts and Nevis", value: "1-869" },
        { label: "Saint Lucia", value: "1-758" },
        { label: "Saint Martin (French part)", value: "590" },
        { label: "Saint Pierre and Miquelon", value: "508" },
        { label: "Saint Vincent and the Grenadines", value: "1-784" },
        { label: "Samoa", value: "685" },
        { label: "San Marino", value: "378" },
        { label: "Sao Tome and Principe", value: "239" },
        { label: "Saudi Arabia", value: "966" },
        { label: "Senegal", value: "221" },
        { label: "Serbia", value: "381" },
        { label: "Seychelles", value: "248" },
        { label: "Sierra Leone", value: "232" },
        { label: "Singapore", value: "65" },
        { label: "Sint Maarten (Dutch part)", value: "1-721" },
        { label: "Slovakia", value: "421" },
        { label: "Slovenia", value: "386" },
        { label: "Solomon Islands", value: "677" },
        { label: "Somalia", value: "252" },
        { label: "South Africa", value: "27" },
        { label: "South Georgia and the South Sandwich Islands", value: "500" },
        { label: "South Sudan", value: "211" },
        { label: "Spain", value: "34" },
        { label: "Sri Lanka", value: "94" },
        { label: "Sudan", value: "249" },
        { label: "Suriname", value: "597" },
        { label: "Svalbard and Jan Mayen", value: "47" },
        { label: "Sweden", value: "46" },
        { label: "Switzerland", value: "41" },
        { label: "Syrian Arab Republic", value: "963" },
        { label: "Taiwan", value: "886" },
        { label: "Tajikistan", value: "992" },
        { label: "Tanzania, United Republic of", value: "255" },
        { label: "Thailand", value: "66" },
        { label: "Timor-Leste", value: "670" },
        { label: "Togo", value: "228" },
        { label: "Tokelau", value: "690" },
        { label: "Tonga", value: "676" },
        { label: "Trinidad and Tobago", value: "1-868" },
        { label: "Tunisia", value: "216" },
        { label: "Turkey", value: "90" },
        { label: "Turkmenistan", value: "993" },
        { label: "Turks and Caicos Islands", value: "1-649" },
        { label: "Tuvalu", value: "688" },
        { label: "Uganda", value: "256" },
        { label: "Ukraine", value: "380" },
        { label: "United Arab Emirates", value: "971" },
        { label: "United Kingdom of Great Britain and Northern Ireland", value: "44" },
        { label: "United States of America", value: "1" },
        { label: "Uruguay", value: "598" },
        { label: "Uzbekistan", value: "998" },
        { label: "Vanuatu", value: "678" },
        { label: "Venezuela (Bolivarian Republic of)", value: "58" },
        { label: "Viet Nam", value: "84" },
        { label: "Wallis and Futuna", value: "681" },
        { label: "Western Sahara", value: "212" },
        { label: "Yemen", value: "967" },
        { label: "Zambia", value: "260" },
        { label: "Zimbabwe", value: "263" }
    ]
    const allDoctors = [
        // { id: 1, name: 'Dr. Emad Mohamed', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'Specialist Dermatologist', languages: ['Arabic', 'English'] },
        { id: 2, name: 'Dr. Omar AlGethami', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 3, name: 'Dr. Neha Sachdev', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        // { id: 4, name: 'Dr. Ritul Agarwal', image: '/Doctors/ritul.png', branch: "Mirdif Branch", speciality: 'Prosthodontist And Implantologist', languages: ['Arabic', 'English'] },
        { id: 5, name: 'Dr. Anil Saragur', image: '/Doctors/anil.jpg', branch: "Mirdif Branch", speciality: 'Specialist Orthodontics', languages: ['Arabic', 'English'] },
        // { id: 6, name: 'Dr. Mina Charopeme', image: '/Doctors/mina.jpg', branch: "Mirdif Branch", speciality: 'Specialist Pediatric Dentist', languages: ['Arabic', 'English'] },
        // { id: 7, name: 'Dr. Mirna Abdelmesieh', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 8, name: 'Jennylyn Evangelista', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'Beauty and Laser Therapist', languages: ['Arabic', 'English'] },

        // { id: 9, name: 'Dr. Pierfrancesco Mancini', image: '/Doctors/user.png', branch: "Jumeirah Branch", speciality: 'Consultant Plastic Surgery', languages: ['Arabic', 'English'] },
        // { id: 10, name: 'Dr. Carmelo Crisafulli', image: '/Doctors/user.png', branch: "Jumeirah Branch", speciality: 'Specialist Plastic Surgery', languages: ['Arabic', 'English'] },
        // { id: 11, name: 'Dr. Rokea Alrifaee', image: '/Doctors/user.png', branch: "Jumeirah Branch", speciality: 'Specialist Dermatology', languages: ['Arabic', 'English'] },
        // { id: 12, name: 'SP. Alena Kuzima', image: '/Doctors/user.png', branch: "Jumeirah Branch", speciality: 'Specialist Dermatology', languages: ['Arabic', 'English'] },

        { id: 13, name: 'Dr. Khaled Rezk', image: '/Doctors/rezq.png', branch: "Marina Branch", speciality: 'Specialist Plastic Surgery', languages: ['Arabic', 'English'] },
        // {
        //     "id": 14,
        //     "name": "Dr. Ahmad Maadarani",
        //     "image": "/Doctors/user.png",
        //     "branch": "Marina Branch",
        //     "speciality": "General Practitioner Aesthetic",
        //     "languages": ["Arabic", "English"],
        //     "core": [
        //         "Diagnosis and treatment of skin conditions.",
        //         "Cosmetic procedures such as Botox, fillers, threads, mesotherapy and chemical peels.",
        //         "Laser treatments for various skin issues.",
        //         "Skincare advice and product recommendations.",
        //         "Knowledge of dermatological medications and their effects."
        //     ],
        //     "profile": [
        //         "Lebanese", "General Aesthethic Practitioner", "3 Years"
        //     ],
        //     "qualification": [
        //         "Bachelor's degree in Medicine.",
        //         "Doctor of Medicine (MD) in Dermatology.",
        //         "Board certification in Dermatology and Cosmetology.",
        //         "3 years of experience in dermatology and cosmetology practice.",
        //         "Continuing education in dermatological advancements and cosmetic procedures.",
        //         "Diploma in aesthetic medicine from American association of continuing medical education.",
        //         "Certificate aesthetic medicine course accredited by DHA."
        //     ],
        //     "lang": ["Arabic", "Russian", "Ukrainian", "English"],

        // },

        // { id: 15, name: 'Dr. Lina Saleh', image: '/Doctors/user.png', branch: "Marina Branch", speciality: 'General Practitioner Aesthetic', languages: ['Arabic', 'English'] },
        { id: 16, name: 'SP. Alla Abu Baker', image: '/Doctors/user.png', branch: "Marina Branch", speciality: 'Beauty and Laser Therapist', languages: ['Arabic', 'English'] },
        { id: 17, name: 'SP. Joy Cadias', image: '/Doctors/user.png', branch: "Marina Branch", speciality: 'Beauty and Laser Therapist', languages: ['Arabic', 'English'] },

        { id: 18, name: 'Dr. Said Hassan', image: '/Doctors/saed.jpg', branch: "Ras Al Khaima Branch", speciality: 'General and Laparoscopic Surgeon', languages: ['Arabic', 'English'] },
        {
            id: 19,
            name: "Dr. Tareq Boghdady",
            image: "/Doctors/tareq.JPG",
            branch: "Ras Al Khaima Branch",
            speciality: "Specialist Dermatology",
            languages: ["Arabic", "English"],
            core: [
                "Cosmetic dermatology",
                "Botox, fillers, Mesotherapy and PRP treatment",
                "Skin lifting, tightening and HIFU face treatment",
                "Microneedling and radiofrequency",
                "Chemical peeling and fractional resurfacing",
                "Acne and acne scars treatment",
                "Hair, nail disorders and common dermatological disorders"
            ],
            profile: [
                "Egyptian",
                "Specialist Dermatologist",
                "25 Years"
            ],
            qualification: [
                "MBBCh .. Ain shams university, Cairo Egypt",
                "MS. Dermatology and venereal diseases .. Ain Shams university, Cairo Egypt",
                "Diploma course in Aesthetic medicine and laser .. Cairo university, Egypt",
                "Facial contouring and aging medicine .. Cairo university, Egypt",
                "Membership in Egyptian medical syndicate and Egyptian dermatologists association"
            ],
            lang: ['Arabic', 'English', 'Dutch']
        }
        ,
        {
            "id": 20,
            "name": "Dr. Hussam Kayali",
            "image": "/Doctors/KayaliH.JPG",
            "branch": "Ras Al Khaima Branch",
            "speciality": "General Dentist",
            "languages": ["Arabic", "English"],
            "core": [
                "Cosmetic Dentistry",
                "Restorative Dentistry",
                "Dental Surgery of Surgical and Non-Surgical Extractions",
                "Certified Invisalign Provider"
            ],
            "profile": {
                "Nationality": "Syrian Arab Republic",
                "Summary": "Dr. Hussam Kayali has 3 years of experience in all fields of practical dentistry with experience across the Emirates of the UAE."
            },
            "qualifications": [
                "DDS (Doctor of Dental Surgery) from Ajman University",
                "ICD (Internship in clinical dentistry)",
                "DHA and MOH license Registered and Evaluation letter holder",
                "Volunteering instructor in both Ajman and RAK dental universities"
            ],
            "lang": ["Arabic", "English", "Urdu-Hindi"],

        }
        ,
        // { id: 21, name: 'Dr. Khaled Shahin', image: '/Doctors/user.png', branch: "Sharjah Branch", speciality: 'Specialist Dermatology', languages: ['Arabic', 'English'] },
        {
            "id": 22,
            "name": "Dr. Asmaa Hendawi",
            "image": "/Doctors/user.png",
            "branch": "Sharjah Branch",
            "speciality": "Specialist Orthodontist",
            "languages": ["Arabic", "English"],
            "core": [
                "Fixed braces",
                "Transparent invisible braces"
            ],
            "profile": [
                "Syria", "Orthodentistry", "5 years"
            ],
            "qualification": [
                "Dental Medicine and Surgery from the University of Aleppo, Syria, 2013",
                "Orthodontics, University of Aleppo, Syria 2019",
                "Orthodontic license DHA",
                "Orthodontic license MOH"
            ],
            "lang": ["Arabic", "English"],

        },
        // { id: 23, name: 'Dr. Razan Sedki', image: '/Doctors/user.png', branch: "Sharjah Branch", speciality: 'Specialist Prosthodontics', languages: ['Arabic', 'English'] },
        {
            "id": 24,
            "name": "Dr. Nijad Naeisseh",
            "image": "/Doctors/user.png",
            "branch": "Sharjah Branch",
            "speciality": "Specialist Implantologist",
            "languages": ["Arabic", "English"],
            "core": [
                "Oral surgery",
                "Implantology",
                "Oral medicine",
                "Advanced dentistry"
            ],
            "profile": [
                "Canadian", "Implantology", "30 Years"
            ],
            "qualification": [
                "CES oral surgery France 1996",
                "Hospital resident 1992-1998 Cannes, France",
                "Consultant OMF, KSA 2002-2004",
                "MOH, DHA UAE since 2013"
            ],
            "lang": ["English", "French", "Arabic"],

        }, {
            id: 25, name: 'Dr. Hazem Kayali', image: '/Doctors/kayali.png', branch: "Sharjah Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'],
            core: ['smile analysis', 'cosmetic Dentistry, Laminate Veneers, Lumineers', 'All types of fixed prosthodontics (crowns, traditional, complex or Maryland bridges)', 'Aesthetic Fillings', 'Endodontic treatment, (Root canal treatment simple and advanced cases, and root canal re-treatment)', 'Preventive Dentistry', 'Dental Prophylaxis and teeth whitening', 'Gum depigmentation, gingivectomy, gingivoplasty and crown lengthening', 'Dental Laser Treatments'],
            qualification: ['Bachelor degree in Dental Medicine & Surgery in May 2010 at Ain Shams University', 'Master degree in fixed prosthodontics at Ain Shams University in September 2015, and thesis defense in January 2016', 'Dental photography course with Michigan state university in 2020', 'Dental laser workshop in 2018', 'Prosthetic Veneer Workshop by prof. Carlos Sabrosa (Brazil) in 2013', 'Basic and Advanced Endodontic Course in 2010', 'In addition to contribution and attendance of the International Dental Congress of the Egyptian Dental Association in 2009, 2011 and 2013'],
            profile: ['Syrian', 'Dentistry (Prosthodontics)', '14 Years'],
            lang: ['Arabic', 'English', 'Dutch']
        },
        // { id: 26, name: 'Dr. Muna Abu Baker', image: '/Doctors/user.png', branch: "Sharjah Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 27, name: 'Dr. Riza Khirandish', image: '/Doctors/reza.png', branch: "Sharjah Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        {
            "id": 28,
            "name": "Dr. Ahmad Sirafi",
            "image": "/Doctors/srarfi.png",
            "branch": "Sharjah Branch",
            "speciality": "General Dentist",
            "languages": ["Arabic", "English"],
            "core": [
                "Pediatric dentistry",
                "Nitrous oxide administration",
                "All routine dental treatments"
            ],
            "profile": [
                "Syrian", "General Dentistry", "5 Years"
            ],
            "qualification": [
                "Bachelor degree in dentistry (DDS)"
            ],
            "lang": ["Arabic", "English"],

        },
        {
            "id": 29,
            "name": "Dr. Nouraldin Al Farra",
            "image": "/Doctors/user.png",
            "branch": "Sharjah Branch",
            "speciality": "General Dentist",
            "languages": ["Arabic", "English"],
            "core": [
                "Cosmetic dentistry (veneers, hollywood smile, and teeth bleaching)",
                "Routine dental treatment (scaling and polishing, fillings, and root canal treatment)",
                "Surgical extraction",
                "Dental implants",
                "Crowns and bridges",
                "Partial and complete dentures"
            ],
            "profile": [
                "Palestine", "General Denstistry", "10 Years"
            ],
            "qualification": [
                "Bachelor of Dentistry (DDS) (2014)",
                "From: Ajman University for Science & Technology (AUST)"
            ],
            "lang": ["Arabic", "English"],

        }
        ,
        // { id: 30, name: 'Dr. Rehab Mzoughi', image: '/Doctors/rihab.JPG', branch: "Ajman Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        {
            id: 31,
            name: "Dr. Tareq Alkurd",
            image: "/Doctors/tariq.JPG",
            branch: "Ajman Branch",
            speciality: "General Dentist",
            languages: ["Arabic", "English"],
            core: [
                "Cosmetic dentistry (Veneers, lumineers)",
                "Dental surgeries (tooth extraction, wisdom & impaction)",
                "Restorative dentistry including fillings, crowns, veneers, and bridges.",
                "Endodontic treatment including root canal treatments.",
                "Periodontal treatment including gum treatments.",
                "Implant"
            ],
            profile: [
                "Palestine",
                "General Dentistry",
                "17 Years"
            ]
            ,
            qualification: [
                "Bachelor of dental surgery B.D.S EGYPT",
                "Post graduate diploma in oral implant DGZI GERMANY",
                "DHA 77234474",
                "MOH D68455"
            ],
            lang: ['Arabic', 'English', 'Dutch']

        }

        ,
        { id: 32, name: 'Dr. Sara Hussein', image: '/Doctors/user.png', branch: "Ajman Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },



    ];

    const aesthetics = [
        {
            title: "AESTHETIC",
            pageName: "Non-surgical Nose Job",
            desc: `As the nose is in the centre point of the face, asymmetries can appear more pronounced on and around the nose. In the past, surgical rhinoplasty was the only way to fix nose asymmetry. The new developments in non-surgical aesthetics in recent years can help to resolve several issues that cause insecurity about appearance.<br/><br/>
            The non-surgical nose job, commonly known as non-surgical rhinoplasty, is a treatment that uses dermal filler injections to reshape the nose. By using dermal filler to sculpt and shape the nose, you will get a more pleasing and symmetrical appearance.<br/><br/>
            The non-surgical nose job is done by injecting hyaluronic acid (HA) Dermal Fillers into the nose to temporarily reshape specific nose points, mainly the bridge and tip. The hyaluronic acid filler settles into the injected area and holds the shape to achieve the desired look instantly. This is a perfect temporary solution for issues like a small bump, a drooping nasal tip, and asymmetry. The result usually lasts 12-18 months and is visible after a single treatment.<br/><br/>
            A non-surgical nose job has many impressive results but also some limitations. Fillers can’t physically reduce the size of your nose, straighten or make a wide nose narrower. But they make these issues much less noticeable.<br/><br/>
            The procedure is non-invasive, less aftercare and less recovery time. When considering any non-surgical treatments, it is important to reach a professional doctor to deliver the desired results, minimizing the risks and side effects and avoiding complications.<br/><br/>
            At Cosmo Health Medical Center, we are committed to providing clinically advanced aesthetic treatments with safety and professionalism. We recommend the treatment best suitable for you. Get in touch to book your consultation by clicking here.`
        },
        {
            title: "AESTHETIC",
            pageName: "Double Chin Treatment",
            desc: `A double chin is the excess fat volume gathered under the chin and neck area that looks like a secondary chin. An ill-defined jawline also gives the appearance of a double chin. The visual appearance of a double chin dramatically affects confidence as the people look heavier and older than they actually are. Luckily here at Cosmo Health Medical Center, we have a variety of treatments available to help you finally feel proud of your facial appearance.<br/><br/>
        The excess volume in this area can be a result of many factors like loose skin, excess neck fat, weak chin, drooped salivary gland or sagged platysma muscle. And it can be a combination of any of these that result in a visible double chin, not just excess fat. So an expert’s consultation is needed before fixing treatment.<br/><br/>
        Fat in the neck area is usually difficult to remove despite exercise and dieting. Thankfully, advanced aesthetic medicine has allowed us to treat this stubborn fat without resorting to excessive exercise or surgery. These non-invasive double chin treatment methods, like CoolSculpting and Ultherapy, can take place in a shorter time, involving no downtime and exceptionally long-lasting results.<br/><br/>
        Non-Invasive Face Lift – HIFU<br/><br/>
        HIFU stands for High-Intensity Focused Ultrasound. It is a fat-resolving treatment specially designed to treat smaller areas of fat depositions like double chins. This non-invasive treatment starts to heat cells deep under the skin’s surface with ultrasound energy, promoting the production of more collagen. This boosts collagen, which gives skin its elasticity and firmness, giving skin a tighter appearance with fewer wrinkles and more smoothness.<br/><br/>
        During the procedure, a handheld device is placed on the skin, and the ultrasound energy is focused on specific areas to create thermal coagulation points. The body’s natural healing process then kicks in, resulting in a gradual improvement in the appearance of fine lines, wrinkles, and sagging skin over several weeks to months.<br/><br/>
        One of the main benefits of HIFU is that it is non-invasive and does not require any incisions or anesthesia, making it a safe and relatively comfortable procedure with minimal downtime. It is also suitable for a wide range of skin types and can be used on various areas of the face and body.<br/><br/>
        Come to our Medical Center to see our expert surgeons and discuss the best options to achieve your desired shape. Book a consultation today.`
        },
        {
            title: "AESTHETIC",
            pageName: "Fox Eyes",
            desc: `New beauty trends are constantly changing. One of the latest trends that are gaining major popularity within the cosmetic industry is the fox or cat-eye. Foxy eyes are slightly slanted, almond-shaped eyes which are made popular by the likes of supermodels Kendal Jenner and Bella Hadid. Even a new ‘fox eye challenge’ has been trending on social media recently.<br/><br/>
            A fox eye lift is a cosmetic procedure that lifts the outside corners of your eyes and pulls them slightly outwards to give you almond-shaped eyes.<br/><br/>
            Fox Eye Surgery is a minimally invasive treatment using a blunt cannula. The results are rejuvenating as raising the eyebrows also opens the eye and stretches any sagging skin. Depending upon your requirement, we can select any of the following procedures to get the cat eyes you desire.<br/><br/>
            <strong>PDO threading:</strong> The treatment works by inserting a dis-solvable PDO thread under the skin after a local anaesthetic. The inserted thread lifts the outside corner of the eyebrow while stimulating the new collagen production, promoting firmness and elasticity of the skin.<br/><br/>
            <strong>Blepharoplasty:</strong> For people who want a permanent fox eye lift, this is the best option as it removes excess skin and thereby lifts the corner of the eye up. In this procedure, a small portion of the hooded eyelid is cut away. It is a common type of facial plastic surgery, which also reduces the appearance of fine lines and wrinkles.<br/><br/>
            <strong>Temporal lift:</strong> This is a temporary lift achieved through injectable fillers such as Botox. This raises your eyebrows to tighten the skin around the eyes. While most eyebrows have a natural arch, this temporal lift raises the outside corner to lift your eyes naturally. This option typically has no recovery time.<br/><br/>
            <strong>Canthoplasty:</strong> This is a treatment that primarily elongates the eyes. So this option is often used to correct vision problems. When used for fox eye surgery, we make small incisions on the outside corners of the eyes and stretch them slightly to give an almond shape. The procedure can take up to two hours, and the results are permanent.<br/><br/>
            The downtime is usually minimal, with mild swelling and bruising for up to two weeks. Because foxy eyes can be permanent, make sure to get the treatment from a licensed professional such as the ones at Cosmo Health Medical Center. We understand that each patient has individual needs, so we take a customized approach to surgery. All of our practitioners are certified, so you can be confident you will receive exceptional service at  Medical Center.`
        },
        {
            title: "AESTHETIC",
            pageName: "Gummy Smile Treatment",
            desc: `A gummy smile, clinically called excessive gingival display, is when you smile; too much gum tissue shows above the top teeth. Even though a gummy smile is not considered a medical condition, it can affect your self-confidence and discourage you from displaying your pearly whites.<br/><br/>
            Sometimes, a gummy smile can be a result of certain medications. Some medications can cause the overgrowth of your gums. This situation is called gingival hyperplasia.<br/><br/>
            In the past, Gingivectomy, an operation to remove excess gum tissue, was the only way to correct a gummy smile. You can quickly fix your gummy smile in under an hour with advanced technology. Botox Cosmetic injections can be used for a gummy smile correction to give the same effect as gum lift surgery.<br/><br/>
            Botox injections help to relax your facial muscles. For treating a hyperactive upper lip, we use the anti-wrinkle injection to relax the correct muscle, usually near the nose and reduce its ability to pull up your lip excessively. Depending on the muscle treated, the treatment can be as simple with less recovery time. You can go back to normal activities right after.<br/><br/>
            As one of the leading skin clinics, our priority is our safety. If the gummy smile bothers you, book a consultation with one of our dedicated consultants today.`
        },
        {
            title: "AESTHETIC",
            pageName: "Thread Lift Treatment",
            desc: `A thread lift treatment is a non-surgical facial rejuvenation treatment that uses specialized surgical threads to lift sagging skin and give your face a tighter and younger appearance. People who are unsatisfied with the undesirable ageing signs on their faces can get a thread lift without the risk of surgery. Just like the surgical facelift, this will make you look younger and feel better about your facial appearance.<br/><br/>
            The threads used for the treatment are known as PDO Polydioxanone (PDO) threads. These threads are a suture-like material and are carefully placed into the skin with a fine needle to lift the face, reducing the appearance of wrinkles and tightening the skin.<br/><br/>
            The thread lift treatment is not a corrective treatment for just ageing but also stimulates the body’s healing response and thereby triggers collagen production in large amounts. Accelerated collagen production delays ageing, improves skin firmness, volume and texture, and keeps it hydrated and supple.<br/><br/>
            Thread lift is a minimally-invasive cosmetic procedure with minimal recovery time. Owing to the simplicity of the procedure, you can return to your regular life right after the procedure, depending upon the candidate’s condition.<br/><br/>
            Not sure if a PDO thread lift is right for you? Please book a consultation with Cosmo Health Medical Center; our experts will assess your situation and see what treatment is the best fit for you!`
        },
        {
            title: "AESTHETIC",
            pageName: "Anti-Sweating Treatment",
            desc: `Anyone suffering from excessive sweating will understand how it can interfere with their daily activities and the inconvenience caused. Excessive sweating, or hyperhidrosis, is a common problem which affects up to 3% of people. Despite hyperhidrosis’s serious negative impact on those who suffer from it, most never seek treatment. Don’t worry; at Cosmo Health Medical Center, Excess sweating is treated with a simple non-surgical procedure, giving you smooth, dry skin and confidence in your day-to-day life. This non-surgical procedure uses Botox to stop excessive sweating by preventing excess nerve signals in the overactive sweat glands.
            Typically, your sweat glands activate your nervous system when your body temperature rises. As a result, our body automatically cools itself. When you receive Botox injections directly into the affected area, your overactive nerves are partially paralyzed. When your sweat glands stop receiving signals from the nerve, you don’t sweat. 
            This Botox treatment is highly effective and will reduce symptoms by around 90%. This has no downtime, and you can resume your normal activities immediately. The result usually takes 2 and 4 days to notice and two weeks for the full effect. The effects of Botox are temporary, underarm sweating, and booster injections are generally needed after every seven months. For the hands and feet, you may need to repeat your botox treatment after about six months.`
        },
        {
            title: "AESTHETIC",
            pageName: "Lip Lift",
            desc: `Lips are the most defining feature of your face. Shape, volume and facial expressions of lips have a significant impact at first glance on the appearance. Thus, many women dream of having lips that enhance their beauty. If your lips are not as plump or symmetrical as you desired, a lip lift with dermal fillers can deliver that luscious kissable lips.<br/><br/>
            Lip fillers or dermal fillers are substances injected into your lips which remove lines and wrinkles and add volume to lips or cheeks. Most dermal fillers contain a natural hyaluronic acid. First, an anaesthetic cream is used to numb your skin. Then Injections are given around the area of your face being treated and massaged. It might feel uncomfortable but not painful. The treatment usually finishes in 20 and 30 minutes.<br/><br/>
            Fillers are not permanent and last between 6 and 18 months. You can see instant results after the treatment. You can resume your normal activities immediately after the treatment.<br/><br/>
            Lips are the most challenging features for a Nonsurgical Facial treatment as they require significant technical skill. So We suggest a detailed consultation with one of our experienced Doctors to advise you on the outcome and pricing based on your tailored needs. To Book an appointment, call us now.`
        },
        {
            title: "AESTHETIC",
            pageName: "Under Eye Treatment",
            desc: `Eye bags and tired-looking eyes can be caused due to heavy eyelids, puffy eyes, hooded eyes, under-eye dark circles or fine lines under the eyes. Cosmo Health Medical Center offers non-surgical, minimally invasive, safe treatment options for all these concerns and gives you an improved and refreshed appearance.

            Ageing is the most common cause of tired-looking eyes due to loss of collagen and fat padding. Reduced collagen levels result in loss of hydration and elasticity of the associated muscles and skin, leading to sagging and the appearance of wrinkles and fine lines. Such sagging and fine lines are often more prominent under the eyes as the under-eye skin is really thin.
            
            The Under-eye treatment procedure starts with applying local anaesthesia to numb the targeted area to avoid any pain or discomfort. Then, the doctor injects filler containing a gel-like substance to fix dark circles and restore the facial volume. Generally, natural hyaluronic acid is used as this substance to produce a natural-looking outcome. After the fillers are injected, cold packs are applied to minimize the occurrence of side effects like swelling or bruising.
            
            The results of under-eye filler treatment are immediate and last for 1 to 3 years. Fillers need some time to integrate with the surrounding eye tissues and achieve the hydration balance. You will see a lifting effect right after getting this treatment; however, it takes a couple of weeks for the complete desired outcome.
            
            At Cosmo Health Medical Center, we have a number of non-surgical solutions to leave you with an overall rejuvenated eye look.`
        },
        {
            title: "AESTHETIC",
            pageName: "Anti-wrinkle Treatment",
            desc: `Our face shows the signs of ageing first. Age spots, sagging or dry skin, crow’s feet, wrinkles, dark circles, and generally looking tired are all big concerns about ageing skin. Even though we are not able to stop the ageing process, we can help your skin look healthy and reduce the appearance of wrinkles with any of the below-mentioned anti-ageing treatments.

            Dermal fillers are one of the popular anti-ageing treatments in UAE. These fillers are made from Hyaluronic Acid, which is a natural substance found in our body. Hyaluronic acid’s ability to hold up to 1000 times its own weight in water helps to hydrate the skin. When injected, hydraulic acid is able to plump and volume the skin, which in turn can reduce the appearance of fine lines and wrinkles.
            
            Anti-Wrinkle Cosmetic Injectables are a non-invasive, quick, affordable, and effective way to create a refreshed and youthful look. Wrinkle injections are made from a substance called Botulinum Toxin which can cause a form of muscle paralysis known as botulism. When Small doses are injected into specific areas directly, it causes controlled relaxation of the muscles responsible for wrinkle- formation.
            
            Laser skin resurfacing is another innovative method to rejuvenate ageing skin. It is an ideal treatment to help even out areas of discolouration and can also reduce the appearance of fine lines. The procedure uses short, concentrated pulsating beams of light in a pre-selected pattern on the top layer of the skin.
            
            Choosing the right treatment can be difficult, which is why we have a team of skin experts who assess your skin and suggest the best regime of treatment. All of our skin treatments are performed by highly trained professionals, meaning you are in the safest of hands at all times.To find out the best treatment for you, book a consultation at  Clinic.`
        },
        {
            title: "AESTHETIC",
            pageName: "Hair Treatment",
            desc: `Cosmo Health Medical Center is a destination, focused exclusively on non-surgical facial treatments in UAE. Our medical experts and luxurious surroundings in medical center create an elevated client experience. All in a setting with elegant interiors and concierge service that is more akin to a five-star hotel.<br/><br/>
            Hair loss in both men and women can lead to a range of emotional issues. We offer a range of regenerative hair loss treatments aiming to restore your confidence.<br/><br/>
            Male pattern baldness, or Androgenetic alopecia, causes hair follicles to shrink, so they produce thinner and thinner hairs until the growth is stopped altogether. Our innovative regenerative hair therapies are designed to stimulate the follicles to become healthier and stronger. Thereby slowing and reversing the thinning process and restoring hair growth. These procedures are non-invasive, so they can easily fit in with the busiest of schedules.<br/><br/>
            <b>Platelet Rich Plasma therapy:</b> Through a set of small injections into the scalp dermis, Platelet Rich Plasma therapy stimulates the follicles so they become healthier and larger, slowing and reversing the thinning process and restoring hair growth.<br/><br/>
            <b>Carboxytherapy:</b> In this procedure, a small amount of carbon dioxide gas are injected below the skin’s surface. This stimulates hair growth by improving circulation, oxygenation and the supply of nutrients to the scalp. Carboxytherapy can combine with other treatments to stimulate and nourish hair follicles.<br/><br/>
            <b>Mesotherapy:</b> With a series of superficial microinjections below the epidermis, the Mesotherapy cocktails target the exact areas of concern, stimulating hair strength and re-growth. The procedure is virtually safe and painless, and no dressing or local anesthesia is required.`
        },
        {
            title: "AESTHETIC",
            pageName: "Fillers",
            desc: `Fillers are gel-like compounds that are injected beneath the skin to replace lost volume, collagen, and elasticity. They plump up areas like your lips or cheeks and provide volume where lines and wrinkles are present. This is a non-invasive way to look younger without having surgery or a downtown.<br/><br/>
            Although fillers are sometimes referred to as “wrinkle fillers,” they actually accomplish much more than that. Additionally, they accentuate sunken cheeks and give them volume again, diminish vertical skin wrinkles, plump the lips, and increase facial symmetry.<br/><br/>
            Fillers don’t last forever. The type of filler used and the area where it is injected are factors that impact how long they last. They often endure 6 to 18 months. There are numerous fillers that have received FDA approval, and they are categorized according to the material they are constructed of. In UAE, hyaluronic acid, a naturally occurring chemical, is present in the majority of dermal fillers.<br/><br/>
            The average dermal filler procedure lasts 30 minutes. In this procedure, natural fillers called hyaluronic acid are injected directly into the skin to decrease the appearance of wrinkles and improve the overall health of your skin by replenishing moisture where it has been needed. Also, they restore volume lost over time, or provide a subtle enhancement, leaving you feeling confident. A local anesthetic cream will be applied to your skin by your doctor to prepare for the treatment and ease any discomfort. Although there may be a small amount of localized discomfort, the injections shouldn’t hurt.<br/><br/>
            Filler-based procedures are secure and fast. This does not imply that they are risk-free, especially if they are carried out by unqualified practitioners. At Cosmo Health Medical Center Our cosmetic physicians are qualified to provide youthful and natural-looking outcomes while attempting to minimize complications.`
        },
        {
            title: "AESTHETIC",
            pageName: "Butt Fillers",
            desc: `The gluteal muscles and the fat that covers them are what give the buttocks their characteristic contour. Walking, squats, and climbing are some examples of routine activities that target these muscles and can help tone, lift, and build muscle to assist attain an apple-shaped posterior.

            However, sagging skin and cellulite are very difficult to correct with exercise alone and may require a little aesthetic assistance to achieve the full and lifted look.
            
            The non-surgical butt lift procedure Sculptra or Lanluma bum lift is particularly well-liked. Both Sculptra and Lanluma are injectable products that encourage the production of collagen and elastin in your body, while Lanluma is newer and has a more pronounced plumping effect. By filling in wrinkles and cellulite, Lanluma injections help to improve the appearance of the skin. They also help to increase the volume to the buttock region. You will probably require 2-3 rounds of bum injections, spaced one month apart. It will take 3-6 months for the full effects to show, and they will persist for two years.
            
            Moreover, Sculptra is different from other buttock fillers like hyaluronic acid. In order to give you fuller, firmer, and more aesthetically pleasing buttocks, Sculptra leverages the strength of your own collagen rather than synthetic filler.
            
            Although the effects of bum injections are not permanent (lasting approximately two years on average), deciding to have any kind of aesthetic operation is a serious decision that should be given some considerable thinking and consideration. Please do not hesitate to contact us if you have any questions regarding bum fillers or to schedule your consultation.`
        },
        {
            title: "AESTHETIC",
            pageName: "Fat Dissolving Injections",
            desc: `If you’re seeking a non-surgical solution for reducing unwanted fat in UAEve come to the right place. Injection lipolysis is an excellent option for those looking to target small to medium-sized fat deposits that don’t warrant surgical intervention.<br/><br/>
            This procedure involves a series of micro-injections that chemically reduce the number of fat cells around the injection site. The injection contains natural chemicals that are already present in your body, which help to emulsify and break down fat and cholesterol.<br/><br/>
            Injection lipolysis is a safe and effective way to achieve a more contoured and toned appearance without the risks and downtime associated with surgery. So, if you’re looking to enhance your body’s natural contours and achieve a more sculpted look, consider injection lipolysis as your go-to cosmetic procedure.<br/><br/>
            <b>Advantages:</b><br/>
            Injection lipolysis is an excellent solution for those who struggle with stubborn fat cells that refuse to budge despite rigorous exercise and dieting. This non-invasive treatment is highly effective, eliminating the need for extensive preparation, surgery, and post-operative care required for other procedures that yield similar results.<br/><br/>
            What’s more, injection lipolysis is a quick and relatively painless procedure with minimal risks. Patients who undergo this treatment in UAE can return to their daily routines without any disruption to their normal functioning.<br/><br/>
            Say goodbye to those pesky fat cells and hello to a more confident, streamlined you with injection lipolysis.`
        },

        {
            title: "AESTHETIC",
            pageName: "Morpheus8",
            desc: `
Morpheus8 is an FDA-cleared microneedling RF device that boosts collagen production for skin rejuvenation. It delivers radiofrequency energy into the subdermal tissue, triggering the body’s natural healing process and increasing collagen levels. This results in smoother, firmer, and more youthful skin.
Morpheus8 penetrates deeper into the dermal tissue and fat than other devices, effectively treating early jowl formation and rejuvenating the skin. It’s the first RF microneedling device that can contour the jawline, making it a valuable non-surgical anti-aging treatment.
Morpheus8 radiofrequency microneedling provides facelift benefits without the need for incisions. This quick procedure delivers excellent results, making it an effective non-surgical option.
    <ul>
      <li>- Reduce Acne scars</li>
      <li>- Little to no downtime</li>
      <li>- Enhances collagen production</li>
      <li>- Fades stretch marks</li>
      <li>- Lifting / Tightening</li>
      <li>- Tone and texture</li>
      <li>- Reduce Fine lines, wrinkles</li>
    </ul>
    <br><br/>

    <b>HIFU: Advanced Non-Invasive Fat Reduction</b><br/>
HIFU, or High-Intensity Focused Ultrasound, is a non-invasive treatment designed to target small fat deposits like double chins. Using ultrasound energy, HIFU heats cells deep under the skin's surface, boosting collagen production for increased skin elasticity and firmness. This results in tighter, smoother skin with fewer wrinkles.

    <br/>
    During the procedure, a handheld device delivers focused ultrasound energy to specific areas, promoting natural healing and gradual improvement in fine lines, wrinkles, and sagging skin. HIFU is safe, comfortable, and requires no incisions or anesthesia, with minimal downtime. Suitable for various skin types and areas of the face and body, HIFU offers a convenient solution for enhancing your appearance.

  `
        }
        ,

        {
            title: "AESTHETIC",
            pageName: "Rhinoplasty",
            desc: `
              <b>Rhinoplasty: Sculpting the Nose, Enhancing Your Confidence</b><br/><br/>
          
              <b>What is Rhinoplasty?</b><br/>
              Rhinoplasty, commonly known as a "nose job," is a surgical procedure aimed at reshaping or reconstructing the nose. Whether for cosmetic enhancement or to correct functional issues, rhinoplasty can bring balance to facial features and improve nasal function, helping to boost your self-esteem and overall quality of life.
          
              <br/></br>
              <b>Who Can Benefit from Rhinoplasty?</b><br/>
              Rhinoplasty is ideal for individuals who are dissatisfied with the appearance of their nose or who suffer from breathing difficulties due to structural problems. Common reasons for seeking rhinoplasty include:
              <ul>
                <li>- A nose that is disproportionate to the face</li>
                <li>- A prominent bump on the nasal bridge</li>
                <li>- A drooping or upturned nasal tip</li>
                <li>- Asymmetry resulting from injury or birth defects</li>
                <li>- Breathing issues caused by a deviated septum or other structural irregularities</li>
              </ul>
              
            </br>
              <b>The Rhinoplasty Procedure</b><br/>
              Rhinoplasty is a highly personalized surgery, tailored to meet each patient's unique needs and aesthetic goals. Here's an overview of the process:
              <ul>
                <li><b>1. Consultation:</b> The journey begins with an in-depth consultation, where the surgeon discusses your goals and conducts a detailed examination of your nasal structure. Advanced imaging may be used to help visualize potential outcomes.</li>
                <li><b>2. Surgery:</b> The surgery can be performed using an open or closed technique. In open rhinoplasty, a small incision is made on the columella (the tissue between the nostrils) for better access to the nasal structure. In closed rhinoplasty, incisions are made inside the nostrils, leaving no visible scars. The surgeon then carefully reshapes the bone, cartilage, and tissue to achieve the desired results.</li>
                <li><b>3. Recovery:</b> After surgery, patients may experience swelling and bruising, which usually subsides within a few weeks. Most individuals can resume their regular activities within 1-2 weeks, though it may take several months for the final results to fully develop.</li>
              </ul>
          
            </br>
              <b>Why Choose Our Clinic for Rhinoplasty?</b><br/>
              Selecting the right surgeon for rhinoplasty is crucial for achieving the best possible results. Our clinic is dedicated to providing personalized care, ensuring that every patient feels supported and informed throughout their journey. We combine expertise with a commitment to patient satisfaction, helping you achieve your aesthetic goals with confidence.
          
              <br/><br/>
              <b>Schedule Your Consultation Today</b><br/>
              If you're ready to explore the possibilities of rhinoplasty, contact us today to schedule your consultation. Let us help you achieve the nose you've always envisioned and enhance your natural beauty.
            `
        }



    ]

    const aestheticsSer = aesthetics.map(surgery =>
        surgery.pageName
            .toLowerCase()
            .replace(/&/g, 'and') // Replace & with 'and'
            .replace(/[^a-z0-9-]/g, '-') // Replace all other non-alphanumeric characters with hyphens
    );

    const LaserTreatment = [
        {
            title: "LASER TREATMENT",
            pageName: "Laser Hair Removal",
            desc: `Welcome to our laser hair removal service! Our qualified technicians are dedicated to providing you with safe and effective treatments, ensuring smooth and long-lasting results. You're in expert hands with our experienced team, committed to making your experience comfortable and satisfying.
            </br></br>
            <strong>How does it work</strong>
            </br>
            Laser hair removal works by emitting a highly concentrated light that targets the hair follicles. The pigment in the follicle absorbs the light, causing the follicle to break down, which prevents future hair growth.

            `
        },
        {
            title: "LASER TREATMENT",
            pageName: "Laser Vein Removal",
            desc: `Spider veins or Leg thread veins are harmless but annoying veins that appear on the surface of your skin. Even though these veins are usually not painful, but may negatively affect your confidence, particularly in summer when you want to bare your legs.

            As we age, some valves become weaker, so we are more likely to develop leg thread veins. Many people suffering from leg thread veins will find it genetic. This condition is more common in women. Overweight, Pregnancy, Hormones or sitting/ standing for extended periods can be the other reasons.
            
            One of the popular treatment options for leg thread veins is the use of a laser. This will effectively break down unwanted veins using brief pulses of laser light. This light heats up the veins very quickly, causing them to collapse in on themselves. Laser treatment is usually suggested for small veins.
            
            In your consultation, the best treatment will be suggested depending on the size and number of thread veins you have. Some people see the results after one treatment, whereas others will need a course of three to see optimum results.`
        },
        {
            title: "LASER TREATMENT",
            pageName: "Mole Removal",
            desc: `Look Good. Feel Great.<br/><br/>
            Moles are a common feature of our skin caused by a collection of pigment-producing cells called melanocytes, and moles can vary in colour, size or shape. Most moles are harmless and tend to get more as we age. Abnormal mole tends to proliferate and have different sizes or shapes and a darker colour than your other moles.<br/><br/>
            Mole removal is a quick and pain-free procedure for removing cosmetic blemishes or moles under local anaesthetic with no cancer risk. Mole removal can be either a laser and/or surgical technique to ensure that all suspicious, worrying or unsightly moles are safely removed.<br/><br/>
            At Fine Cosmetics, We offer a range of methods for mole removal. The most suitable method can be used for each individual, decided at your consultation.<br/><br/>
            
            <ul>
                <li><b>Shave Excision:</b> This is a suitable method for a protruding mole; minimally invasive, and no stitches are required after the procedure. This method can leave a round pinkish scar where the mole has been cut out.</li>
                <li><b>Curette and Cautery:</b> In this method, a wire with a small electrical charge is used to burn the mole away. Minimally invasive, no stitches required. Just like the shave excision, it can leave a round pinkish scar where the mole has been cut out.</li>
                <li><b>Elliptical Surgical Excision:</b> Under local anaesthetic, the mole is cut away surgically. This is slightly more invasive than the other two. Stitches are required, and you must attend the clinic seven days later to remove the stitches. A fine white line scar may appear where the mole has been cut out.</li>
            </ul>`
        },
        {
            title: "LASER TREATMENT",
            pageName: "Laser Hair Bleaching",
            desc: `Make unwanted hair invisible without chemicals.<br/><br/>
            Finding a safe solution to get rid of unwanted hair is something many of us have looked for, trying everything from waxing or shaving. But, many of these methods can result in irritated or uneven skin tone and may leave you feeling self-conscious.<br/><br/>
            Laser Hair Removal is considered the most popular way to deal with unwanted hair quickly and effectively. However, there are times when laser hair removal is not the best solution. Specific hairs– for instance, fine baby hairs–are difficult to remove using the laser. Lasers can be effectively used to bleach these hairs and make them invisible for several weeks at a time.<br/><br/>
            Laser hair bleaching targets the melanin in fine baby hair and thereby reduces the amount of melanin in that fine hair, making them invisible. Laser hair bleaching technology uses Photo Acoustic energy. Short pulses of laser light penetrate the hair follicle and disrupt the factors causing hair growth. Unlike the other laser hair removal method for thick hair, you don’t need to shave the area, but the hair cannot be any longer than 2cm.<br/><br/>
            This procedure is non-surgical and non-invasive, so you don’t experience any kind of irritation or breakouts. It is super quick and takes only 15-45 minutes to complete. Like all other Laser therapy treatments, the frequency and number of treatments will depend on your skin, area, and the size of that area. Our specialists at Cosmo Health Medical Center will have an in-depth consultation to help create a personalised treatment plan. Book your consultation today.`
        },
        {
            title: "LASER TREATMENT",
            pageName: "Laser Hair Bleaching",
            desc: `The laser carbon peel is a fast and painless procedure for improving the appearance of the skin. It’s the best solution for people with oily skin, acne, and enlarged or clogged pores. This treatment is also beneficial for the effects of sun damage or tan on your skin.

            Resurfacing treatments can be classified in three ways: superficial, medium, and deep depending on how many layers of skin the treatment penetrates. Treatments that penetrate further below the surface of the skin show more dramatic results. So superficial treatments deliver modest results with minimal recovery time.
            
            One of the popular options for mild to moderate skin issues is a carbon laser peel or carbon laser facials. Carbon laser peel is a method of superficial treatment that helps with acne, enlarged pores, oily skin, and uneven skin tone.
            
            Despite what the name suggests, a carbon laser peel is not just a traditional chemical peel. Instead, our doctor uses a carbon solution and lasers to create a peeling effect on your skin. The first step is to apply a high carbon content serum to your skin. As the serum dries, it bonds with the impurities like dirt, oil, and other contaminants on the surface of the skin. Depending on the skin type, doctors pass a warming laser over your face, which will heat the carbon to absorb the impurities on your skin. In the final step, this carbon serum is broken down using a pulsed laser. The laser destroys the carbon particles and the bonded oil, dead skin cell, bacteria, or other impurities. In addition, the heat from this process also signals a healing response in your skin. That deeply exfoliate the skin and stimulates collagen and elastin production to make your skin look firmer. Since The lasers don’t penetrate the skin too deeply, there is a fast recovery time. The treatment is quick and takes about 30 minutes only.`
        },
        {
            title: "LASER TREATMENT",
            pageName: "Laser Carbon Peel",
            desc: `The laser carbon peel is a fast and painless procedure for improving the appearance of the skin. It’s the best solution for people with oily skin, acne, and enlarged or clogged pores. This treatment is also beneficial for the effects of sun damage or tan on your skin.

            Resurfacing treatments can be classified in three ways: superficial, medium, and deep depending on how many layers of skin the treatment penetrates. Treatments that penetrate further below the surface of the skin show more dramatic results. So superficial treatments deliver modest results with minimal recovery time.
            
            One of the popular options for mild to moderate skin issues is a carbon laser peel or carbon laser facials. Carbon laser peel is a method of superficial treatment that helps with acne, enlarged pores, oily skin, and uneven skin tone.
            
            Despite what the name suggests, a carbon laser peel is not just a traditional chemical peel. Instead, our doctor uses a carbon solution and lasers to create a peeling effect on your skin. The first step is to apply a high carbon content serum to your skin. As the serum dries, it bonds with the impurities like dirt, oil, and other contaminants on the surface of the skin. Depending on the skin type, doctors pass a warming laser over your face, which will heat the carbon to absorb the impurities on your skin. In the final step, this carbon serum is broken down using a pulsed laser. The laser destroys the carbon particles and the bonded oil, dead skin cell, bacteria, or other impurities. In addition, the heat from this process also signals a healing response in your skin. That deeply exfoliate the skin and stimulates collagen and elastin production to make your skin look firmer. Since The lasers don’t penetrate the skin too deeply, there is a fast recovery time. The treatment is quick and takes about 30 minutes only.`
        },
        {
            title: "LASER TREATMENT",
            pageName: "Fractional Radiofrequency",
            desc: `Photodamaged and ageing skin is associated with a decrease in collagen levels resulting in fine lines, wrinkles, tone and texture changes, and lax skin. The major reason for this is thinning of the epidermis and dermis. Damage to the dermis affects the activity of various enzymes, such as metalloproteinases and collagenase, which are responsible for removing the old tissue and deposition of new.

            Fractional radiofrequency (FRF) has recently emerged for the treatment of scars, cellulite and skin rejuvenation. FRF works by causing minimal to no damage to the skin, naturally boosting collagen production and rejuvenating skin cells, resulting in the stimulation of cell regeneration and tightening of the skin.
            
            This treatment can be used to treat all areas of the body except the lips and eyelids due to the high sensitivity of the areas.
            
            Fractional RF micro-needling is a minimally invasive technology using a set of very fine sterilized needles to create microscopic wounds at various predetermined depths in the dermis without affecting the epidermis. The direct thermal heating of the dermis results in new collagen production and subsequently results in dermal thickening. The presence of the intact epidermis and the surrounding dermal tissue results in rapid re-epithelialization and thus minimal downtime and risks. Recent studies show that fractional radiofrequency micro-needling is effective in the treatment of a majority of skin problems.
            
            After assessing your skin, we recommend the optimum number of treatments required to achieve noticeably healthier young skin. One single section of treatment takes between 20 to 45 minutes, although this is completely dependent on the size of the area being treated`
        },
    ]

    const LaserTreatmentSer = LaserTreatment.map(surgery =>
        surgery.pageName
            .toLowerCase()
            .replace(/&/g, 'and') // Replace & with 'and'
            .replace(/[^a-z0-9-]/g, '-') // Replace all other non-alphanumeric characters with hyphens
    );

    const wellness = [
        {
            title: "WELLNESS",
            pageName: "Health Checkup",
            desc: `Everyone wants to stay healthy and enjoy a long and happy life without any significant disease. Sadly, Cancer and Coronary Heart Disease can strike unannounced at any time. However, there is something you can do about it. You can have a complete body check, and if anything is developing inside, you can get it treated.<br/><br/>
            A complete body check-up helps detect the earliest signs and risk factors for any disease and gives you the tools to be proactive about your health. Prevention is key. Cosmo Health Medical Center will help you understand your results and provide the knowledge and medication to tackle health issues, adopt a healthy lifestyle, and reduce your risk of future disease.<br/><br/>
            Book yourself a Healthy Heart Screening package at Cosmo Health Medical Center and give your heart the care it needs.<br/><br/>
            BOOK TODAY AND AVAIL THE FOLLOWING PACKAGE:<br/>
            - Consultation with physician<br/>
            - ECG<br/>
            - Blood Pressure<br/>
            - Lipid Profile<br/>
            - Blood Sugar<br/>
            - BMI`
        },
        {
            title: "WELLNESS",
            pageName: "Oligo Scan Analysis",
            desc: `A quick and precise way to analyze trace elements, vitamins, minerals, heavy metals, and oxidative stress is to use the OligoScan. It uses an optical technology called spectrophotometry to obtain results on the number of trace elements and heavy metals that exist within the specific area of body tissue. The scanner measures the intensity of the electromagnetic signals of 34 elements, after which the intelligent software calculates the results of deficiencies and accumulations and compiles a comprehensive report.<br/><br/>
            Why do we need OligoScan?<br/>
            Minerals play a key role in the proper functioning of the body. A deficiency in trace elements, vitamins, and minerals result in tiredness, poor performance, stress, tension, or intellectual capacity. So it’s very important to control the trace elements in the body to be able to anticipate associated risks.<br/><br/>
            OligoScan can first assist us in determining our supplement and detoxification needs. Deficiencies in some nutrients or an accumulation of heavy metals in the blood can have a remarkable number of negative effects on one’s health. This indicates that OligoScan can be particularly beneficial for people with frequent problems like:<br/><br/>
            - Problematic skin, sensitive skin, and acne<br/>
            - Allergies/sensitivities<br/>
            - Difficulty sleeping<br/>
            - Gaining weight<br/>
            - Feeling tired<br/>
            - Limited immunity<br/>
            - Low mood<br/><br/>
            <strong>Advantages:</strong><br/>
            - Non-Invasive and pain-free<br/>
            - No blood test or Injection is needed<br/>
            - More accurate than a standard blood test<br/>
            - Rapid complete Written Report<br/>
            - Level of cellular oxidation and cellular ageing<br/>
            - Diabetes, insulin resistance, thyroid illness, and allergy susceptibility<br/>
            - Hormonal level<br/>
            - Liver detox level<br/>
            - Detection of the high rates of toxic metals in the body<br/>
            - Anti-oxidant levels<br/><br/>
            <strong>OligoScan Analysis is completely free of charge if you proceed with any IV treatment on the same day.</strong><br/><br/>
            Our medical professionals can make recommendations based on the report that will be highly compatible and suitable for your existing deficiencies.`
        },
        {
            title: "WELLNESS",
            pageName: "Slimming & Body Contouring",
            desc: `Weight Loss is not just about Calories and Exercise. Our approach allows identifying the underlying causes which must be addressed before treating weight loss. This approach not only allows you to lose weight but, also importantly helps you to achieve overall health.<br/><br/>
            Cosmo Health Medical Center uses leading, clinically proven advanced technologies to deliver comfortable and non-invasive treatments for skin tightening, fat reduction, total body contouring, and successful cellulite treatment.<br/><br/>
            <strong>Treatments:</strong><br/>
            <ul>
                <li>- Inch Loss</li>
                <li>- Fat reduction</li>
                <li>- Skin tightening</li>
                <li>- Buttock lifting</li>
                <li>- Cellulite</li>
                <li>- Total body contouring</li>
            </ul>
            Interested in learning more about how our treatment can be customized to help you meet your personal goals? Book a call now.`
        },
    ]

    const wellnessSer = wellness.map(surgery =>
        surgery.pageName
            .toLowerCase()
            .replace(/&/g, 'and') // Replace & with 'and'
            .replace(/[^a-z0-9-]/g, '-') // Replace all other non-alphanumeric characters with hyphens
    );

    const facialTreatment = [
        {
            title: "FACIAL TREATMENT",
            pageName: "Hydrafacial",
            desc: `HydraFacial is an ultimate skin detoxifying and deep cleansing experience for fresh, rejuvenated skin, using active botanical ingredients. This facial treatment reveals glowing, refreshed and radiant skin with immediate results. HydraFacial is suitable for all skin types and concerns at any age.<br/><br/>     
            Hydrafacial will instantly and visibly renew your skin and stimulate collagen production. It’s not just another facial but a unique mix of technology that will unleash a new layer of glowing, nourished skin with the 6-step method. This six-step treatment process follows – Detox, Cleanse and Exfoliation, Brightening, Extraction, Hydration and finally, Rejuvenation. Dead skin cells and impurities are removed after the process, and the skin is hydrated with antioxidants and peptides.<br/><br/>   
            The technology uses advanced, vortex technology and effectively delivering botanical ingredients containing nutrients like hyaluronic acid, red algae extract, horse chestnut seed extract, zinc and magnesium peptides. HydraFacial also delivers powerful antioxidants which counteract damage from the free radicals which accelerates the ageing process.<br/><br/>           
            HydraFacial helps to purify, extract, and moisturise the damaged skin. Whether you’re simply looking for healthy skin or are concerned about any particular skin condition, HydraFacial has the best solution. Book an appointment with us today.`
        },
        {
            title: "FACIAL TREATMENT",
            pageName: "Microneedling",
            desc: `Microneedling is a revolutionary cosmetic procedure that can help you achieve flawless, youthful-looking skin. This minimally invasive treatment stimulates collagen development, which is essential for maintaining healthy skin. It is also known as collagen induction therapy and is highly effective in treating a range of skin problems, including stretch marks, acne scars, sunspots, and even eyelid surgery.<br/><br/>       
            The procedure works by creating tiny pinpricks in the skin, which cause minor damage. This damage triggers the body’s natural healing process, resulting in the production of new collagen-rich tissue. The new tissue has a more uniform texture and tone, giving your skin a firmer, more youthful appearance.<br/><br/>        
            As we age, our skin loses collagen, which can lead to wrinkles, fine lines, and other signs of aging. Microneedling is an excellent way to combat this natural process and keep your skin looking its best. It is a safe and effective treatment that can be customized to meet your specific needs.<br/><br/>         
            If you’re interested in microneedling, we invite you to book a consultation at Cosmo Health Medical Center in UAE. Our team of highly skilled medical professionals will work with you to develop a personalized treatment plan that will help you achieve the results you want. Don’t wait any longer to get the flawless, youthful-looking skin you deserve. Contact us today to schedule your consultation!`
        },
        {
            title: "FACIAL TREATMENT",
            pageName: "Acne Scar Removal",
            desc: `Acne can be very distressing, but the worst part is that even after the acne has cleared, there may have some pigmentation and scarring on your face. According to the latest data, with a prevalence rate of 1 in 5 people, 95% of acne sufferers say it impacts their daily lives. Don’t worry; we are here to help. Find a solution to your acne scarring and discover confidence in better skin at Cosmo Health clinic, Palm Jumeriah.<br/><br/>
            Scars are formed when a breakout punctures the skin profoundly and destroys the tissues under it. Before treating the scars, it’s vital to identify their type. Each type responds to treatment differently; some methods work better for particular types than others. There is a range of acne scar treatments available at our clinic, depending on the type and severity of the scarring.<br/><br/>
            At Cosmo Health, various treatments are available to help you get rid of embarrassing acne scars. These include microdermabrasion, chemical peels, dermal fillers, and fractional CO2 lasers. Determining which treatment will be best for you is determined by our dermatologist after evaluation. The final results will take some time to appear, depending on the severity and type of scars. The results will be long-lasting.`
        },
        {
            title: "FACIAL TREATMENT",
            pageName: "Chemical Peels",
            desc: `A chemical peel is a procedure in which a chemical solution is applied to the face, hands, or neck to improve the appearance of the skin. This chemical solution results in the skin exfoliation and eventually peeling off. After the treatment, the new skin grows back and is often smoother, appears less wrinkled, and has less damage.<br/><br/>
            There are three types of peels: superficial, medium, and deep.<br/><br/>         
            Superficial and medium peels are usually safe and have less recovery time. These two peels are not permanent and need to be repeated for longer results. Deeper peels are more risky. The result is longer-lasting and usually does not need to be repeated.<br/><br/>          
            In a light peel, a chemical solution like salicylic acid will be applied to the area using a cotton ball or brush. Once the procedure is complete, the chemical solution is removed, or a neutralizing solution is added.<br/><br/>   
            During the medium chemical peel, a chemical solution containing glycolic acid or trichloroacetic acid is applied to the skin. The skin will begin to whiten, and a cool compress will be applied to the skin.<br/><br/>
            During a deep chemical peel, a cotton-tipped applicator will be used to apply phenol to your skin, which turns your skin white or grey. The procedure is done in 15-minute sections to limit the skin’s exposure to the acid.<br/><br/>
            A thorough evaluation by a professional dermatologist is imperative before a chemical peel. At Finecare, we offer a variety of industry-leading skin peels that exfoliate dead skin cells and encourage new ones to grow, revealing the softer, smoother skin beneath.`
        },
        {
            title: "FACIAL TREATMENT",
            pageName: "Carbon Laser Facial",
            desc: `The Carbon Laser Facial is a non-invasive and painless treatment that typically requires no downtime. The procedure involves the application of liquid carbon to the face, which bonds with the skin and any impurities such as open pores or acne. Once the carbon has dried, a laser is passed across the skin. The laser is attracted to the carbon particles, which ultimately destroys the particles in the pores or acne.<br/><br/>
            This treatment is an excellent option for those seeking a quick and effective solution to skin imperfections. It is a safe and efficient way to achieve a smoother, more radiant complexion without the need for invasive procedures or extended recovery time.<br/><br/>
            The Carbon Laser Facial is suitable for all skin types and can be customized to address specific concerns. Whether you are looking to reduce the appearance of fine lines and wrinkles, minimize pores, or improve overall skin texture, this treatment can help you achieve your desired results.<br/><br/>
            In conclusion, the Carbon Laser Facial is an effective method of skin rejuvenation and is an excellent option for anyone looking to achieve a more youthful, radiant complexion.<br/><br/>
            It is crucial to bear in mind that an incorrect laser procedure can result in long-term damage to your skin. At Cosmo Health, we prioritize your comfort and satisfaction above all else. Our team of highly skilled professionals takes great care to perform every procedure with meticulous attention to your well-being. As a result, we offer a customer experience that is unparalleled in the industry.`
        },
        {
            title: "FACIAL TREATMENT",
            pageName: "Carbon Laser Facial",
            desc: `The Carbon Laser Facial is a non-invasive and painless treatment that typically requires no downtime. The procedure involves the application of liquid carbon to the face, which bonds with the skin and any impurities such as open pores or acne. Once the carbon has dried, a laser is passed across the skin. The laser is attracted to the carbon particles, which ultimately destroys the particles in the pores or acne.<br/><br/>
            This treatment is an excellent option for those seeking a quick and effective solution to skin imperfections. It is a safe and efficient way to achieve a smoother, more radiant complexion without the need for invasive procedures or extended recovery time.<br/><br/>
            The Carbon Laser Facial is suitable for all skin types and can be customized to address specific concerns. Whether you are looking to reduce the appearance of fine lines and wrinkles, minimize pores, or improve overall skin texture, this treatment can help you achieve your desired results.<br/><br/>
            In conclusion, the Carbon Laser Facial is an effective method of skin rejuvenation and is an excellent option for anyone looking to achieve a more youthful, radiant complexion.<br/><br/>
            It is crucial to bear in mind that an incorrect laser procedure can result in long-term damage to your skin. At Cosmo Health, we prioritize your comfort and satisfaction above all else. Our team of highly skilled professionals takes great care to perform every procedure with meticulous attention to your well-being. As a result, we offer a customer experience that is unparalleled in the industry.`
        },
        {
            title: "FACIAL TREATMENT",
            pageName: "Cleopatra Facial",
            desc: `When it comes to beauty, Cleopatra has always been regarded as one of the most beautiful women ever lived. Luckily, her beauty secrets are well documented, which has inspired the Cosmetic world to take new steps in beauty treatments.<br/><br/>
            
            Cleopatra facial is a pampering, nourishing, resurfacing, and skin brightening cosmetic treatment, utilizing the skin rejuvenating properties of lactic acid and gold. The 24K Cleopatra Gold Sheets enrich the skin with the luminous benefits of gold, accelerating the skin’s natural radiance and removing unwanted facial lines. For skin tightening, the treatment combines a 24K gold sheet mask with a light chemical peel, radio frequency or ultrasound treatment, and LED therapy on areas of concern.<br/><br/>
            
            This unique anti-ageing facial has three steps: first, a mild chemical peel, then dermaplaning (or facial shaving), followed by a vitamin facial.<br/><br/>
            
            Cleopatra facials give instant results, with the best results seen after a couple of days. A significant improvement in the complexion and smooth, clear skin can be visible after the treatment. The skin will look refreshed and rejuvenated for several weeks; however, regular treatments are advised for maximum results.`
        },
        {
            title: "FACIAL TREATMENT",
            pageName: "Microneedling",
            desc: `Microneedling is an effective, minimally invasive therapeutic technique that helps to address many skin-related complaints, like acne scars, burn scars, skin rejuvenation, hyperhidrosis, wrinkles, stretch marks, and many more. This is considered a very safe technique for dark skin types, where the risk of post-inflammatory pigmentation is high with many other techniques that damage the epidermis.<br/><br/>
        
            Microneedling is a relatively new procedure involving superficial and controlled skin puncturing by rolling with fine miniature needles. It is a fast procedure lasting 10 to 20 minutes depending on the area to be treated. Preferably, the skin should be prepared preoperatively for at least a month with vitamin A and C formulations twice a day for maximum dermal collagen formation.<br/><br/>
        
            There is little downtime, and the patient can resume daily work the very next day. Treatments are performed at 3–8 week intervals, and multiple sittings are needed to achieve the desired effect on the skin. The final results cannot be viewed immediately because new collagen production takes approximately 3–6 months after treatment has ceased.<br/><br/>
        
            At Finecare Clinic, we like to go the extra mile for creating a tailored and personalised treatment to maximise your treatment results. All appointments begin with a careful consultation and assessment of your skin. Then we will thoroughly explain the procedure and benefits prior to treatment. Book your appointment today for beautiful skin.`
        },
        {
            title: "FACIAL TREATMENT",
            pageName: "Topical Gold Threads",
            desc: `Gold filament rejuvenation is another name for the application of gold threads for skin renewal.<br/><br/>
        
            The gold threads stimulate the body’s natural development of new collagen (neocollagenesis) in response to a controlled trauma or foreign body reaction, similar to other cosmetic procedures like medical microneedling. As a result, the skin becomes thicker and generally a lot more supple. In certain persons, it may also have a modest tightening or lifting effect. However, compared to therapies like skin needling, the effects of gold filament treatment are far more long-lasting.<br/><br/>
        
            By thickening the skin through this neocollagenesis process, the treatment aims to increase its elasticity, firmness, and suppleness. Even though they are considered a more long-lasting choice than some thread or suture products, slow thread fragmentation may occur with partial resorption by phagocytosis, when the body gradually absorbs the threads. The new collagen structure will stay in place and the person won’t notice this.`
        },



    ]

    const facialTreatmentSer = facialTreatment.map(surgery =>
        surgery.pageName
            .toLowerCase()
            .replace(/&/g, 'and') // Replace & with 'and'
            .replace(/[^a-z0-9-]/g, '-') // Replace all other non-alphanumeric characters with hyphens
    );

    const dental = [
        {
            title: "DENTAL",
            pageName: "Cosmetic Dentistry",
            desc: `With just a small change in your smile and nothing else, you would find a dramatic transformation in your personality, confidence, and appearance. Cosmetic dentistry is a set of different treatments designed to deliver you the perfect smile – from teeth whitening to complete smile makeovers.<br/><br/>

            One of our popular treatments is the teeth whitening procedure, which can help to improve your smile’s appearance for a healthier-looking, brighter set of teeth in no time! When it comes to Cosmetic Dentistry, teeth whitening is the most effective, affordable, and speedy way to transform your teeth’ aesthetic. Moreover, it requires little to no downtime and gets impressive results with minimal sensitivity!<br/><br/>
        
            Veneers are a quick and effective solution for misaligned, stained, or chipped teeth. They also cover up gaps where orthodontics isn’t suitable. In this treatment, a thin, custom-made porcelain moulding will be bonded to the front surface of a tooth. Veneers offer a natural look, as they have the same colour, shape, and size as your original teeth.<br/><br/>
        
            Another popular cosmetic dentistry option is a smile makeover, which gives you an opportunity to exclusively design your perfect smile.<br/><br/>
        
            We can assure you that at Cosmo Health Medical Center UAE, you will be examined by a highly qualified specialist who has an artistic eye that makes them the perfect choice to create a naturally perfect pleasing smile.`
        },
        {
            title: "DENTAL",
            pageName: "General Dentistry Treatment",
            desc: `General dentistry refers to diagnosing, preventing, and treating various conditions, diseases, and disorders that may affect your teeth, gums, and jawbone. It involves regular check-ups, digital X-rays, and cleaning or polishing of your teeth on a daily basis. During the examination, the pictures of your teeth are placed on a screen to let you know about the condition and treatments you need. Dentists provide oral health care and periodontal maintenance for patients of all ages.<br/><br/>
    
            Cosmo Health Medical Center brings together the highest standards of professionalism and excellence in general dentistry within a clean, comfortable, and safe environment. Our highly skilled and experienced dentists provide the best possible care for each patient.  is well equipped with the latest technology, so it offers an advanced level of treatment.<br/><br/>
            
            <ul>
                <li>- Teeth sensitivity to hot or cold</li>
                <li>- Gums that bleed while brushing your teeth</li>
                <li>- Bad breath</li>
                <li>- Bad taste in mouth</li>
                <li>- Pain when chewing</li>
                <li>- Any medical condition that risks your oral health.</li>
                <li>- Maintenance of restorative work such as crowns, fillings, or dental implants, and Braces (Invisalign).</li>
            </ul><br/><br/>
            
            Get in touch with us to book an appointment.`
        },
        {
            title: "DENTAL",
            pageName: "Dental Implant",
            desc: `A lot of people have been suffering from loose dentures, multiple missing teeth, or worn & broken-down teeth. The perfect solution for this is dental implants. Finecare Clinic offers a wide range of implant and surgical treatments to transform a patient’s smile and help to get their confidence back again.<br/><br/>
    
            A dental implant is an artificial tooth root placed into a jaw to hold a replacement tooth or bridge. It has long been considered the treatment of choice for missing teeth. This is because dental implants are a permanent, long-lasting, and fixed solution for missing teeth; Implants can replace & restore missing teeth without cutting down healthy teeth on either side of the gaps.<br/><br/>
            
            Dental Implants are tiny titanium screws placed into the jawbone to act as the replacement of tooth roots. The new artificial tooth will be secured in place onto the implant anchor or false ‘root’.<br/><br/>
            
            If you are missing multiple teeth, implant-supported dentures can be the perfect solution. Other common treatments like fixed bridges or removable partial dentures are dependent on support from adjacent teeth. But these implant-supported bridges replace teeth without sacrificing the health of neighboring teeth, in addition to looking and functioning like natural teeth.<br/><br/>
            
            Book a consultation with us today and get more about the benefits of dental implants and all the treatment options available to you.`
        },
        {
            title: "DENTAL",
            pageName: "Pediatric Dentistry",
            desc: `<strong style="font-size: 24px;">Welcome to Our Pediatric Dentistry Services</strong><br/><br/>
            At Cosmo Health Medical Center we specialize in providing exceptional pediatric dentistry services to ensure the oral health and happiness of your children. Our dedicated team of dental professionals is committed to creating a friendly, welcoming environment where kids feel comfortable and cared for during their dental visits. <br/><br/>
            <strong style="font-size: 20px;">Why Choose Us?</strong><br/><br/>
            <strong style="font-size: 18px;">Expert Care:</strong> Our pediatric dentists have extensive experience and training in treating children of all ages. We understand the unique dental needs of growing children and tailor our treatments accordingly.<br/>
            <strong style="font-size: 18px;">Child-Friendly Environment:</strong> We have designed our clinic to be a fun and relaxing place for kids. From the waiting area to the treatment rooms, every aspect of our office is geared towards making your child’s visit enjoyable and stress-free.<br/>
            <strong style="font-size: 18px;">Comprehensive Services:</strong> We offer a full range of pediatric dental services, including routine check-ups, cleanings, fluoride treatments, sealants, fillings, and more. Our goal is to provide preventive care and early intervention to keep your child’s smile healthy.<br/>
            <strong style="font-size: 18px;">Education and Prevention:</strong> We believe in empowering parents and children with the knowledge they need to maintain good oral hygiene. During visits, we provide tips and guidance on proper brushing, flossing, and diet to help prevent dental issues.<br/>
            <strong style="font-size: 18px;">Advanced Technology:</strong> Our clinic is equipped with the latest dental technology to ensure accurate diagnostics and effective treatments. We use child-friendly techniques and equipment to minimize discomfort and anxiety by using the latest technologies in pediatric dentistry, such as STA computerized anesthesia or nitrous oxide inhalation sedation by new technology (Baldus).`
        },
        {
            title: "DENTAL",
            pageName: "Invisalign",
            desc: `
            Invisalign® is an advanced clear aligner system, preferred by teens and adults who want to straighten their teeth discreetly and efficiently. The treatment involves wearing custom-made, removable aligners that gradually move your teeth into their ideal position. Unlike traditional braces, Invisalign offers a more comfortable and aesthetically pleasing solution, with the added benefit of typically delivering faster results.<br/><br/>
            `
        },
    ]

    const dentalSer = dental.map(surgery =>
        surgery.pageName
            .toLowerCase()
            .replace(/&/g, 'and') // Replace & with 'and'
            .replace(/[^a-z0-9-]/g, '-') // Replace all other non-alphanumeric characters with hyphens
    );

    const ivDrip = [
        {
            title: "IV DRIP",
            pageName: "Immune Booster IV Drip",
            desc: `Stress, lack of exercise, exposure to sunlight, lack of sleep, tobacco, and alcohol suppress the immune system and expose us to infections and chronic diseases. Immune Booster IV drip is the latest IV drip that boosts your immune system with Vitamin C, B12, Glutathione, and Electrolytes.<br/><br/>
                   IV Immune Booster of these vitamins & nutrients is much more effective than taking oral supplements. Only 10% of the vitamins from oral supplements are absorbed in the gastrointestinal tract. With the Immune booster, the key nutrients are delivered into your body 100% and immediately.<br/><br/>
                   <strong>Advantages:</strong><br/>
                   - Help your immune system to be in the best condition.<br/>
                   - Reduces tiredness, stress, and fatigue.<br/>
                   - Decreases oxidative stress caused by free radicals.<br/><br/>
                   Our IV Immunity Booster helps your body to recover full health.`
        },
        {
            title: "IV DRIP",
            pageName: "Anti-hair Loss Drip",
            desc: `Hair is often a reflection of our identity, signifying self-confidence and self-care. If your hair looks overly oily or dry and frizzy, then it can have a significant impact on your self-esteem.<br/><br/>

            The presence of grey hairs, dandruff, split ends, and hair loss can indicate malnutrition and poor general health. A lack of vitamins and minerals may lead to pale, brittle nails with white spots and ridges that can exacerbate hair problems. The Hair IV was created to improve the condition of your hair and nails along with your overall health. A combination of B vitamins and minerals is included in this IV to support and strengthen the body. As opposed to most other treatments that aim to improve the superficial appearance of the hair and nails, Hair and Nails IV therapy works by treating the underlying problem and restoring natural health.<br/><br/>
        
            Anti hair loss IV is developed based on the hair production cycle of renewal and shedding. The therapy is composed of two infusion bags; the first contains calcium gluconate, iron, potassium chloride, magnesium gluconate, and B Complex and the second combines B12 and zinc.<br/><br/>
        
            Hormonal changes in the body affect Calcium levels, so menopausal women are often found to be suffering a deficiency in calcium. The second infusion bag contains B12 and zinc, which are required for DNA synthesis, the cell reproduction process occurring in the hair production cycle.`
        },
        {
            title: "IV DRIP",
            pageName: "Anti-aging IV Therapy",
            desc: `There are many aesthetic treatments available that can successfully treat lines and wrinkles, but most do not address the core problem of cellular damage caused by ageing. With glutathione – the mother of all antioxidants – our Anti-Ageing Booster IV helps fight the free radicals responsible for a number of ageing signs.<br/><br/>
            Additionally, this drip contains vital vitamin C and powerful B vitamins, essential for collagen production, which gives skin its elasticity. The B vitamins also support and repair our muscles when they are damaged, and also help to flush out lactic acid from the muscles. If you suffer muscular aches and pains regularly, you may be vitamin B deficient. Rich in these nutrients, Anti-Ageing Booster IV helps you to fight against free radicals that cause the signs of ageing.`
        },
        {
            title: "IV DRIP",
            pageName: "NAD",
            desc: `NAD also known as, Nicotinamide adenine dinucleotide, is a co-enzyme found in body cells, which is involved in the major metabolic functions of the cell. As we age, the production of this essential co-enzyme becomes less. The main functions of NAD are, to convert nutrients into energy and the regulation intracellular processes for long-term cellular health.
            The powerful anti-ageing properties of NAD treat age-related cell damage. The best way to absorb NAD is by intravenous injection. Our NAD + IV drip special formula is freshly prepared, and after consultation it will be given directly into your blood stream while being supervised by an experienced doctor and nurse. This will help you achieve a youthful feel and look by increasing the levels of NAD+ in your body. NAD also helps in repairing DNA damage from environmental stressors. Additionally, sirtuin genes, which are longevity genes, are activated, which decreases systemic inflammation.`
        },
        {
            title: "IV DRIP",
            pageName: "Jet Lag Booster",
            desc: `IV DRIP
            Jet Lag Booster
            Book Appointment
            When a body travels quickly through different time zones, its natural circadian rhythms are interrupted, resulting in jet lag or desynchronosis. Your 24-hour sleep-wake cycle, also referred to as your circadian rhythm or body clock, regulates your body’s physiological, biochemical, and behavioral processes. Although it is an internal mechanism for tracking time, we train it to control our actions from day to night by using outside forces.
    
            Jet lag is caused by upsetting and disrupting our internal clock, and its typical symptoms include anger, fatigue, lethargy, and the inability to focus. Additionally, you may encounter stomach issues, sleeplessness, loss of appetite, and dizziness. This only occurs when time zones are crossed, and it is typically more severe when traveling from the west.
            
            You’ve reached your location, but instead of rushing into meetings or venturing outside to view the sites, you’re completely exhausted after the flight. Your circadian rhythm can be quickly restored with the aid of an intravenous drip that administers vitamins and minerals directly into the bloodstream. They can also get rid of all of the effects of jet lag.
            
            Our Hydration IV Booster is a saline and electrolyte concoction that replenishes lost fluids, restores fluid balance, and flushes out lactic acid and toxins accumulated during our voyage. The Myers Cocktail, which combines vitamins C, B-12, B5, and B6, as well as crucial minerals like magnesium, selenium, and calcium, was the first and is still the most popular vitamin drip. These minerals can help reset your body clock because they are all tied to a poor regulation of it. Magnesium can also aid in muscle relaxation, which will hasten the process of falling asleep.`
        },
        {
            title: "IV DRIP",
            pageName: "Multivitamin Drip For Women",
            desc: `Even though we consume organic, healthy food and take supplements, we could constantly feel exhausted. We frequently discover that this is a result of digestive problems that hinder the appropriate absorption of nutrients. Given that up to 50% of the nutrients we take via food and oral supplements are never absorbed, it makes sense why so many of us constantly feel exhausted. With Multi-vitamin IV drips, infusions, and shots, the gut is not involved, resulting in 90–100% absorption and practically immediate benefits.<br/><br/>

            The best multivitamins for women comprise essential vitamins and minerals that have been shown to promote your body’s natural operation. Our multivitamin trip has combined Ashwagandha and Korean Ginseng for energy and cognition, ensuring that you never miss a beat. It also contributes to the regulation of hormonal activity.`
        },
        {
            title: "IV DRIP",
            pageName: "Post-surgery Recovery",
            desc: `If you recently had surgery, especially if it was an emergency procedure, and you notice that your recuperation is not going as well as you had planned, IV vitamin therapy will start supplying essential nutrients right away. To get the most benefit from this treatment, you should begin your IV vitamin therapy prior to surgery, even if it’s just by a few weeks. This will help to ensure that you are not missing out on essential nutrients that could help your recovery.<br/><br/>

    We provide a variety of IV Vitamin Therapy procedures at Finecare Clinic in UAE that can increase vitamin and mineral levels. To get the best outcomes, we frequently combine therapies:<br/><br/>

    <strong>High-Dose Intravenous Vitamin C:</strong> is a vitamin powerhouse that plays a crucial part in collagen creation as well as acting as a highly effective agent to maintain a healthy immune system.<br/><br/>

    <strong>The Myers’ Cocktail:</strong> The potent combination of minerals and vitamins in the Myers’ Cocktail makes it a crucial component of any IV vitamin therapy treatment regimen, even though vitamin C can be prescribed as a very successful stand-alone treatment.<br/><br/>

    <strong>Glutathione IV:</strong> Glutathione is a very potent antioxidant that might lessen the oxidative stress brought on by the generation of free radicals throughout your procedure.`
        },
        {
            title: "IV DRIP",
            pageName: "Detox Drip",
            desc: `Increase your metabolism and get rid of the toxins that are stopping you from moving forward.<br/><br/>
            As we become more aware of the effects of toxins on the body’s capacity to function, detox has emerged as the next diet buzzword. The body will store toxins in your fat cells, in order to keep them away from your important organs, making them more difficult to remove.<br/><br/>
            In addition, our Detoxification IV drip contains a number of amino acids, such as glycine, taurine, arginine, and ornithine, which are crucial for the detoxification procedure and mix with and neutralize toxins in the liver. Our Detoxification IV drip is a potent blend of nutrients that can be a very helpful aid, but in order to get the results you want, you must also commit to a diet and exercise program.`
        },
        {
            title: "IV DRIP",
            pageName: "Athletic Choice",
            desc: `By supplying essential energy-boosting vitamins and amino acids, the Athletic Choice drip can increase your performance and prevent the comedown. Key B vitamins and amino acids like carnitine and taurine found in our Performance Booster  assist the body’s conversion of nutrients into energy.
            Additionally, magnesium and calcium, which are crucial for muscle function, are included in our Performance Booster IV. If you exercise regularly, you’ve certainly experienced hurting muscles and joints at some point. A lack of magnesium can cause muscle cramping and spasms. Magnesium can hasten your recuperation and aid with injury avoidance.        
            We’ve also added an electrolyte injection solution, which is necessary for adequate hydration. Electrolytes keep the body’s fluid balance in check, and any physical activity can quickly deplete them, leading to cramps, exhaustion, weakened muscles, and joint issues.`
        },
        {
            title: "IV DRIP",
            pageName: "Party Rescue IV Drip",
            desc: `Dehydration is the primary factor in hangovers. By refueling yourself with fluids beforehand, you can take whatever the night throws at you with ease. The additional B-complex will ensure that your body is properly equipped to manage anything you have planned for the evening.<br/><br/>

            By receiving an IV infusion of water and nutrients, you may stay hydrated, active, and lessen the unpleasant after-party hangover symptoms. Preparing for a big party, event, or after-party has been simpler with an IV drip hydration treatment. We’ll take care of your hydration, vitamins, and electrolytes need so you can stay joyful and energized throughout the night or the next day.`
        },
        {
            title: "IV DRIP",
            pageName: "Mood Enhancer Drip",
            desc: `
            Everyone experiences stress, but when it begins to have a severe impact on your health and well-being, it is critical to address it right away.
            
            Our IV drips can provide quick stress release. They can be carefully put together to maintain healthy cognitive function, rebalance your mind, and minimize exhaustion and fatigue.
            
            At Finecare Clinic, a high dose of glutamine, carnitine, and ornithine is added to our mood booster drip IV along with a mix of vitamins and minerals. These amino acids have been shown to give you the strength to deal with everyday life.`
        },
    ]

    const ivDripSer = ivDrip.map(surgery =>
        surgery.pageName
            .toLowerCase()
            .replace(/&/g, 'and') // Replace & with 'and'
            .replace(/[^a-z0-9-]/g, '-') // Replace all other non-alphanumeric characters with hyphens
    );

    const surgeries = [
        {
            title: "SURGERIES",
            pageName: "Gynecomastia",
            desc: `
            <h3>Overcome Gynecomastia with Advanced Treatment</h3>
    Gynecomastia, often referred to as male breast enlargement or "man boobs," is a common condition that affects 1 in 10 men. It can lead to discomfort, self-consciousness, and a decrease in quality of life. At Cosmo Health Medical Center, we offer effective solutions to help you regain your confidence and achieve a more masculine chest.
    <br></br>
    <h4>What is Gynecomastia?</h4>
    Gynecomastia is the enlargement of breast tissue in men, often caused by hormonal imbalances, certain medications, or underlying health conditions. It can affect one or both breasts, sometimes unevenly. While it's not a serious health issue, it can significantly impact a man’s self-esteem and body image.
    <br></br>
    <h4>Symptoms of Gynecomastia</h4>
    <ul>
      <li>Swollen breast gland tissue</li>
      <li>Breast tenderness</li>
      <li>Pain or discomfort in the chest area</li>
      <li>Emotional distress or embarrassment</li>
    </ul>
    <br>
    <h4>Our Treatment Options</h4>
    At Cosmo Health Medical Center, we provide cutting-edge treatments for gynecomastia, tailored to each individual’s needs. Our treatment options include:
    <ul>
      <li><strong>Liposuction:</strong> A minimally invasive procedure that removes excess fat from the breast area, resulting in a flatter and more contoured chest.</li>
      <li><strong>Surgical Excision:</strong> In cases where glandular tissue or excess skin needs to be removed, surgical excision is performed to achieve optimal results.</li>
    </ul>
    <br>
    <h4>Why Choose Us?</h4>
    <ul>
      <li><strong>Experienced Specialists:</strong> Our team of skilled professionals has extensive experience in treating gynecomastia, ensuring you receive the best care possible.</li>
      <li><strong>Advanced Facilities:</strong> We use the latest technology and techniques to deliver effective and safe treatments.</li>
      <li><strong>Personalized Care:</strong> We understand that every patient is unique. Our specialists will work with you to develop a customized treatment plan that addresses your specific concerns and goals.</li>
      <li><strong>Comfort and Privacy:</strong> Your comfort and privacy are our top priorities. We strive to create a supportive and confidential environment for your treatment.</li>
    </ul>
    <br>
    <h4>Benefits of Gynecomastia Treatment</h4>
    <ul>
      <li>Improved Chest Contour: Achieve a flatter, more masculine chest appearance.</li>
      <li>Boosted Confidence: Feel more confident and comfortable in your body.</li>
      <li>Quick Recovery: Our minimally invasive techniques ensure a faster recovery time with minimal downtime.</li>
      <li>Long-Lasting Results: Enjoy lasting results that enhance your quality of life.</li>
    </ul>
    <br>
    <h4>Take the First Step Towards a More Confident You</h4>
    Don’t let gynecomastia hold you back any longer. At Cosmo Health Medical Center, we are committed to helping you achieve the chest contour you desire. Schedule a consultation with our experts today to learn more about our gynecomastia treatment options and take the first step towards a more confident you.
    Contact us now to book your appointment and start your journey to a better you!</p>
   `
        },

        {
            title: "SURGERIES",
            pageName: "Gastric Balloon & Smart Capsule",
            desc: `
           <h3>Gastric Balloon</h3>
                
Gastric balloon is a non-surgical weight loss solution designed for individuals seeking to lose weight and improve their overall health. The procedure involves placing a soft, durable balloon into the stomach via an endoscopic procedure. Once in place, the balloon is inflated with saline, occupying space in the stomach to help you feel fuller faster and eat less.
<br></br>
<h4>How Does it Work?</h4>
<strong>Placement:</strong> The balloon is inserted into the stomach using an endoscope. <br>
<strong>Inflation:</strong> Once in the stomach, the balloon is filled with saline solution. <br>
<strong>Effect:</strong> The balloon partially fills the stomach, reducing its capacity and helping to control portion sizes and appetite.
<br></br>
<h4>Benefits of the Gastric Balloon</h4>
<strong>Non-Surgical:</strong> No incision and minimally invasive. <br>
<strong>Temporary:</strong> The balloon is usually removed after six months. <br>
<strong>Effective:</strong> Helps patients lose a significant amount of weight in a short period. <br>
<strong>Improved Health:</strong> Can lead to improvements in conditions like diabetes, hypertension, and sleep apnea.
<br></br>
<h4>Are you a right Candidate?</h4>
The gastric balloon is ideal for individuals with a BMI of 30-40 who are tired of traditional weight loss methods such as diet and exercise. It is also suitable for those who are looking for a non-surgical option to jumpstart their weight loss journey.
<br></br>
<h4>The Procedure</h4>
<strong>Consultation:</strong> Consultation with our General & Laparoscopic Surgeon to discuss suitability and expectations. <br>
<strong>Placement:</strong> The procedure takes about 20-30 minutes under anesthesia. <br>
<strong>Follow-Up & Dietitian Support:</strong> Regular check-ups to monitor progress and make dietary adjustments.
<br></br>
<h3>Allurion Capsule</h3>
                
The Allurion Capsule, formerly known as the Elipse Balloon, is a revolutionary weight loss program designed for those who prefer a non-invasive method. This capsule is swallowed and then inflated in the stomach to help with weight loss by creating a feeling of fullness, which reduces food intake.
<br></br>
<h4>How Does it Work?</h4>
<strong>Swallowing:</strong> The capsule is swallowed with water. <br>
<strong>Inflation:</strong> Once in the stomach, the capsule is filled with water through a thin tube that is then removed. <br>
<strong>Effect:</strong> The capsule expands, creating a feeling of fullness and helping to reduce hunger and portion sizes.
<br></br>
<h4>Benefits of the Allurion Capsule</h4>
<strong>Non-Invasive:</strong> No surgery or endoscopy required for placement. <br>
<strong>Temporary:</strong> The capsule naturally deflates and passes through the digestive system after approximately 16 weeks. <br>
<strong>Effective:</strong> Patients can lose an average of 10-15% of their body weight. <br>
<strong>Convenient:</strong> Simple, quick procedure with minimal downtime.
<br></br>
<h4>Are you a right Candidate?</h4>
The Allurion Capsule is suitable for individuals with a BMI of 27 and above who are looking for a non-surgical, temporary weight loss solution. It is particularly beneficial for those who want a less invasive option compared to traditional weight loss surgeries.
<br></br>
<h4>The Procedure</h4>
<strong>Consultation:</strong> Consultation with our General & Laparoscopic Surgeon to discuss suitability and expectations. <br>
<strong>Swallowing:</strong> The capsule is swallowed with water and an X-ray is taken to ensure the position. <br>
<strong>Monitoring:</strong> Regular follow-ups to track progress and ensure optimal results. Allurion smartwatch & Allurion electronic scale is provided for monitoring.

                `
        },
        {
            title: "SURGERIES",
            pageName: "Liposuction",
            desc: `Liposuction, also known as fat removal surgery, is a cosmetic procedure designed to remove unwanted fat from specific areas of the body using a suction technique, resulting in a more defined and contoured shape. This procedure is suitable for both men and women and can target various parts of the body, including:
            <ul>
                <li>- Upper arms</li>
                <li>- Back</li>
                <li>- Inner and outer thighs</li>
                <li>- Flanks (love handles)</li>
                <li>- Hips</li>
                <li>- Face, neck, and chin</li>
                <li>- Stomach</li>
                <li>- Calves</li>
            </ul>
            Liposuction is effective in eliminating stubborn fat that typically resists diet and exercise efforts. It is widely regarded as the most effective technique for removing stubborn fat deposits in specific areas.<br/><br/>
            <strong>How long does the result of liposuction last?</strong><br/>
            The results of liposuction can be long-lasting. During the procedure, fat cells are permanently removed from the treated area, creating a more defined body shape. Since these fat cells do not regenerate, the results can be permanent, provided you maintain a healthy lifestyle that includes a balanced diet and regular exercise.`
        },

        {
            title: "AESTHETIC",
            pageName: "Bariatric Surgery",
            desc: `
              <b>Understanding Bariatric Surgery Options: A Comprehensive Guide</b><br/>
          
             <br/> <b>Introduction</b><br/>
Looking to achieve sustainable weight loss? You might be considering different surgical 
options. Some popular bariatric surgeries are Roux-en-Y Gastric Bypass (RYGB), Mini 
Gastric Bypass (MGB), and Sleeve Gastrectomy (SG). Each of these procedures has its 
own benefits and considerations. This guide will thoroughly compare these surgeries to 
help you make an informed decision.          
              <br/></br>
              <b>Weight Loss Efficacy and Surgical Complexity</b><br/>
When considering bariatric surgery, it is essential to evaluate both weight loss 
effectiveness and the level of surgical complexity. Let's explore these factors:

              </br>
            </br>
                            <img src="https://static.wixstatic.com/media/4e5aea_42693663354e47959c88986dfaf9b16a~mv2.png/v1/fill/w_925,h_396,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/4e5aea_42693663354e47959c88986dfaf9b16a~mv2.png" alt="Bariatric Surgery Options" width="100%"/><br/>
</br>
            </br>
              <h5><strong>Weight Loss Efficacy</strong></h5>
RYGB is known for its high long-term weight loss efficacy when it comes to losing extra 
weight, typically resulting in a 70-80% excess weight reduction. MGB often offers 
slightly higher weight loss, around 75-85%. On the other hand, SG results in about 60-
70% excess weight loss, making it a slightly less effective option in terms of weight loss.

<h5><strong>Surgical Complexity</strong></h5>
Are you considering the surgery itself? RYGB is more complex because it involves 
creating a small pouch and rerouting the intestines. MGB is more straightforward and 
shorter because it involves less intestinal rerouting. SG is the least complex, as it only
involves reducing the size of the stomach.
             
          
            </br></br>
              <b>Risk of Complications and Resolution of Diabetes</b><br/>
Next, let's discuss the risk of complications and the resolution of diabetes with each type 
of surgery.         
<div class="table" style="display: flex; flex-wrap: wrap; width: 100%; border: 1px solid black;">
  <!-- Row 1 -->
  <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center; font-weight: 900">
    Risk of Complications
  </div>
  <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center; word-wrap: break-word; word-break: break-word;">
    Moderate; includes risk of leaks, strictures, and nutrient deficits
  </div>
  <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center; word-wrap: break-word; word-break: break-word;">
    Lower risk than RYGB but can have bile reflux
  </div>
  <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center; word-wrap: break-word; word-break: break-word;">
    Lower risk; primarily related to leaks and strictures
  </div>
  
  <!-- Row 2 -->
  <div class="cell" style="font-weight: 900; width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">
    Resolution of Diabetes
  </div>
  <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center; word-wrap: break-word; word-break: break-word;">
    High; significant improvement in glycemic control
  </div>
  <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center; word-wrap: break-word; word-break: break-word;">
    Comparable to RYGB; may have slight advantage
  </div>
  <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center; word-wrap: break-word; word-break: break-word;">
    Moderate; less effective than bypass procedures
  </div>
  
  <!-- Row 3 -->
  <div class="cell" style="font-weight: 900; width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">
    Nutritional Deficiencies
  </div>
  <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center; word-wrap: break-word; word-break: break-word;">
    Common; requires lifelong nutritional supplementation
  </div>
  <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center; word-wrap: break-word; word-break: break-word;">
    Less common than RYGB but more than SG
  </div>
  <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center; word-wrap: break-word; word-break: break-word;">
    Less common; some risk due to reduced stomach size
  </div>
  
  <!-- Row 4 -->
  <div class="cell" style="font-weight: 900; width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">
    Reversibility/Modifiability
  </div>
  <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center; word-wrap: break-word; word-break: break-word;">
    Technically reversible but complex
  </div>
  <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center; word-wrap: break-word; word-break: break-word;">
    Easier to reverse or modify than RYGB
  </div>
  <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center; word-wrap: break-word; word-break: break-word;">
    Not reversible; permanent removal of stomach portion
  </div>
</div>
              <br/><br/>
              <h5><b>Risk of Complications</b></h5>
All surgeries carry risks, but the extent of these risks varies. RYGB has moderate risks, 
including potential leaks, strictures, and nutrient deficits. MGB carries a lower risk than 
RYGB but can lead to bile reflux. SG has the lowest risk and is primarily associated with 
leaks and strictures.    
<h5><b>Resolution of Diabetes</b></h5>
"One of the major advantages of bariatric surgery is the improvement or resolution of 
type 2 diabetes. Roux-en-Y gastric bypass (RYGB) has high rates of diabetes resolution, 
leading to significant improvement in glycemic control. Mini gastric bypass (MGB) has 
similar outcomes, with some studies indicating a slight advantage. Sleeve gastrectomy 
(SG) is also effective, although less so than the bypass procedures."

<h5><b>Nutritional Deficiencies</b></h5>
After bariatric surgeries, patients may experience nutritional deficiencies. Those who 
have undergone RYGB typically require lifelong nutritional supplementation to address 
common deficiencies. MGB patients tend to have fewer deficiencies than RYGB patients, 
but more than SG patients. SG patients have the lowest risk of deficiencies, although 
some supplementation may still be necessary. <br></br>

              <b>Impact on Gut Hormones, Recovery Time, Long-term Maintenance, and Patient Satisfaction</b><br/>
              Lastly, let's explore the impact on gut hormones, recovery time, long-term maintenance, 
and patient satisfaction. 
<div class="table" style="display: flex; flex-wrap: wrap; width: 100%; border: 1px solid black;">
               
                <div class="cell" style="font-weight: 900; width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">Impact on Gut 
Hormones</div>
                <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">Significant; alters 
hormones like ghrelin 
and GLP-1</div>
                <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">Similar impact to 
RYGB; affects 
satiety and hunger</div>
                <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">Less alteration; 
mainly reduction in 
ghrelin</div>
                
                <div class="cell" style="font-weight: 900;width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">Recovery Time</div>
                <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">Longer due to 
complexity; around 
2-3 weeks</div>
                <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">Shorter than RYGB; 
typically, 1-2 weeks</div>
                <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">Shortest; about 1-2 
weeks</div>
                
                <div class="cell" style="font-weight: 900;width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">Long-term 
Maintenance</div>
                <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">Requires strict 
adherence to dietary 
and vitamin 
supplementation</div>
                <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">Less stringent than 
RYGB but more than 
SG</div>
                <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">Requires moderate 
dietary adjustments</div>
                
                <div class="cell" style="font-weight: 900;width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">Patient Satisfaction</div>
                <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">High, particularly due 
to significant health 
improvements</div>
                <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">Generally high; 
slightly less data 
available than RYGB</div>
                <div class="cell" style="width: 25%; border: 1px solid black; box-sizing: border-box; padding: 8px; text-align: center;">High; depends on 
expectations and 
weight loss results</div>
            </div> 
<br></br>
<h5><b>Impact on Gut Hormones</b></h5>
The reversibility and impact on gut hormones differ among different types of weight loss 
surgeries. RYGB can technically be reversed but it is a complex procedure. MGB is more 
easily reversed or modified. SG, on the other hand, is not reversible because it involves 
the permanent removal of a portion of the stomach. In terms of hormonal impact, RYGB 
and MGB have significant effects on hormones such as ghrelin and GLP-1, which 
influence feelings of fullness and hunger, while SG has a lesser effect on these hormones.

<h5><b>Recovery Time</b></h5>
Recovery time is an important factor to consider when comparing different bariatric 
surgeries. RYGB typically requires a longer recovery period of 2-3 weeks, MGB usually 
requires 1-2 weeks, and SG has the shortest recovery time of around 1-2 weeks.

<h5><b>Long-term Maintenance</b></h5>
Long-term maintenance differs among different bariatric surgeries. RYGB requires strict 
dietary guidelines and vitamin supplementation, MGB is less strict but still more than 
SG, which requires moderate nutritional adjustments.

<h5><b>Patient Satisfaction</b></h5>
Patients tend to be highly satisfied with all surgeries, but their satisfaction levels can vary 
based on their individual expectations and results. RYGB patients generally report high 
satisfaction due to significant health improvements. MGB patients also report high 
satisfaction, but there is slightly less data available. The satisfaction levels of SG patients 
mainly depend on their weight loss results and overall health improvements.
<br/><br/>
<b>Conclusion</b></br>
When choosing the most suitable bariatric surgery for yourself, it is important to consider 
several factors. These factors include the effectiveness of weight loss, the complexity of 
the surgery, the risk of complications, and the long-term results. By understanding the 
differences between RYGB, MGB, and SG procedures, you can make a more informed 
decision about which one will best suit your health goals and lifestyle. It is always 
advisable to consult with a qualified bariatric surgeon to discuss your options and 
determine the most suitable course of action for your specific needs.
        `
        }




    ]

    const surgeriesSer = surgeries.map(surgery =>
        surgery.pageName
            .toLowerCase()
            .replace(/&/g, 'and') // Replace & with 'and'
            .replace(/[^a-z0-9-]/g, '-') // Replace all other non-alphanumeric characters with hyphens
    );
    const [showContact, setShowContact] = useState(false);
    const [show, setShow] = useState(false);
    const [newActiveTreatment, setNewActiveTreatment] = useState({});

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseContact = () => setShowContact(false);
    const handleShowContact = () => setShowContact(true);

    const { service } = useParams();

    const [selectedSpeciality, setSelectedSpeciality] = useState('');
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('');


    const specialities = Array.from(new Set(allDoctors.map((doctor) => doctor.speciality)));

    const filteredDoctors = allDoctors.filter((doctor) => {
        return (!selectedSpeciality || doctor.speciality === selectedSpeciality) &&
            (selectedLanguages.length === 0 ||
                selectedLanguages.includes('All') ||
                selectedLanguages.some(language => doctor.languages.includes(language))) &&
            (!selectedBranch || doctor.branch === selectedBranch);
    });

    const handleSpecialityChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedSpeciality(selectedValue);
    };

    const handleLanguageChange = (event) => {
        const selectedValues = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedLanguages(selectedValues);
    };

    const handleBranchChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedBranch(selectedValue);
    };

    useEffect(() => {
        console.log('Speciality:', service);
        const allLists = [...aesthetics, ...LaserTreatment, ...wellness, ...facialTreatment, ...dental, ...ivDrip, ...surgeries]; // Add all your lists here
        console.log(allLists)
        const foundItem = allLists.find(item => item.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-') === service);
        setNewActiveTreatment(foundItem)


    }, [service]);

    return (
        <>
            <Modal show={showContact} onHide={handleCloseContact}>

                <Modal.Body style={{ textAlign: "center" }}>
                    <h4>Select the nearest branch</h4>
                    <Button target="_blank" href='https://wa.me/971585100850' style={{ marginRight: "20px", backgroundColor: "#ab7f21", borderColor: "#ab7f21" }}>Mirdif</Button>
                    <Button target="_blank" href='https://wa.me/971509428554' style={{ backgroundColor: "#ab7f21", borderColor: "#ab7f21" }}>Marina</Button>
                </Modal.Body>

            </Modal>
            <Appointment show={show} handleClose={handleClose} />

            {service === 'liposuction' && (
                <>
                    <Helmet>
                        <title>Liposuction in UAE | Best liposuction surgeons in UAE</title>
                        <meta name="description" content="Discover expert liposuction in UAE with experienced surgeons. Transform your body with the best liposuction surgeons in UAE." />
                        {/* Add more meta tags if needed */}
                    </Helmet>
                    <div className="intro_image">
                        <img src={surgeriesPic} alt="Liposuction" style={{ marginTop: "50px", zIndex: "-1" }} />
                    </div>
                </>
            )}
            {service === 'laser-hair-removal' && (
                <>
                    <Helmet>
                        <title>Laser Hair Removal in UAE | Full Body Laser Hair Removal</title>
                        <meta name="description" content="Say goodbye to the hassle of shaving and waxing! Schedule your laser treatment today." />
                        {/* Add more meta tags if needed */}
                    </Helmet>
                    <div className="intro_image">
                        <img src={laserRemoval} alt="Liposuction" style={{ marginTop: "50px", zIndex: "-1", width: "100%", marginBottom: "-100px" }} />
                    </div>
                </>
            )}
            {service === 'gynecomastia' && (
                <>
                    {/* <Helmet>
                        <title>Liposuction in UAE | Best liposuction surgeons in UAE</title>
                        <meta name="description" content="Discover expert liposuction in UAE with experienced surgeons. Transform your body with the best liposuction surgeons in UAE." />
                    </Helmet> */}
                    <div className="intro_image">
                        <img src={gyne} alt="Liposuction" style={{ marginTop: "50px", zIndex: "-1" }} />
                    </div>
                </>
            )}

            {service === 'gastric-balloon-and-smart-capsule' && (
                <>
                    <Helmet>
                        <title>Gastric Balloon, Smart Capsule In UAE | Non-Surgical Weight Loss Program</title>
                        <meta name="description" content="Discover non-surgical, painless treatments for weight loss. Visit the best medical center in UAE for the gastric balloon and smart capsule procedures." />
                        {/* Add more meta tags if needed */}
                    </Helmet>
                    <div className="intro_image">
                        <img src={gastric} alt="Liposuction" style={{ marginTop: "50px", zIndex: "-1" }} />
                    </div>
                </>
            )}

            {service === 'invisalign' && (
                <>
                    <Helmet>
                        <title>Get Affordable Invisalign Treatment in UAE with Dr. Anil Ramu</title>
                        <meta name="description" content="Free Invisalign Consultation. Book your appointment with Dr. Anil Ramu for the best clear aligners in UAE." />
                        {/* Add more meta tags if needed */}
                    </Helmet>
                    <div className="intro_image">
                        <img src={invislign} alt="Liposuction" style={{ marginTop: "50px", zIndex: "-1" }} />
                    </div>
                </>
            )}

            {service === 'pediatric-dentistry' && (
                <>
                    <Helmet>
                        <title>Pediatric Dentist in UAE| Ajman| Sharjah| RAK</title>
                        <meta name="description" content="Pediatric Dentist in UAE, UAE | Expert Children's Dental Care
meta description: Looking for a trusted pediatric dentist in UAE? We offer gentle, expert care for a comfortable, stress-free visit." />
                        {/* Add more meta tags if needed */}
                    </Helmet>
                    <div className="intro_image">
                        <img src={pediatricPic} alt="Liposuction" style={{ marginTop: "50px", zIndex: "-1" }} />
                    </div>
                </>
            )}

            {service === 'morpheus8' && (
                <>
                    <Helmet>
                        <title>Morpheus 8 Pro | Skin Tightening in UAE</title>
                        <meta name="description" content="Morpheus 8 Pro | Skin Tightening in UAE | Up to 50% OFF 
                            Description: Experience advanced skin tightening with Morpheus8. Achieve a youthful and rejuvenated appearance with the treatment." />
                        {/* Add more meta tags if needed */}
                    </Helmet>
                    <div className="intro_image">
                        <img src={morpheus} alt="Liposuction" style={{ marginTop: "50px", zIndex: "-1" }} />
                    </div>
                </>
            )}

            {service === 'rhinoplasty' && (
                <>
                    <Helmet>
                        <title></title>
                        {/* <meta name="description" content="Morpheus 8 Pro | Skin Tightening in UAE | Up to 50% OFF 
                            Description: Experience advanced skin tightening with Morpheus8. Achieve a youthful and rejuvenated appearance with the treatment." /> */}
                    </Helmet>
                    <div className="intro_image">
                        <img src={rino} alt="Liposuction" style={{ marginTop: "50px", zIndex: "-1" }} />
                    </div>
                </>
            )}

            {service === 'cosmetic-dentistry' && (
                <>
                    <Helmet>
                        <title></title>
                        {/* <meta name="description" content="Morpheus 8 Pro | Skin Tightening in UAE | Up to 50% OFF 
                            Description: Experience advanced skin tightening with Morpheus8. Achieve a youthful and rejuvenated appearance with the treatment." /> */}
                    </Helmet>
                    <div className="intro_image">
                        <img src={dentalPic} alt="Liposuction" style={{ marginBottom: "-100px", zIndex: "-1" }} />
                    </div>
                </>
            )}

            {service === 'dental-implant' && (
                <>
                    <Helmet>
                        <title></title>
                        {/* <meta name="description" content="Morpheus 8 Pro | Skin Tightening in UAE | Up to 50% OFF 
                            Description: Experience advanced skin tightening with Morpheus8. Achieve a youthful and rejuvenated appearance with the treatment." /> */}
                    </Helmet>
                    <div className="intro_image">
                        <img src={dentalPic} alt="Liposuction" style={{ marginBottom: "-100px", zIndex: "-1" }} />
                    </div>
                </>
            )}

            {(service === 'immune-booster-iv-drip' ||
                service === 'anti-hair-loss-drip' ||
                service === 'anti-aging-iv-therapy' ||
                service === 'nad' ||
                service === 'jet-lag-booster' ||
                service === 'multivitamin-drip-for-women' ||
                service === 'post-surgery-recovery' ||
                service === 'detox-drip' ||
                service === 'athletic-choice' ||
                service === 'party-rescue-iv-drip' ||
                service === 'mood-enhancer-drip') && (
                    <>
                        <Helmet>
                            <title></title>
                            {/* <meta name="description" content="Morpheus 8 Pro | Skin Tightening in UAE | Up to 50% OFF 
                            Description: Experience advanced skin tightening with Morpheus8. Achieve a youthful and rejuvenated appearance with the treatment." /> */}
                        </Helmet>
                        <div className="intro_image">
                            <img src={ivDripBanner} alt="Liposuction" style={{ marginBottom: "-100px", zIndex: "-1" }} />
                        </div>
                    </>
                )}


            {/* <Button className='but' onClick={() => handleShow()} variant="primary" style={{ borderRadius: "25px", backgroundColor: "#ab7f21", borderColor: "#ab7f21", marginTop: "-100px", width: "200px", height: "50px", fontSize: '20px', marginLeft: "300px", position: "absolute" }}>Book Now</Button>{' '} */}

            <div className="intro" style={{ paddingTop: "0px" }}>

                <div className="container">
                    <div className="row" style={{ marginTop: "200px", alignItems: "center" }}>
                        <div className="col">
                            <div className="section_title_container">
                                <div className="section_title" style={{ textAlign: "center", marginTop: "20px" }}>
                                    <div style={{
                                        marginBottom: "30px",
                                        marginTop: newActiveTreatment.pageName === 'Liposuction' || newActiveTreatment.pageName === 'Pediatric Dentistry' || newActiveTreatment.pageName === 'morpheus8' || newActiveTreatment.pageName === 'Gastric Balloon & Smart Capsule' || newActiveTreatment.pageName === 'Invisalign' || newActiveTreatment.pageName === 'Gynecomastia' || newActiveTreatment.pageName === 'Morpheus8' || newActiveTreatment.pageName === 'Rhinoplasty' ? '10px' : '100px'
                                    }}>
                                        {surgeriesSer.includes(service) && (
                                            (newActiveTreatment.pageName === 'Liposuction' ? surgeries.slice().reverse() : surgeries).map((surgery, index) => (
                                                <Button key={index} className="custom-button">
                                                    <Link
                                                        to={`/treatment/${surgery.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`}
                                                    >
                                                        {surgery.pageName}
                                                    </Link>
                                                </Button>
                                            ))
                                        )}

                                        {ivDripSer.includes(service) && (
                                            ivDrip.map((surgery, index) => (
                                                <Button key={index} className="custom-button">
                                                    <Link
                                                        to={`/treatment/${surgery.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`}
                                                    >
                                                        {surgery.pageName}
                                                    </Link>
                                                </Button>
                                            ))
                                        )}

                                        {dentalSer.includes(service) && (
                                            dental.map((surgery, index) => (
                                                <Button key={index} className="custom-button">
                                                    <Link

                                                        to={`/treatment/${surgery.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`}
                                                    >
                                                        {surgery.pageName}
                                                    </Link>
                                                </Button>
                                            ))
                                        )}

                                        {facialTreatmentSer.includes(service) && (
                                            facialTreatment.map((surgery, index) => (
                                                <Button key={index} className="custom-button">
                                                    <Link

                                                        to={`/treatment/${surgery.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`}
                                                    >
                                                        {surgery.pageName}
                                                    </Link>
                                                </Button>
                                            ))
                                        )}

                                        {wellnessSer.includes(service) && (
                                            wellness.map((surgery, index) => (
                                                <Button key={index} className="custom-button">
                                                    <Link

                                                        to={`/treatment/${surgery.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`}
                                                    >
                                                        {surgery.pageName}
                                                    </Link>
                                                </Button>
                                            ))
                                        )}

                                        {LaserTreatmentSer.includes(service) && (
                                            <div className="desktop-button-container">
                                                {LaserTreatment.map((surgery, index) => (
                                                    <Button key={index} className="desktop-custom-button custom-button">
                                                        <Link
                                                            to={`/treatment/${surgery.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`}
                                                        >
                                                            {surgery.pageName}
                                                        </Link>
                                                    </Button>
                                                ))}
                                            </div>
                                        )}

                                        {(
                                            aestheticsSer.includes(service) &&
                                            newActiveTreatment.pageName !== 'Hair Treatment' &&
                                            newActiveTreatment.pageName !== 'Rhinoplasty'
                                        ) && (
                                                aesthetics
                                                    .filter((surgery) => surgery.pageName !== 'Hair Treatment' && surgery.pageName !== 'Rhinoplasty')
                                                    .map((surgery, index) => (
                                                        <Button key={index} className="custom-button">
                                                            <Link
                                                                to={`/treatment/${surgery.pageName.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`}
                                                            >
                                                                {surgery.pageName}
                                                            </Link>
                                                        </Button>
                                                    ))
                                            )}

                                    </div>
                                    {/* <h1 style={{ fontSize: '25px', color: "black" }}>{newActiveTreatment.title}</h1> */}
                                    <h2
                                        style={{
                                            fontSize: '45px',
                                            color: "#ab7f21",
                                        }}
                                    >
                                        {newActiveTreatment.pageName}
                                    </h2>

                                    {service == 'liposuction' && (
                                        <CardGroup style={{ marginTop: "50px" }}>
                                            <Card style={{ border: "none", borderRight: "1px solid white", backgroundColor: "#ab7f21", height: "120px" }}>
                                                <Card.Body>
                                                    <Card.Title><FaDollarSign size={40} color='white' /></Card.Title>
                                                    <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                        Affordable Prices & Flexible Payment Plan
                                                    </Card.Text>
                                                </Card.Body>

                                            </Card>

                                            <Card style={{ border: "none", borderRight: "1px solid white", backgroundColor: "#ab7f21", height: "120px" }}>
                                                <Card.Body>
                                                    <Card.Title><FaUserMd size={40} color='white' /></Card.Title>
                                                    <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                        Highly Experienced Medical Professionals
                                                    </Card.Text>
                                                </Card.Body>

                                            </Card>
                                            <Card style={{ border: "none", backgroundColor: "#ab7f21", height: "120px" }}>
                                                <Card.Body>
                                                    <Card.Title><FaSmileWink size={40} color='white' /></Card.Title>
                                                    <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                        1K Happy Customer
                                                    </Card.Text>
                                                </Card.Body>

                                            </Card>
                                        </CardGroup>


                                    )}

                                    {service == 'invisalign' && (
                                        <>
                                            <CardGroup style={{ marginTop: "50px" }}>
                                                <Card style={{ border: "none", borderRight: "1px solid white", backgroundColor: "#ab7f21", height: "120px" }}>
                                                    <Card.Body>
                                                        <Card.Title><FaDollarSign size={40} color='white' /></Card.Title>
                                                        <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                            Platinum Elite II Invisalign Provider
                                                        </Card.Text>
                                                    </Card.Body>

                                                </Card>

                                                <Card style={{ border: "none", borderRight: "1px solid white", backgroundColor: "#ab7f21", height: "120px" }}>
                                                    <Card.Body>
                                                        <Card.Title><FaUserMd size={40} color='white' /></Card.Title>
                                                        <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                            Highly Experienced Medical Professionals
                                                        </Card.Text>
                                                    </Card.Body>

                                                </Card>
                                                <Card style={{ border: "none", backgroundColor: "#ab7f21", height: "120px" }}>
                                                    <Card.Body>
                                                        <Card.Title><FaSmileWink size={40} color='white' /></Card.Title>
                                                        <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                            2K Happy Customers
                                                        </Card.Text>
                                                    </Card.Body>

                                                </Card>
                                            </CardGroup>


                                        </>


                                    )}

                                    {service == 'pediatric-dentistry' && (
                                        <CardGroup style={{ marginTop: "50px" }}>
                                            <Card style={{ border: "none", borderRight: "1px solid white", backgroundColor: "#ab7f21", height: "120px" }}>
                                                <Card.Body>
                                                    <Card.Title><TbMoodKid size={40} color='white' /></Card.Title>
                                                    <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                        Child-Friendly Environment
                                                    </Card.Text>
                                                </Card.Body>

                                            </Card>

                                            <Card style={{ border: "none", borderRight: "1px solid white", backgroundColor: "#ab7f21", height: "120px" }}>
                                                <Card.Body>
                                                    <Card.Title><FaUserMd size={40} color='white' /></Card.Title>
                                                    <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                        Experienced and specialized Team
                                                    </Card.Text>
                                                </Card.Body>

                                            </Card>
                                            <Card style={{ border: "none", backgroundColor: "#ab7f21", height: "120px" }}>
                                                <Card.Body>
                                                    <Card.Title><FaSmileWink size={40} color='white' /></Card.Title>
                                                    <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                        Comprehensive and Preventive Care
                                                    </Card.Text>
                                                </Card.Body>

                                            </Card>
                                        </CardGroup>


                                    )}

                                    {service == 'morpheus8' && (
                                        <CardGroup style={{ marginTop: "50px" }}>
                                            <Card style={{ border: "none", borderRight: "1px solid white", backgroundColor: "#ab7f21", height: "120px" }}>
                                                <Card.Body>
                                                    <Card.Title><MdDiscount size={40} color='white' /></Card.Title>
                                                    <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                        50% Off On Sessions
                                                    </Card.Text>
                                                </Card.Body>

                                            </Card>

                                            <Card style={{ border: "none", borderRight: "1px solid white", backgroundColor: "#ab7f21", height: "120px" }}>
                                                <Card.Body>
                                                    <Card.Title><FaUserMd size={40} color='white' /></Card.Title>
                                                    <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                        Highly Experienced Medical Professionals
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                            <Card style={{ border: "none", backgroundColor: "#ab7f21", height: "120px" }}>
                                                <Card.Body>
                                                    <Card.Title><LiaMoneyBillWaveAltSolid size={40} color='white' /></Card.Title>
                                                    <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                        Flexible Payment Plans

                                                    </Card.Text>
                                                </Card.Body>

                                            </Card>
                                        </CardGroup>


                                    )}

                                    {service == 'gastric-balloon-and-smart-capsule' && (
                                        <CardGroup style={{ marginTop: "50px" }}>
                                            <Card style={{ border: "none", borderRight: "1px solid white", backgroundColor: "#ab7f21", height: "120px" }}>
                                                <Card.Body>
                                                    <Card.Title><FaDollarSign size={40} color='white' /></Card.Title>
                                                    <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                        Affordable Prices & Flexible payment plan
                                                    </Card.Text>
                                                </Card.Body>

                                            </Card>

                                            <Card style={{ border: "none", borderRight: "1px solid white", backgroundColor: "#ab7f21", height: "120px" }}>
                                                <Card.Body>
                                                    <Card.Title><FaUserMd size={40} color='white' /></Card.Title>
                                                    <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                        Highly Experienced Medical Professionals
                                                    </Card.Text>
                                                </Card.Body>

                                            </Card>
                                            <Card style={{ border: "none", backgroundColor: "#ab7f21", height: "120px" }}>
                                                <Card.Body>
                                                    <Card.Title><FaSmileWink size={40} color='white' /></Card.Title>
                                                    <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                        1K Happy Customer
                                                    </Card.Text>
                                                </Card.Body>

                                            </Card>
                                        </CardGroup>


                                    )}

                                    {service === 'laser-hair-removal' && (
                                        <CardGroup style={{ marginTop: "50px" }} className="justify-content-md-center">
                                            <Row >
                                                <Col xs={6} md={3} className="mb-1">
                                                    <Card className='small-card' style={{ border: "none", borderRight: "1px solid white", backgroundColor: "#ab7f21", height: "150px" }}>
                                                        <Card.Body>
                                                            <Card.Title className="small-icon" style={{ fontSize: "30px" }}><MdOutlineMoneyOff color='white' /></Card.Title>
                                                            <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                                Free Consultation
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col xs={6} md={3} className="mb-2">
                                                    <Card style={{ border: "none", borderRight: "1px solid white", backgroundColor: "#ab7f21", height: "150px" }}>
                                                        <Card.Body>
                                                            <Card.Title className="small-icon" style={{ fontSize: "30px" }}><FaUserMd color='white' /></Card.Title>
                                                            <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                                FDA-Approved
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col xs={6} md={3} className="mb-2">
                                                    <Card style={{ border: "none", borderRight: "1px solid white", backgroundColor: "#ab7f21", height: "150px" }}>
                                                        <Card.Body>
                                                            <Card.Title className="small-icon" style={{ fontSize: "30px" }}><PiGenderIntersexBold color='white' /></Card.Title>
                                                            <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                                Men and Women
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                {/* <Col xs={6} md={3} className="mb-2">
                                                    <Card style={{ border: "none", backgroundColor: "#ab7f21", height: "150px", borderRight: "1px solid white" }}>
                                                        <Card.Body>
                                                            <Card.Title><CiCircleCheck size={40} color='white' /></Card.Title>
                                                            <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                                Certified Technicians
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col> */}
                                                <Col xs={6} md={3} className="mb-2">
                                                    <Card style={{ border: "none", backgroundColor: "#ab7f21", height: "150px" }}>
                                                        <Card.Body>
                                                            <Card.Title className="small-icon" style={{ fontSize: "30px" }}><FaHandHoldingHeart color='white' /></Card.Title>
                                                            <Card.Text style={{ fontSize: "18px", color: 'white' }}>
                                                                Suitable For All Skin Types
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </CardGroup>
                                    )}



                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" >
                        {service === 'liposuction' ? (
                            <>
                                <div className="col-lg-5 py-4">
                                    <div className="intro_image">
                                        <img src={liposuction} alt="" style={{ height: "100%" }} />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="intro_content">
                                        <p
                                            dangerouslySetInnerHTML={{ __html: newActiveTreatment.desc }}
                                            style={{ fontSize: "17px", color: "black" }}
                                        />
                                    </div>
                                </div>
                                {/* <div class="njs-sticky-side body-append image_button_cover placement-right now-show">
                                    <a href="https://wa.me/971502008364" target="blank_" rel="noopener noreferrer">Check out our cases<FaWhatsapp size={30} style={{ marginLeft: "10px", fontWeight: "50px" }} /></a>
                                </div> */}
                            </>
                        ) :
                            service === 'pediatric-dentistry' ? (
                                <>
                                    <div className="col-lg-5 py-5">
                                        <div className="intro_image">
                                            <img src={pediatricHero} alt="" style={{ height: "100%" }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="intro_content">
                                            <p
                                                dangerouslySetInnerHTML={{ __html: newActiveTreatment.desc }}
                                                style={{ fontSize: "17px", color: "black" }}
                                            />
                                        </div>
                                    </div>
                                    {/* <div class="njs-sticky-side body-append image_button_cover placement-right now-show">
                                        <a href="https://wa.me/971502008364" target="blank_" rel="noopener noreferrer">Check out our cases<FaWhatsapp size={30} style={{ marginLeft: "10px", fontWeight: "50px" }} /></a>
                                    </div> */}
                                </>
                            ) :
                                service === 'morpheus8' ? (
                                    <>
                                        <div className="col-lg-5 py-5">
                                            <div className="intro_image">
                                                <img src={morpheusHero} alt="" style={{ height: "100%" }} />
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="intro_content">
                                                <p
                                                    dangerouslySetInnerHTML={{ __html: newActiveTreatment.desc }}
                                                    style={{ fontSize: "17px", color: "black" }}
                                                />
                                            </div>
                                        </div>
                                        {/* <div class="njs-sticky-side body-append image_button_cover placement-right now-show">
                                            <a href="https://wa.me/971502008364" target="blank_" rel="noopener noreferrer">Check out our cases<FaWhatsapp size={30} style={{ marginLeft: "10px", fontWeight: "50px" }} /></a>
                                        </div> */}
                                    </>
                                ) :
                                    service === 'gastric-balloon-and-smart-capsule' ? (
                                        <>
                                            {/* <div className="col-lg-5 py-5">
                                                <div className="intro_image">
                                                    <img src={morpheusHero} alt="" style={{ height: "100%" }} />
                                                </div>
                                            </div> */}
                                            <div className="col-lg-12">
                                                <div className="intro_content">
                                                    <p
                                                        dangerouslySetInnerHTML={{ __html: newActiveTreatment.desc }}
                                                        style={{ fontSize: "17px", color: "black" }}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div class="njs-sticky-side body-append image_button_cover placement-right now-show">
                                                <a href="https://wa.me/971502008364" target="blank_" rel="noopener noreferrer">Check out our cases<FaWhatsapp size={30} style={{ marginLeft: "10px", fontWeight: "50px" }} /></a>
                                            </div> */}
                                        </>
                                    ) :
                                        service === 'rhinoplasty' ? (
                                            <>
                                                {/* <div className="col-lg-5 py-5">
                                                <div className="intro_image">
                                                    <img src={morpheusHero} alt="" style={{ height: "100%" }} />
                                                </div>
                                            </div> */}
                                                <div className="col-lg-12">
                                                    <div className="intro_content">
                                                        <p
                                                            dangerouslySetInnerHTML={{ __html: newActiveTreatment.desc }}
                                                            style={{ fontSize: "17px", color: "black" }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div class="njs-sticky-side body-append image_button_cover placement-right now-show">
                                                    <a href="https://wa.me/971502008364" target="blank_" rel="noopener noreferrer">Check out our cases<FaWhatsapp size={30} style={{ marginLeft: "10px", fontWeight: "50px" }} /></a>
                                                </div> */}
                                            </>
                                        )
                                            :
                                            service === 'invisalign' ? (
                                                <>
                                                    <div className="col-lg-12">
                                                        <div className="intro_content">
                                                            <p style={{ fontSize: "17px", color: "black" }}>
                                                                <a href="https://www.invisalign.ae/" style={{ color: "blue", textDecoration: "underline", borderBottom: "none" }}>Invisalign® </a>
                                                                is an advanced clear aligner system, preferred by teens and adults who want to straighten their teeth discreetly and efficiently. The treatment involves wearing custom-made, removable aligners that gradually move your teeth into their ideal position. Unlike traditional braces, Invisalign offers a more comfortable and aesthetically pleasing solution, with the added benefit of typically delivering faster results.
                                                            </p>

                                                        </div>
                                                    </div>

                                                    <div style={{ backgroundColor: "#AB7F21", width: "100%", textAlign: "center", padding: "20px" }}>
                                                        <h4 style={{ fontFamily: 'Fredoka", sans-serif' }}>Step-by-step treatment process</h4>
                                                        <h5 style={{ color: 'white', fontFamily: 'Fredoka", sans-serif' }}>Ask your Orthodontist about the possibility of INVISALIGN splints for you</h5>
                                                        <div className="container">
                                                            <Row style={{ marginTop: "25px" }}>
                                                                <Col lg={6} md={6} sm={12} style={{ display: 'flex', alignItems: 'center', marginBottom: '25px' }}>
                                                                    <h1 style={{ marginRight: '15px', color: 'white', fontWeight: "bold", fontFamily: 'Fredoka", sans-serif' }}>1</h1>  {/* Change number to black */}
                                                                    <h5 style={{ color: 'white', textAlign: 'left', fontFamily: 'Fredoka", sans-serif' }}>
                                                                        Meet Dr. Anil Ramu, our Platinum Elite II Invisalign provider with over 18 years of experience. Using our cutting-edge iTero Element Scanner, we'll capture a precise 3D scan of your teeth. This allows us to create a personalized treatment plan and provide you with a visual representation of your future smile.
                                                                    </h5>
                                                                </Col>

                                                                <Col lg={6} md={6} sm={12} style={{ display: 'flex', alignItems: 'center', marginBottom: '25px' }}>
                                                                    <h1 style={{ marginRight: '15px', color: 'white', fontWeight: "bold", fontFamily: 'Fredoka", sans-serif' }}>2</h1>  {/* Change number to black */}
                                                                    <h5 style={{ color: 'white', textAlign: 'left', fontFamily: 'Fredoka", sans-serif' }}>
                                                                        Let the transformation begin! Dr. Anil will tailor a plan to your needs, addressing any concerns you may have and guiding you through the process. We'll start your Invisalign journey meticulously, ensuring each aligner fits seamlessly. Visit us every 6 to 8 weeks for progress checks and to receive your next set of aligners.
                                                                    </h5>
                                                                </Col>

                                                                <Col lg={6} md={6} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <h1 style={{ marginRight: '15px', color: 'white', fontWeight: "bold", fontFamily: 'Fredoka", sans-serif' }}>3</h1>  {/* Change number to black */}
                                                                    <h5 style={{ color: 'white', textAlign: 'left', fontFamily: 'Fredoka", sans-serif' }}>
                                                                        Maintain your stunning new smile with our premium retainers.
                                                                    </h5>
                                                                </Col>

                                                                <Col lg={6} md={6} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <h1 style={{ marginRight: '15px', color: 'black' }}></h1>  {/* Ensures empty number space remains black */}
                                                                    <h5 style={{ color: 'white' }}></h5>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <img src={inviBanner} style={{ width: "100%", marginTop: "30px" }} />
                                                    </div>



                                                    <div className='container' style={{ marginTop: "50px" }}>

                                                        <Row>
                                                            <Col lg={6} style={{ marginBottom: "15px" }}>
                                                                <img style={{ objectFit: "fill", width: "100%" }} src={anil} />
                                                            </Col>
                                                            <Col lg={6} style={{ paddingLeft: '10px' }}>
                                                                <h4>Why choose Orthodontic treatment with Dr. Anil Ramu?</h4>

                                                                <h6>
                                                                    <a href="https://dranilramu.com/" style={{ color: "blue", textDecoration: "underline" }}>Dr. Anil Ramu</a> is an exceptional choice due to his extensive 18-year career specializing in fixed and invisible braces, dentofacial orthopedics, and jaw corrections. As a leading Invisalign Platinum Elite II provider, he has successfully treated over 6,000 orthodontic patients.
                                                                </h6>
                                                                <br />
                                                                <h6>
                                                                    His educational background is equally impressive, with a bachelor’s degree in General Dentistry (2005), a master’s in Orthodontics and Dentofacial Orthopedics (2008), and a further master’s degree in Neuromuscular Orthodontics from Jaume I University in Spain (2020). Dr. Anil Ramu's expertise and commitment to advancing his knowledge make him a trusted professional in his field.
                                                                </h6>
                                                            </Col>


                                                        </Row>
                                                    </div>

                                                    <div className='container' style={{ marginTop: "50px" }}>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <img style={{ objectFit: "fill", width: "100%" }} src={invi3} />
                                                            </Col>
                                                            <Col lg={6} >
                                                                <img style={{ objectFit: "fill", width: "100%" }} src={invi4} />
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    <div className='container' style={{ marginTop: "50px" }}>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <h4>Do you offer payment plans?</h4>
                                                                <h5>
                                                                    Yes, we do! To make your Invisalign treatment more affordable, we offer payment plans that can be discussed during your consultation.
                                                                </h5>
                                                            </Col>
                                                        </Row>
                                                    </div>


                                                </>
                                            )
                                                :
                                                (
                                                    <div className="col-lg-12">
                                                        <div className="intro_content">

                                                            <p
                                                                dangerouslySetInnerHTML={{ __html: newActiveTreatment.desc }}
                                                                style={{ fontSize: "17px", color: "black" }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                    </div>
                </div>
            </div>

            {service == 'liposuction' && (
                <>
                    <div className="col-lg-12" >
                        <h4 style={{ marginBottom: "-20px", textAlign: "center", fontSize: "24px", fontWeight: "600", color: "#ab7f21" }}>Why Choose Us</h4>
                    </div>
                    <div style={{ backgroundColor: "#FAF9F6	" }}>

                        <CardGroup style={{ marginTop: "50px", textAlign: "left" }}>
                            <Card style={{ border: "none", height: "90px", backgroundColor: "#FAF9F6	" }}>
                                <Card.Body>
                                    <Card.Text style={{ fontSize: "18px", color: 'black' }}>
                                        <FcApproval size={30} color='black' style={{ marginRight: "10px" }} />Thousands of Satisfied Customers
                                    </Card.Text>
                                </Card.Body>

                            </Card>
                            <Card style={{ border: "none", height: "90px", backgroundColor: "#FAF9F6	" }}>
                                <Card.Body>
                                    <Card.Text style={{ fontSize: "18px", color: 'black' }}>
                                        <FcApproval size={30} color='black' style={{ marginRight: "10px" }} />Expert Weight Loss Treatments
                                    </Card.Text>
                                </Card.Body>

                            </Card>
                            <Card style={{ border: "none", height: "90px", backgroundColor: "#FAF9F6	" }}>
                                <Card.Body>
                                    <Card.Text style={{ display: 'flex', alignItems: 'center', fontSize: '18px', color: 'black' }}>
                                        <FcApproval style={{ minWidth: '30px', minHeight: '30px', marginRight: '10px' }} />
                                        <span style={{ flex: 1 }}>Top-Tier Bariatric Surgeries for Effective Weight Loss</span>
                                    </Card.Text>
                                </Card.Body>

                            </Card>



                        </CardGroup>
                        <CardGroup style={{ marginTop: "50px", textAlign: "left", }}>
                            <Card style={{ border: "none", height: "90px", backgroundColor: "#FAF9F6	" }}>
                                <Card.Body>
                                    <Card.Text style={{ fontSize: "18px", color: 'black' }}>
                                        <FaMapMarkedAlt size={35} color='#AB7F21' style={{ marginRight: "10px" }} />6 Branches across UAE
                                    </Card.Text>
                                </Card.Body>

                            </Card>

                            <Card style={{ border: "none", height: "90px", backgroundColor: "#FAF9F6	" }}>
                                <Card.Body>
                                    <Card.Text style={{ fontSize: "18px", color: 'black' }}>
                                        <FaAward size={35} style={{ marginRight: "10px" }} color='#AB7F21' />10+ years of experience
                                    </Card.Text>
                                </Card.Body>

                            </Card>
                            <Card style={{ border: "none", height: "90px", backgroundColor: "#FAF9F6	" }}>
                                <Card.Body>
                                    <Card.Text style={{ fontSize: "18px", color: 'black' }}>
                                        <FaCoins size={35} style={{ marginRight: "10px" }} color='#AB7F21' />Affordable Prices for All Surgeries
                                    </Card.Text>
                                </Card.Body>

                            </Card>
                            <Card style={{ border: "none", height: "90px", backgroundColor: "#FAF9F6	" }}>
                                <Card.Body>
                                    <Card.Text style={{ fontSize: "18px", color: 'black' }}>
                                        <FaClinicMedical size={35} style={{ marginRight: "10px" }} color='#AB7F21' />20 Medical specialties
                                    </Card.Text>
                                </Card.Body>

                            </Card>
                        </CardGroup>
                    </div>

                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4">
                                <div className='row'>
                                    <figure className="snip1519">
                                        <figcaption>
                                            <GiStomach size={100} color='#AB7F21' />
                                            <h3 >Lipo 360</h3>
                                        </figcaption>
                                    </figure>
                                </div>
                                {/* <h4 className="title black-text" >Lipo 360</h4> */}
                                <p className="text black-text" style={{ fontWeight: "bolder", lineHeight: "2.4" }}>Unlock Your Dream Body with Lipo 360</p>
                                <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Ready to achieve your ideal body contour? Lipo 360 is a revolutionary procedure that sculpts and reshapes your body from every angle. Unlike traditional liposuction, it offers comprehensive fat removal for a balanced appearance.</h6>
                                <h4 className="subtitle black-text" >Benefits:</h4>
                                <div className="text black-text" style={{ marginBottom: '20px' }}>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>All-Around Contouring: Ensures overall improvement and balance.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Enhanced Curves: Highlights and enhances natural curves.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Minimally Invasive: Small incisions mean minimal scarring and quicker recovery.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Customized Results: Tailored to your unique body shape and goals.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Lipo 360 is ideal for individuals who are close to their ideal weight but struggle with stubborn fat deposits in the midsection. A consultation with our experienced surgeons will help determine if Lipo 360 is the best option for achieving your desired results.</h6>

                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className='row'>
                                    <figure className="snip1519">
                                        <figcaption>
                                            <MdFace4 size={100} color='#AB7F21' />
                                            <h3>Double Chin Liposuction</h3>
                                        </figcaption>
                                    </figure>
                                </div>
                                {/* <h4 className="title black-text">Double Chin Liposuction</h4> */}
                                <p className="text black-text" style={{ fontWeight: "bolder", lineHeight: "2.4" }}>Sculpt Your Chin and Neck</p>
                                <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Struggling with stubborn chin fat? Our double chin liposuction in UAE offers an innovative solution to redefine your chin and neck contours. Our advanced techniques precisely target and remove excess fat, giving you a sleeker, more defined profile.</h6>
                                <h4 className="subtitle black-text">Benefits:</h4>
                                <div className="text black-text" style={{ marginBottom: '20px' }}>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Enhance jawline and neck appearance.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Boost confidence and self-esteem.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Achieve permanent results.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Reduce neck wrinkles and tighten lower neck.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Improve facial contour for a youthful look.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Strengthen a weak or short chin.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Our expert team is dedicated to delivering exceptional results and guiding you towards your aesthetic goals with care and expertise.</h6>

                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className='row'>
                                    <figure className="snip1519">
                                        {/* <img src="path/to/image3.jpg" alt="Gastric Sleeve" /> */}
                                        <figcaption>
                                            <GiStomach size={100} color='#AB7F21' />
                                            {/* <p>Weight Loss</p> */}
                                            <h3>Tummy Tuck</h3>
                                        </figcaption>
                                    </figure>
                                </div>
                                <p className="text black-text" style={{ fontWeight: "bolder", lineHeight: "2.4" }}>Achieve a Flatter, Firmer Midsection</p>
                                <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>A tummy tuck, or abdominoplasty, is a transformative procedure designed to remove excess skin and fat from the abdominal area while tightening the underlying muscles. This surgery is ideal for individuals who have experienced significant weight loss, pregnancy, or aging, leading to loose or sagging skin around the stomach.</h6>
                                <h4 className="subtitle black-text" >Benefits:</h4>
                                <div className="text black-text" style={{ marginBottom: '20px' }}>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Flatter Stomach: Removes excess skin and fat for a smoother, firmer abdomen.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Tightened Muscles: Repairs and tightens abdominal muscles, enhancing core strength and posture.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Enhanced Confidence: Achieve a more toned appearance, boosting your self-esteem and body image.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Permanent Results:Enjoy long-lasting results with proper diet and exercise.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Regain your confidence and enjoy a more contoured abdomen with our professional tummy tuck services.</h6>

                                </div>

                            </div>
                        </div>
                    </div>


                </>
            )}

            {service == 'laser-hair-removal' && (
                <>

                    <div className='container' style={{ marginBottom: "20px", marginTop: "-60px" }}>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <Card style={{ color: "white", fontWeight: "bold", fontSize: "16px", borderColor: "#AB7F21", backgroundColor: "#AB7F21" }}>
                                    <Card.Body>
                                        No more waxing! Repeatedly shaving or waxing a body part can cause skin pigmentation and uneven skin tone. Laser hair removal technology reduces the risk of this happening
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12" style={{ marginBottom: "30px" }}>
                        <h4 style={{ marginBottom: "-20px", textAlign: "center", fontSize: "24px", fontWeight: "600", color: "#ab7f21" }}>Benefits</h4>
                    </div>
                    <div className="container custom-grid-container" style={{ marginBottom: "30px" }}>
                        <div className="row justify-content-center">
                            <div className="col-6 col-lg-3">
                                <figure className="snip1519">
                                    <figcaption>
                                        <CiTimer size={100} color="#AB7F21" />
                                        <h3>Convenient and Time-Saving</h3>
                                    </figcaption>
                                </figure>
                            </div>

                            <div className="col-6 col-lg-3">
                                <figure className="snip1519">
                                    <figcaption>
                                        <TbRazor size={100} color="#AB7F21" />
                                        <h3>No More Ingrown Hair</h3>
                                    </figcaption>
                                </figure>
                            </div>

                            <div className="col-6 col-lg-3">
                                <figure className="snip1519">
                                    <figcaption>
                                        <CiPercent size={100} color="#AB7F21" />
                                        <h3>Up to 90% Hair Reduction</h3>
                                    </figcaption>
                                </figure>
                            </div>

                            <div className="col-6 col-lg-3">
                                <figure className="snip1519">
                                    <figcaption>
                                        <GiSandsOfTime size={100} color="#AB7F21" />
                                        <h3>Long Lasting Result</h3>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-12" style={{ marginBottom: "30px" }}>
                        <h4 style={{ marginBottom: "-20px", textAlign: "center", fontSize: "24px", fontWeight: "600", color: "#ab7f21" }}>Our Machines</h4>
                    </div>
                    <div className="container">
                        <div className='row'>
                            <div className="col-lg-6">
                                <img src={duoPro} style={{ width: "100%" }} />
                            </div>
                            <div className="col-lg-6">
                                <h4 style={{ fontWeight: "bolder", lineHeight: "2.4" }}>Duo Pro & Star Duo</h4>
                                {/* <h5 className="subtitle black-text" >Benefits:</h5> */}
                                <div className="text black-text" style={{ marginBottom: '20px' }}>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4", fontWeight: "bold" }}><FaCheckCircle size={25} color='#AB7F21' style={{ marginRight: "5px" }} />Hair Reduction</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4", fontWeight: "bold" }}><FaCheckCircle size={25} color='#AB7F21' style={{ marginRight: "5px" }} />Leg Veins</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4", fontWeight: "bold" }}><FaCheckCircle size={25} color='#AB7F21' style={{ marginRight: "5px" }} />Brown Spots</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4", fontWeight: "bold" }}><FaCheckCircle size={25} color='#AB7F21' style={{ marginRight: "5px" }} />Facial and Spider Veins</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4", fontWeight: "bold" }}><FaCheckCircle size={25} color='#AB7F21' style={{ marginRight: "5px" }} />Beard Bumps Pseudifilllicultis Barbae</h6>

                                </div>

                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-lg-6">
                                <h4 style={{ fontWeight: "bolder", color: "#ab7f21" }}>Benefits of Laser Hair Removal</h4>
                                {/* <h5 className="subtitle black-text" >Benefits:</h5> */}
                                <div className="text black-text" style={{ marginBottom: '20px' }}>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4", fontWeight: "bold" }}><FaCheckCircle size={25} color='#AB7F21' style={{ marginRight: "5px" }} />Long lasting results</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4", fontWeight: "bold" }}><FaCheckCircle size={25} color='#AB7F21' style={{ marginRight: "5px" }} />Precision & Speed</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4", fontWeight: "bold" }}><FaCheckCircle size={25} color='#AB7F21' style={{ marginRight: "5px" }} />Reduced ingrown hairs</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4", fontWeight: "bold" }}><FaCheckCircle size={25} color='#AB7F21' style={{ marginRight: "5px" }} />Safe & Less painful</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4", fontWeight: "bold" }}><FaCheckCircle size={25} color='#AB7F21' style={{ marginRight: "5px" }} />Silky smooth skin</h6>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <h4 style={{ fontWeight: "bolder", color: "#ab7f21" }}>Areas Treated With Laser Hair Removal At Drypskin UAE</h4>
                                {/* <h5 className="subtitle black-text" >Benefits:</h5> */}
                                <div className="text black-text" style={{ marginBottom: '20px' }}>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4", fontWeight: "bold" }}><FaCheckCircle size={25} color='#AB7F21' style={{ marginRight: "5px" }} />Face</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4", fontWeight: "bold" }}><FaCheckCircle size={25} color='#AB7F21' style={{ marginRight: "5px" }} />Body</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4", fontWeight: "bold" }}><FaCheckCircle size={25} color='#AB7F21' style={{ marginRight: "5px" }} />Arms</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4", fontWeight: "bold" }}><FaCheckCircle size={25} color='#AB7F21' style={{ marginRight: "5px" }} />Legs</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4", fontWeight: "bold" }}><FaCheckCircle size={25} color='#AB7F21' style={{ marginRight: "5px" }} />Bikini</h6>
                                </div>
                            </div>
                        </div>

                    </div>


                </>
            )}

            {service == 'morpheus8' && (
                <>


                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4">
                                <div className='row'>
                                    <figure className="snip1519">
                                        <figcaption>
                                            <PiNeedleLight size={100} color='#AB7F21' />
                                            <h3 >Vivace Microneedling
                                            </h3>
                                        </figcaption>
                                    </figure>
                                </div>
                                {/* <h4 className="title black-text" >Lipo 360</h4> */}
                                <p className="text black-text" style={{ fontWeight: "bolder", lineHeight: "2.4", fontSize: "15px" }}>Rejuvenate Your Skin with Vivace Microneedling</p>
                                <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Ready to reveal a smoother, more youthful complexion? Vivace Microneedling is an advanced procedure that revitalizes your skin by combining microneedling with radiofrequency energy. Unlike traditional microneedling, it enhances collagen production and tightens skin for comprehensive rejuvenation. </h6>
                                <h5 className="subtitle black-text" >Benefits:</h5>
                                <div className="text black-text" style={{ marginBottom: '20px' }}>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}><b>Stimulated Collagen Production:</b> Boosts natural collagen for firmer, smoother skin.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}><b>Improved Skin Texture:</b>Reduces fine lines, wrinkles, and acne scars.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}><b>Minimally Invasive:</b> Tiny needles and RF energy mean minimal discomfort and downtime.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}><b>Customized Treatment:</b> Tailored to your unique body shape and goals.</h6>
                                </div>
                                <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Vivace Microneedling is perfect for people seeking to address signs of aging, acne scars, and uneven skin tone. Our expert team is dedicated to delivering exceptional results and guiding you towards your aesthetic goals with expertise.</h6>

                            </div>
                            <div className="col-lg-4">
                                <div className='row'>
                                    <figure className="snip1519">
                                        <figcaption>
                                            <LuSparkles size={100} color='#AB7F21' />
                                            <h3>Zaffiro</h3>
                                        </figcaption>
                                    </figure>
                                </div>
                                {/* <h4 className="title black-text">Double Chin Liposuction</h4> */}
                                <p className="text black-text" style={{ fontWeight: "bolder", lineHeight: "2.4", fontSize: "15px" }}>Achieve Radiant Skin with Zaffiro</p>
                                <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>
                                    Ready to enhance your skin’s natural glow? Zaffiro is an innovative treatment that rejuvenates and tightens your skin using advanced water peeling and infrared technology. Unlike traditional facials, Zaffiro offers comprehensive skin revitalization for a radiant, youthful appearance.
                                </h6>
                                <h5 className="subtitle black-text">Benefits:</h5>
                                <div className="text black-text" style={{ marginBottom: '20px' }}>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}><b>Deep Hydration:</b> Water peeling deeply hydrates and refreshes the skin.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}><b>Tighter Skin:</b> Infrared technology stimulates collagen production for firmer skin.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}><b>Non-Invasive:</b> No needles or surgery, ensuring a comfortable experience with no downtime.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}><b>Customized Treatment:</b> Tailored to address your specific skin concerns and goals.</h6>

                                </div>
                                <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Zaffiro is the best option for individuals looking to combat signs of aging, improve skin texture, and achieve a healthy glow. A consultation with our experienced practitioners will help determine if Zaffiro is the best option for achieving your desired skin rejuvenation.</h6>

                            </div>
                            <div className="col-lg-4">
                                <div className='row'>
                                    <figure className="snip1519">
                                        {/* <img src="path/to/image3.jpg" alt="Gastric Sleeve" /> */}
                                        <figcaption>
                                            <MdOutlineFaceRetouchingNatural size={100} color='#AB7F21' />
                                            {/* <p>Weight Loss</p> */}
                                            <h3>
                                                Aerolase
                                            </h3>
                                        </figcaption>
                                    </figure>
                                </div>
                                <p className="text black-text" style={{ fontWeight: "bolder", lineHeight: "2.4", fontSize: "15px" }}>
                                    Reveal Your Best Skin with Aerolase
                                </p>
                                <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Ready to achieve a flawless complexion? Aerolase is an advanced laser treatment that addresses a variety of skin concerns including acne, age spots, spider veins and fine lines and wrinkles. Unlike traditional laser treatments, it offers a gentle yet effective approach for all skin types and tones.</h6>
                                <h5 className="subtitle black-text" >Benefits:</h5>
                                <div className="text black-text" style={{ marginBottom: '20px' }}>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}><b>Versatile :</b>Targets acne, rosacea, hyperpigmentation, and more.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}><b>Gentle on Skin:</b> Minimizes discomfort and eliminates the need for topical anesthetics.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}><b>Quick Sessions:</b> Treatment sessions are short with minimal downtime.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}><b>Customized Care:</b> Tailored to your specific skin needs and goals.</h6>

                                </div>
                                <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Aerolase is ideal for individuals seeking effective solutions for persistent skin issues without extensive downtime. A consultation with our skilled doctor will help to determine how Aerolase can help you in achieving your desired results</h6>

                            </div>
                        </div>
                    </div>


                </>
            )}

            {service == 'pediatric-dentistry' && (
                <>
                    <div className="col-lg-12" >
                        <h4 style={{ textAlign: "center", fontSize: "24px", fontWeight: "600", color: "#ab7f21" }}>Our Services</h4>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4">
                                <div className='row'>
                                    <figure className="snip1519">
                                        <figcaption>
                                            <TbCheckupList size={100} color='#AB7F21' />
                                            <h3 >Routine Check-Ups and Cleanings</h3>
                                        </figcaption>
                                    </figure>
                                </div>
                                {/* <h4 className="title black-text" >Lipo 360</h4> */}
                                {/* <p className="text black-text" style={{ fontWeight: "bolder", lineHeight: "2.4" }}>Unlock Your Dream Body with Lipo 360</p> */}
                                <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Regular dental visits are essential for maintaining your child’s oral health. We perform thorough exams and cleanings to keep their teeth and gums healthy.</h6>
                                {/* <h5 className="subtitle black-text" >Benefits:</h5>
                                <div className="text black-text" style={{ marginBottom: '20px' }}>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>All-Around Contouring: Ensures overall improvement and balance.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Enhanced Curves: Highlights and enhances natural curves.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Minimally Invasive: Small incisions mean minimal scarring and quicker recovery.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Customized Results: Tailored to your unique body shape and goals.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Lipo 360 is ideal for individuals who are close to their ideal weight but struggle with stubborn fat deposits in the midsection. A consultation with our experienced surgeons will help determine if Lipo 360 is the best option for achieving your desired results.</h6>

                                </div> */}

                            </div>
                            <div className="col-lg-4">
                                <div className='row'>
                                    <figure className="snip1519">
                                        <figcaption>
                                            <FaShieldAlt size={100} color='#AB7F21' />
                                            <h3>Fluoride Treatments and Sealants</h3>
                                        </figcaption>
                                    </figure>
                                </div>
                                {/* <h4 className="title black-text">Double Chin Liposuction</h4> */}
                                {/* <p className="text black-text" style={{ fontWeight: "bolder", lineHeight: "2.4" }}>Sculpt Your Chin and Neck</p> */}
                                <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Preventive treatments like fluoride applications and dental sealants help protect your child’s teeth from decay and cavities</h6>
                                {/* <h5 className="subtitle black-text">Benefits:</h5>
                                <div className="text black-text" style={{ marginBottom: '20px' }}>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Enhance jawline and neck appearance.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Boost confidence and self-esteem.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Achieve permanent results.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Reduce neck wrinkles and tighten lower neck.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Improve facial contour for a youthful look.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Strengthen a weak or short chin.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Our expert team is dedicated to delivering exceptional results and guiding you towards your aesthetic goals with care and expertise.</h6>

                                </div> */}

                            </div>
                            <div className="col-lg-4">
                                <div className='row'>
                                    <figure className="snip1519">
                                        {/* <img src="path/to/image3.jpg" alt="Gastric Sleeve" /> */}
                                        <figcaption>
                                            <PiTooth size={100} color='#AB7F21' />
                                            {/* <p>Weight Loss</p> */}
                                            <h3>Cavity Treatments</h3>
                                        </figcaption>
                                    </figure>
                                </div>
                                {/* <p className="text black-text" style={{ fontWeight: "bolder", lineHeight: "2.4" }}>Achieve a Flatter, Firmer Midsection</p> */}
                                <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>If your child develops a cavity, our gentle and effective treatments will restore their teeth and prevent further issues.</h6>
                                {/* <h5 className="subtitle black-text" >Benefits:</h5>
                                <div className="text black-text" style={{ marginBottom: '20px' }}>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Flatter Stomach: Removes excess skin and fat for a smoother, firmer abdomen.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Tightened Muscles: Repairs and tightens abdominal muscles, enhancing core strength and posture.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Enhanced Confidence: Achieve a more toned appearance, boosting your self-esteem and body image.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Permanent Results:Enjoy long-lasting results with proper diet and exercise.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Regain your confidence and enjoy a more contoured abdomen with our professional tummy tuck services.</h6>

                                </div> */}

                            </div>
                            <div className="col-lg-4">
                                <div className='row'>
                                    <figure className="snip1519">
                                        {/* <img src="path/to/image3.jpg" alt="Gastric Sleeve" /> */}
                                        <figcaption>
                                            <LiaTeethOpenSolid size={100} color='#AB7F21' />
                                            {/* <p>Weight Loss</p> */}
                                            <h3>Orthodontic Evaluations</h3>
                                        </figcaption>
                                    </figure>
                                </div>
                                {/* <p className="text black-text" style={{ fontWeight: "bolder", lineHeight: "2.4" }}>Achieve a Flatter, Firmer Midsection</p> */}
                                <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>We monitor the development of your child’s teeth and jaw to identify any need for orthodontic treatment early on.
                                </h6>
                                {/* <h5 className="subtitle black-text" >Benefits:</h5>
                                <div className="text black-text" style={{ marginBottom: '20px' }}>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Flatter Stomach: Removes excess skin and fat for a smoother, firmer abdomen.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Tightened Muscles: Repairs and tightens abdominal muscles, enhancing core strength and posture.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Enhanced Confidence: Achieve a more toned appearance, boosting your self-esteem and body image.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Permanent Results:Enjoy long-lasting results with proper diet and exercise.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Regain your confidence and enjoy a more contoured abdomen with our professional tummy tuck services.</h6>

                                </div> */}

                            </div>

                            <div className="col-lg-4">
                                <div className='row'>
                                    <figure className="snip1519">
                                        {/* <img src="path/to/image3.jpg" alt="Gastric Sleeve" /> */}
                                        <figcaption>
                                            <RiFirstAidKitLine size={100} color='#AB7F21' />
                                            {/* <p>Weight Loss</p> */}
                                            <h3>Emergency Care</h3>
                                        </figcaption>
                                    </figure>
                                </div>
                                {/* <p className="text black-text" style={{ fontWeight: "bolder", lineHeight: "2.4" }}>Achieve a Flatter, Firmer Midsection</p> */}
                                <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Dental emergencies can happen at any time. We offer prompt and compassionate emergency care to address any urgent dental issues your child may face.</h6>
                                {/* <h5 className="subtitle black-text" >Benefits:</h5>
                                <div className="text black-text" style={{ marginBottom: '20px' }}>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Flatter Stomach: Removes excess skin and fat for a smoother, firmer abdomen.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Tightened Muscles: Repairs and tightens abdominal muscles, enhancing core strength and posture.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Enhanced Confidence: Achieve a more toned appearance, boosting your self-esteem and body image.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Permanent Results:Enjoy long-lasting results with proper diet and exercise.</h6>
                                    <h6 style={{ fontSize: "15px", color: "black", lineHeight: "2.4" }}>Regain your confidence and enjoy a more contoured abdomen with our professional tummy tuck services.</h6>

                                </div> */}

                            </div>
                        </div>
                    </div>


                </>
            )}



            <div className="row">
                <div className="contact_form_container" style={{ backgroundColor: "#ab7f21" }}>
                    <h4 style={{ marginTop: "-100px", textAlign: "center", fontSize: "24px", fontWeight: "600", color: "white", marginBottom: "40px" }}>
                        Make an Appointment
                    </h4>
                    <form
                        action="#"
                        className="contact_form"
                        id="contact_form"
                        style={{ display: "flex", flexDirection: "column", alignItems: "center" }} // Added styles
                    >
                        <div className="d-flex flex-row align-items-start justify-content-between flex-wrap">
                            <input type="text" className="contact_input" placeholder="Your Name" required style={{ color: "white" }} />

                            <input type="tel" className="contact_input" placeholder="Your Phone" maxLength={9} minLength={9} required />
                            <select className="contact_select contact_input" required onChange={handleBranchChange}>
                                <option disabled selected>
                                    Branch
                                </option>
                                <option>Sharjah Branch</option>
                                <option>Ajman Branch</option>
                                <option>Ras Al Khaimah Branch</option>
                                <option>Marina Branch</option>
                                <option>Jumeirah Branch</option>
                                <option>Mirdif Branch</option>
                            </select>
                            <select className="contact_select contact_input" required onChange={handleSpecialityChange}>
                                <option>Speciality</option>
                                {specialities.map((speciality, index) => (
                                    <option key={index} value={speciality}>
                                        {speciality}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button className="button button_1 contact_button trans_200" style={{ backgroundColor: "white", color: "#ab7f21", width: "100px", marginTop: "20px" }}>
                            Submit
                        </button>
                    </form>
                </div>
            </div>

        </>
    )
}
export default Treatments