import Carousel from 'react-bootstrap/Carousel';

function CarouselSlider() {
    return (
        // <div className="container">
        //     <div className="row">
        <Carousel fade style={{ marginLeft: "auto", marginRight: "auto", marginTop: "60px" }} controls={false}>
            
            <Carousel.Item interval={1000}>
                <img
                    
                    src="images/banner2.jpg"
                    alt="Image Two"
                    style={{objectFit: "cover", width: '100%'}}

                />
          
            </Carousel.Item>
            <Carousel.Item  interval={1000}>
                <img
                    src="images/banner1.jpg"
                    alt="Image One"
                    style={{ objectFit: "cover", width: '100%'}}

                />
           
            </Carousel.Item>
            

            <Carousel.Item interval={1000}>
                <img
                 
                    src="images/IV therapy.jpg"
                    alt="Image Two"
                    style={{objectFit: "cover", width: '100%'}}

                />
          
            </Carousel.Item>

        
           
        </Carousel>
        //     </div>
        // </div>
    )

}
export default CarouselSlider