function DocInfo({ doctor }) {
    return (
        <div className="intro" style={{ marginTop: "50px" }}>
            <div className="row">
                <div className="col-lg-3 offset-lg-1">
                    <div className="intro_image"><img src={doctor.image} alt="" /></div>
                    {/* <div className="intro_text">
                        <div className="section_title"><h4>Qualifications:</h4></div>

                        <ul style={{ listStyleType: "disc" }}>
                            {doctor.qualification.map((q, index) => (
                                <li key={index}><h6 style={{ textTransform: "capitalize" }}>{q}</h6></li>
                            ))}
                        </ul>
                    </div> */}

                </div>
                {/* Left Column */}
                <div className="col-lg-8">
                    <div className="intro_content">
                        <div className="section_title_container">
                            {/* <div className="section_subtitle">{doctor.name}</div> */}
                            <div className="section_title" style={{marginTop: "-15px"}}><h2>{doctor.name}</h2></div>
                        </div>
                        <div className="intro_text">
                            <div className="section_title"><h4 style={{ color: "#ab7f21", fontWeight: "700", fontSize: "20px" }}>Core Competencies</h4></div>
                            <ul style={{ listStyleType: "disc" }}>
                                {doctor.core.map((c, index) => {
                                    let words = c.split(' ');
                                    let lines = [];
                                    let line = '';
                                    words.forEach((word, i) => {
                                        line += word + ' ';
                                        if ((i + 1) % 7 === 0 || i === words.length - 1) {
                                            lines.push(line.trim()); // Remove trailing space
                                            line = '';
                                        }
                                    });
                                    return (
                                        <li key={index}>
                                            <h6 style={{ textTransform: "capitalize" }}>
                                                {lines.map((line, index) => (
                                                    <span key={index}>
                                                        {line}
                                                        <br />
                                                    </span>
                                                ))}
                                            </h6>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>



                        {/* Milestones */}
                        <div className="milestones">
                            <div className="row milestones_row">

                                {/* Milestone 1 */}
                                <div className="col-md-3 milestone_col">
                                    <div className="milestone">
                                        <div className="milestone_counter" data-end-value="5000" data-sign-before="+" style={{ fontSize: "20px", textAlign: "left" }}>Speciality</div>
                                        <p style={{fontSize: "1rem", color: "#404040", textAlign: "left"}}>{doctor.profile['1']}</p>
                                    </div>
                                </div>

                                {/* Milestone 2 */}
                                <div className="col-md-3 milestone_col">
                                    <div className="milestone">
                                        <div className="milestone_counter" data-end-value="352" style={{ fontSize: "20px", textAlign: "left" }}>Nationality</div>
                                        <p style={{fontSize: "1rem", color: "#404040"}}>{doctor.profile['0']}</p>
                                    </div>
                                </div>

                                {/* Milestone 3 */}
                                <div className="col-md-3 milestone_col">
                                    <div className="milestone">
                                        <div className="milestone_counter" data-end-value="718" style={{ fontSize: "20px", textAlign: "left" }}>Experience</div>
                                        <p style={{fontSize: "1rem", color: "#404040"}}>{doctor.profile['2']}</p>
                                    </div>
                                </div>

                                {/* Milestone 4 */}
                                <div className="col-md-3 milestone_col" style={{marginBottom: "35px"}}>
                                    <div className="milestone">
                                        <div className="milestone_counter" data-end-value="5" style={{ fontSize: "20px", textAlign: "left" }}>Languages</div>
                                        {doctor.lang.map((l) => (
                                            <p style={{fontSize: "1rem", color: "#404040", marginBottom: "-10px"}}>{l}</p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="intro_text" style={{ marginTop: "-50px" }}>
                            <div className="section_title" ><h4 style={{ color: "#ab7f21", fontWeight: "700",  fontSize: "20px" }}>Qualifications:</h4></div>
                            <ul style={{ listStyleType: "disc" }}>
                                {doctor.qualification.map((q, index) => {
                                    let words = q.split(' ');
                                    let lines = [];
                                    let line = '';
                                    words.forEach((word, i) => {
                                        line += word + ' ';
                                        if ((i + 1) % 7 === 0 || i === words.length - 1) {
                                            lines.push(line.trim()); // Remove trailing space
                                            line = '';
                                        }
                                    });
                                    return (
                                        <li key={index}>
                                            <h6 style={{ textTransform: "capitalize" }}>
                                                {lines.map((line, index) => (
                                                    <span key={index}>
                                                        {line}
                                                        <br />
                                                    </span>
                                                ))}
                                            </h6>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>

                    </div>
                </div>

                {/* Right Column */}

            </div>
        </div>
    )

}
export default DocInfo