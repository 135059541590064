// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section#counter-stats {
	display: flex;
	justify-content: center;
	margin-top: 100px;
}



.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  transition: background-color 0.3s;
  flex: 1 1 20%; /* Ensures that each item takes up equal space and wraps if necessary */
  background-color: #AB7F21;
  color: white;
  max-width: 150px;
}

.stats:hover {
  background-color: white;
  color: #AB7F21;
}

.milestone_counter {
  color: inherit; /* Inherit text color from .stats */
}

.milestone_text {
  color: inherit; /* Inherit text color from .stats */
}`, "",{"version":3,"sources":["webpack://./src/Components/Counter/Counter.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,uBAAuB;CACvB,iBAAiB;AAClB;;;;AAIA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,iCAAiC;EACjC,aAAa,EAAE,uEAAuE;EACtF,yBAAyB;EACzB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,cAAc,EAAE,mCAAmC;AACrD;;AAEA;EACE,cAAc,EAAE,mCAAmC;AACrD","sourcesContent":["section#counter-stats {\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\tmargin-top: 100px;\r\n}\r\n\r\n\r\n\r\n.stats {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  border: 1px solid #ccc;\r\n  border-radius: 8px;\r\n  padding: 20px;\r\n  margin: 10px;\r\n  transition: background-color 0.3s;\r\n  flex: 1 1 20%; /* Ensures that each item takes up equal space and wraps if necessary */\r\n  background-color: #AB7F21;\r\n  color: white;\r\n  max-width: 150px;\r\n}\r\n\r\n.stats:hover {\r\n  background-color: white;\r\n  color: #AB7F21;\r\n}\r\n\r\n.milestone_counter {\r\n  color: inherit; /* Inherit text color from .stats */\r\n}\r\n\r\n.milestone_text {\r\n  color: inherit; /* Inherit text color from .stats */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
