import React, { useState } from 'react';
import { Link } from 'react-router-dom';


function DoctrPage({ onComponentChange }) {

    const allDoctors = [
        { id: 2, name: 'Dr. Omar AlGethami', image: '/Doctors/omar.jpg', branch: "Mirdif Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 3, name: 'Dr. Neha Sachdev', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        // { id: 4, name: 'Dr. Ritul Agarwal', image: '/Doctors/ritul.png', branch: "Mirdif Branch", speciality: 'Prosthodontist And Implantologist', languages: ['Arabic', 'English'] },
        { id: 5, name: 'Dr. Anil Saragur', image: '/Doctors/anil.jpg', branch: "Mirdif Branch", speciality: 'Specialist Orthodontics', languages: ['Arabic', 'English'] },
        // { id: 6, name: 'Dr. Mina Charopeme', image: '/Doctors/mina.jpg', branch: "Mirdif Branch", speciality: 'Specialist Pediatric Dentist', languages: ['Arabic', 'English'] },
        // { id: 7, name: 'Dr. Mirna Abdelmesieh', image: '/Doctors/Merna.jpg', branch: "Mirdif Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 8, name: 'Jennylyn Evangelista', image: '/Doctors/jenny.jpg', branch: "Mirdif Branch", speciality: 'Beauty and Laser Therapist', languages: ['Arabic', 'English'] },

      

        { id: 13, name: 'Dr. Khaled Rezk', image: '/Doctors/rezq.png', branch: "Marina Branch", speciality: 'Specialist Plastic Surgery', languages: ['Arabic', 'English'] },
        // {
        //     "id": 14,
        //     "name": "Dr. Ahmad Maadarani",
        //     "image": "/Doctors/maadrani.jpg",
        //     "branch": "Marina Branch",
        //     "speciality": "General Practitioner Aesthetic",
        //     "languages": ["Arabic", "English"],
        //     "core": [
        //         "Diagnosis and treatment of skin conditions.",
        //         "Cosmetic procedures such as Botox, fillers, threads, mesotherapy and chemical peels.",
        //         "Laser treatments for various skin issues.",
        //         "Skincare advice and product recommendations.",
        //         "Knowledge of dermatological medications and their effects."
        //     ],
        //     "profile": [
        //         "Lebanese", "General Aesthethic Practitioner", "3 Years"
        //     ],
        //     "qualification": [
        //         "Bachelor's degree in Medicine.",
        //         "Doctor of Medicine (MD) in Dermatology.",
        //         "Board certification in Dermatology and Cosmetology.",
        //         "3 years of experience in dermatology and cosmetology practice.",
        //         "Continuing education in dermatological advancements and cosmetic procedures.",
        //         "Diploma in aesthetic medicine from American association of continuing medical education.",
        //         "Certificate aesthetic medicine course accredited by DHA."
        //     ],
        //     "lang": ["Arabic", "Russian", "Ukrainian", "English"],

        // },

        { id: 17, name: 'SP. Joy Cadias', image: '/Doctors/joy.JPG', branch: "Marina Branch", speciality: 'Beauty and Laser Therapist', languages: ['Arabic', 'English'] },

        { id: 18, name: 'Dr. Said Hassan', image: '/Doctors/saed.jpg', branch: "Ras Al Khaima Branch", speciality: 'General and Laparoscopic Surgeon', languages: ['Arabic', 'English'] },
        {
            id: 19,
            name: "Dr. Tareq Boghdady",
            image: "/Doctors/tareq.JPG",
            branch: "Ras Al Khaima Branch",
            speciality: "Specialist Dermatology",
            languages: ["Arabic", "English"],
            core: [
                "Cosmetic dermatology",
                "Botox, fillers, Mesotherapy and PRP treatment",
                "Skin lifting, tightening and HIFU face treatment",
                "Microneedling and radiofrequency",
                "Chemical peeling and fractional resurfacing",
                "Acne and acne scars treatment",
                "Hair, nail disorders and common dermatological disorders"
            ],
            profile: [
                "Egyptian",
                "Specialist Dermatologist",
                "25 Years"
            ],
            qualification: [
                "MBBCh .. Ain shams university, Cairo Egypt",
                "MS. Dermatology and venereal diseases .. Ain Shams university, Cairo Egypt",
                "Diploma course in Aesthetic medicine and laser .. Cairo university, Egypt",
                "Facial contouring and aging medicine .. Cairo university, Egypt",
                "Membership in Egyptian medical syndicate and Egyptian dermatologists association"
            ],
            lang: ['Arabic', 'English', 'Dutch']
        }
        ,
        {
            "id": 20,
            "name": "Dr. Hussam Kayali",
            "image": "/Doctors/kayaliH.JPG",
            "branch": "Ras Al Khaima Branch",
            "speciality": "General Dentist",
            "languages": ["Arabic", "English"],
            "core": [
                "Cosmetic Dentistry",
                "Restorative Dentistry",
                "Dental Surgery of Surgical and Non-Surgical Extractions",
                "Certified Invisalign Provider"
            ],
            "profile": {
                "Nationality": "Syrian Arab Republic",
                "Summary": "Dr. Hussam Kayali has 3 years of experience in all fields of practical dentistry with experience across the Emirates of the UAE."
            },
            "qualifications": [
                "DDS (Doctor of Dental Surgery) from Ajman University",
                "ICD (Internship in clinical dentistry)",
                "DHA and MOH license Registered and Evaluation letter holder",
                "Volunteering instructor in both Ajman and RAK dental universities"
            ],
            "lang": ["Arabic", "English", "Urdu-Hindi"],

        }
        ,
        {
            "id": 22,
            "name": "Dr. Asmaa Hendawi",
            "image": "/Doctors/asma.jpg",
            "branch": "Sharjah Branch",
            "speciality": "Specialist Orthodontist",
            "languages": ["Arabic", "English"],
            "core": [
                "Fixed braces",
                "Transparent invisible braces"
            ],
            "profile": [
                "Syria", "Orthodentistry", "5 years"
            ],
            "qualification": [
                "Dental Medicine and Surgery from the University of Aleppo, Syria, 2013",
                "Orthodontics, University of Aleppo, Syria 2019",
                "Orthodontic license DHA",
                "Orthodontic license MOH"
            ],
            "lang": ["Arabic", "English"],

        },
        {
            "id": 24,
            "name": "Dr. Nijad Naeisseh",
            "image": "/Doctors/nijad.png",
            "branch": "Sharjah Branch",
            "speciality": "Specialist Implantologist",
            "languages": ["Arabic", "English"],
            "core": [
                "Oral surgery",
                "Implantology",
                "Oral medicine",
                "Advanced dentistry"
            ],
            "profile": [
                "Canadian", "Implantology", "30 Years"
            ],
            "qualification": [
                "CES oral surgery France 1996",
                "Hospital resident 1992-1998 Cannes, France",
                "Consultant OMF, KSA 2002-2004",
                "MOH, DHA UAE since 2013"
            ],
            "lang": ["English", "French", "Arabic"],

        }, {
            id: 25, name: 'Dr. Hazem Kayali', image: '/Doctors/kayali.png', branch: "Sharjah Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'],
            core: ['smile analysis', 'cosmetic Dentistry, Laminate Veneers, Lumineers', 'All types of fixed prosthodontics (crowns, traditional, complex or Maryland bridges)', 'Aesthetic Fillings', 'Endodontic treatment, (Root canal treatment simple and advanced cases, and root canal re-treatment)', 'Preventive Dentistry', 'Dental Prophylaxis and teeth whitening', 'Gum depigmentation, gingivectomy, gingivoplasty and crown lengthening', 'Dental Laser Treatments'],
            qualification: ['Bachelor degree in Dental Medicine & Surgery in May 2010 at Ain Shams University', 'Master degree in fixed prosthodontics at Ain Shams University in September 2015, and thesis defense in January 2016', 'Dental photography course with Michigan state university in 2020', 'Dental laser workshop in 2018', 'Prosthetic Veneer Workshop by prof. Carlos Sabrosa (Brazil) in 2013', 'Basic and Advanced Endodontic Course in 2010', 'In addition to contribution and attendance of the International Dental Congress of the Egyptian Dental Association in 2009, 2011 and 2013'],
            profile: ['Syrian', 'Dentistry (Prosthodontics)', '14 Years'],
            lang: ['Arabic', 'English', 'Dutch']
        },
        { id: 27, name: 'Dr. Riza Khirandish', image: '/Doctors/reza.png', branch: "Sharjah Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        {
            "id": 28,
            "name": "Dr. Ahmad Sirafi",
            "image": "/Doctors/srarfi.png",
            "branch": "Sharjah Branch",
            "speciality": "General Dentist",
            "languages": ["Arabic", "English"],
            "core": [
                "Pediatric dentistry",
                "Nitrous oxide administration",
                "All routine dental treatments"
            ],
            "profile": [
                "Syrian", "General Dentistry", "5 Years"
            ],
            "qualification": [
                "Bachelor degree in dentistry (DDS)"
            ],
            "lang": ["Arabic", "English"],

        },
        {
            "id": 29,
            "name": "Dr. Nouraldin Al Farra",
            "image": "/Doctors/farra.JPG",
            "branch": "Sharjah Branch",
            "speciality": "General Dentist",
            "languages": ["Arabic", "English"],
            "core": [
                "Cosmetic dentistry (veneers, hollywood smile, and teeth bleaching)",
                "Routine dental treatment (scaling and polishing, fillings, and root canal treatment)",
                "Surgical extraction",
                "Dental implants",
                "Crowns and bridges",
                "Partial and complete dentures"
            ],
            "profile": [
                "Palestine", "General Denstistry", "10 Years"
            ],
            "qualification": [
                "Bachelor of Dentistry (DDS) (2014)",
                "From: Ajman University for Science & Technology (AUST)"
            ],
            "lang": ["Arabic", "English"],

        }
        ,
        {
            id: 31,
            name: "Dr. Tareq Alkurd",
            image: "/Doctors/tariq.JPG",
            branch: "Ajman Branch",
            speciality: "General Dentist",
            languages: ["Arabic", "English"],
            core: [
                "Cosmetic dentistry (Veneers, lumineers)",
                "Dental surgeries (tooth extraction, wisdom & impaction)",
                "Restorative dentistry including fillings, crowns, veneers, and bridges.",
                "Endodontic treatment including root canal treatments.",
                "Periodontal treatment including gum treatments.",
                "Implant"
            ],
            profile: [
                "Palestine",
                "General Dentistry",
                "17 Years"
            ]
            ,
            qualification: [
                "Bachelor of dental surgery B.D.S EGYPT",
                "Post graduate diploma in oral implant DGZI GERMANY",
                "DHA 77234474",
                "MOH D68455"
            ],
            lang: ['Arabic', 'English', 'Dutch']

        }

        ,
        { id: 32, name: 'Dr. Sara Hussein', image: '/Doctors/alnuaimi.jpeg', branch: "Ajman Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { 
            id: 66, name: 'Dr. Ahmed Khazbak', image: '/Doctors/kazbak.jpg', 
            branch: "Mirdif Branch", speciality: 'Consultant Plastic Surgery', languages: ['Arabic', 'English'] ,
            core: [
                "Rhinoplasty",
                "Mommy Makeover",
                "Weight Loss Procedures",
                "Brazilian Buttock Lift",
                "Gynecomastia Procedure",
                "Breast Augmentation",
                "Abdominoplasty",
                "Liposuction",
                "Post Weight Loss Body Contouring"
            ],
            profile: [
                "Egypt",
                "Consultant Plastic Surgery",
                "15 Years"
            ]
            ,
            qualification: [
                "Double Board-Certified Plastic Surgeon",
                "More Than 15 years of Professional Experience",
                "Ain Shams University, Post Graduate",
                "Universitat Autonoma de Barcelona, Postgraduate"
            ],
            lang: ['Arabic', 'English']

        },



    ];

    const [selectedSpeciality, setSelectedSpeciality] = useState('');
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('');

    const specialities = Array.from(new Set(allDoctors.map((doctor) => doctor.speciality)));

    const filteredDoctors = allDoctors.filter((doctor) => {
        return (!selectedSpeciality || doctor.speciality === selectedSpeciality) &&
            (selectedLanguages.length === 0 ||
                selectedLanguages.includes('All') ||
                selectedLanguages.some(language => doctor.languages.includes(language))) &&
            (!selectedBranch || doctor.branch === selectedBranch);
    });

    const handleLanguageChange = (event) => {
        const selectedValues = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedLanguages(selectedValues);
    };

    const handleSpecialityChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedSpeciality(selectedValue);
    };

    const handleBranchChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedBranch(selectedValue);
    };

    return (
        <div className="team" style={{ marginTop: "75px" }}>
            <div className="container">
                <div className='row'>
                    <div className='col-lg-6'>
                        <select className="contact_select contact_input" style={{ width: "100%" }} onChange={handleBranchChange}>
                            <option value="">All Branches</option>
                            <option value="Sharjah Branch">Sharjah Branch</option>
                            <option value="Ajman Branch">Ajman Branch</option>
                            <option value="Ras Al Khaima Branch">Ras Al Khaima Branch</option>
                            <option value="Marina Branch">Marina Branch</option>
                            <option value="Jumeirah Branch">Jumeirah Branch</option>
                            <option value="Mirdif Branch">Mirdif Branch</option>
                        </select>
                    </div>

                    <div className='col-lg-6'>
                        <select className="contact_select contact_input" style={{ width: "100%" }} onChange={handleSpecialityChange}>
                            <option value="">All Specialities</option>
                            {specialities.map((speciality, index) => (
                                <option key={index} value={speciality}>
                                    {speciality}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="row team_row">
                    {filteredDoctors
                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort the doctors array alphabetically by name
                        .map((doctor, index) => (
                            <div key={index} className="col-lg-4 team_col">
                                {doctor.core && doctor.core.length > 0 ? (
                                    <Link to="/info" className="team_item text-center d-flex flex-column align-items-center justify-content-end" onClick={() => onComponentChange('info', doctor)}>
                                        <div className="team_image"><img src={doctor.image} alt="" style={{ height: "450px", width: "350px", objectFit: "cover", marginBottom: "-30px" }} /></div>
                                        <div className="team_content text-center">
                                            <div className="team_name"><a href="#">{doctor.name}</a></div>
                                            <div className="team_title">{doctor.speciality}</div>
                                            <div className="team_text">
                                                {/* <p>Ultrices ut. Etiam ac erat ut enim maximus accumsan vel ac nisl. Duis feugiat bibendum orci, non elementum urna.</p> */}
                                            </div>
                                        </div>
                                    </Link>
                                ) : (
                                    <div className="team_item text-center d-flex flex-column align-items-center justify-content-end">
                                        <div className="team_image"><img src={doctor.image} alt="" style={{ height: "450px", width: "350px", objectFit: "cover", marginBottom: "-30px"}} /></div>
                                        <div className="team_content text-center" >
                                            <div className="team_name" style={{ fontSize: "24px", fontWeight: "600", color: "#404040" }}>{doctor.name}</div>
                                            <div className="team_title">{doctor.speciality}</div>
                                            <div className="team_text">
                                                {/* <p>Ultrices ut. Etiam ac erat ut enim maximus accumsan vel ac nisl. Duis feugiat bibendum orci, non elementum urna.</p> */}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            </div>
        </div >
    )
}
export default DoctrPage