import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FaX } from "react-icons/fa6";
import Button from 'react-bootstrap/Button';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';


function Appointment({ show, handleClose, doctor }) {
    const countries = [
        { label: "Afghanistan", value: "93" },
        { label: "Åland Islands", value: "358" },
        { label: "Albania", value: "355" },
        { label: "Algeria", value: "213" },
        { label: "American Samoa", value: "1-684" },
        { label: "Andorra", value: "376" },
        { label: "Angola", value: "244" },
        { label: "Anguilla", value: "1-264" },
        { label: "Antarctica", value: "672" },
        { label: "Antigua and Barbuda", value: "1-268" },
        { label: "Argentina", value: "54" },
        { label: "Armenia", value: "374" },
        { label: "Aruba", value: "297" },
        { label: "Australia", value: "61" },
        { label: "Austria", value: "43" },
        { label: "Azerbaijan", value: "994" },
        { label: "Bahamas", value: "1-242" },
        { label: "Bahrain", value: "973" },
        { label: "Bangladesh", value: "880" },
        { label: "Barbados", value: "1-246" },
        { label: "Belarus", value: "375" },
        { label: "Belgium", value: "32" },
        { label: "Belize", value: "501" },
        { label: "Benin", value: "229" },
        { label: "Bermuda", value: "1-441" },
        { label: "Bhutan", value: "975" },
        { label: "Bolivia (Plurinational State of)", value: "591" },
        { label: "Bonaire, Sint Eustatius and Saba", value: "599" },
        { label: "Bosnia and Herzegovina", value: "387" },
        { label: "Botswana", value: "267" },
        { label: "Bouvet Island", value: "47" },
        { label: "Brazil", value: "55" },
        { label: "British Indian Ocean Territory", value: "246" },
        { label: "Brunei Darussalam", value: "673" },
        { label: "Bulgaria", value: "359" },
        { label: "Burkina Faso", value: "226" },
        { label: "Burundi", value: "257" },
        { label: "Cabo Verde", value: "238" },
        { label: "Cambodia", value: "855" },
        { label: "Cameroon", value: "237" },
        { label: "Canada", value: "1" },
        { label: "Cayman Islands", value: "1-345" },
        { label: "Central African Republic", value: "236" },
        { label: "Chad", value: "235" },
        { label: "Chile", value: "56" },
        { label: "China", value: "86" },
        { label: "Christmas Island", value: "61" },
        { label: "Cocos (Keeling) Islands", value: "61" },
        { label: "Colombia", value: "57" },
        { label: "Comoros", value: "269" },
        { label: "Congo", value: "242" },
        { label: "Congo (Democratic Republic of the)", value: "243" },
        { label: "Cook Islands", value: "682" },
        { label: "Costa Rica", value: "506" },
        { label: "Croatia", value: "385" },
        { label: "Cuba", value: "53" },
        { label: "Curaçao", value: "599" },
        { label: "Cyprus", value: "357" },
        { label: "Czech Republic", value: "420" },
        { label: "Denmark", value: "45" },
        { label: "Djibouti", value: "253" },
        { label: "Dominica", value: "1-767" },
        { label: "Dominican Republic", value: "1-809, 1-829, 1-849" },
        { label: "Ecuador", value: "593" },
        { label: "Egypt", value: "20" },
        { label: "El Salvador", value: "503" },
        { label: "Equatorial Guinea", value: "240" },
        { label: "Eritrea", value: "291" },
        { label: "Estonia", value: "372" },
        { label: "Eswatini", value: "268" },
        { label: "Ethiopia", value: "251" },
        { label: "Falkland Islands (Malvinas)", value: "500" },
        { label: "Faroe Islands", value: "298" },
        { label: "Fiji", value: "679" },
        { label: "Finland", value: "358" },
        { label: "France", value: "33" },
        { label: "French Guiana", value: "594" },
        { label: "French Polynesia", value: "689" },
        { label: "French Southern Territories", value: "262" },
        { label: "Gabon", value: "241" },
        { label: "Gambia", value: "220" },
        { label: "Georgia", value: "995" },
        { label: "Germany", value: "49" },
        { label: "Ghana", value: "233" },
        { label: "Gibraltar", value: "350" },
        { label: "Greece", value: "30" },
        { label: "Greenland", value: "299" },
        { label: "Grenada", value: "1-473" },
        { label: "Guadeloupe", value: "590" },
        { label: "Guam", value: "1-671" },
        { label: "Guatemala", value: "502" },
        { label: "Guernsey", value: "44-1481" },
        { label: "Guinea", value: "224" },
        { label: "Guinea-Bissau", value: "245" },
        { label: "Guyana", value: "592" },
        { label: "Haiti", value: "509" },
        { label: "Heard Island and McDonald Islands", value: "672" },
        { label: "Holy See", value: "379" },
        { label: "Honduras", value: "504" },
        { label: "Hong Kong", value: "852" },
        { label: "Hungary", value: "36" },
        { label: "Iceland", value: "354" },
        { label: "India", value: "91" },
        { label: "Indonesia", value: "62" },
        { label: "Iran (Islamic Republic of)", value: "98" },
        { label: "Iraq", value: "964" },
        { label: "Ireland", value: "353" },
        { label: "Isle of Man", value: "44-1624" },
        { label: "Israel", value: "972" },
        { label: "Italy", value: "39" },
        { label: "Jamaica", value: "1-876" },
        { label: "Japan", value: "81" },
        { label: "Jersey", value: "44-1534" },
        { label: "Jordan", value: "962" },
        { label: "Kazakhstan", value: "7" },
        { label: "Kenya", value: "254" },
        { label: "Kiribati", value: "686" },
        { label: "Korea (Democratic People's Republic of)", value: "850" },
        { label: "Korea (Republic of)", value: "82" },
        { label: "Kuwait", value: "965" },
        { label: "Kyrgyzstan", value: "996" },
        { label: "Lao People's Democratic Republic", value: "856" },
        { label: "Latvia", value: "371" },
        { label: "Lebanon", value: "961" },
        { label: "Lesotho", value: "266" },
        { label: "Liberia", value: "231" },
        { label: "Libya", value: "218" },
        { label: "Liechtenstein", value: "423" },
        { label: "Lithuania", value: "370" },
        { label: "Luxembourg", value: "352" },
        { label: "Macao", value: "853" },
        { label: "Madagascar", value: "261" },
        { label: "Malawi", value: "265" },
        { label: "Malaysia", value: "60" },
        { label: "Maldives", value: "960" },
        { label: "Mali", value: "223" },
        { label: "Malta", value: "356" },
        { label: "Marshall Islands", value: "692" },
        { label: "Martinique", value: "596" },
        { label: "Mauritania", value: "222" },
        { label: "Mauritius", value: "230" },
        { label: "Mayotte", value: "262" },
        { label: "Mexico", value: "52" },
        { label: "Micronesia (Federated States of)", value: "691" },
        { label: "Moldova (Republic of)", value: "373" },
        { label: "Monaco", value: "377" },
        { label: "Mongolia", value: "976" },
        { label: "Montenegro", value: "382" },
        { label: "Montserrat", value: "1-664" },
        { label: "Morocco", value: "212" },
        { label: "Mozambique", value: "258" },
        { label: "Myanmar", value: "95" },
        { label: "Namibia", value: "264" },
        { label: "Nauru", value: "674" },
        { label: "Nepal", value: "977" },
        { label: "Netherlands", value: "31" },
        { label: "New Caledonia", value: "687" },
        { label: "New Zealand", value: "64" },
        { label: "Nicaragua", value: "505" },
        { label: "Niger", value: "227" },
        { label: "Nigeria", value: "234" },
        { label: "Niue", value: "683" },
        { label: "Norfolk Island", value: "672" },
        { label: "North Macedonia", value: "389" },
        { label: "Northern Mariana Islands", value: "1-670" },
        { label: "Norway", value: "47" },
        { label: "Oman", value: "968" },
        { label: "Pakistan", value: "92" },
        { label: "Palau", value: "680" },
        { label: "Palestine, State of", value: "970" },
        { label: "Panama", value: "507" },
        { label: "Papua New Guinea", value: "675" },
        { label: "Paraguay", value: "595" },
        { label: "Peru", value: "51" },
        { label: "Philippines", value: "63" },
        { label: "Pitcairn", value: "64" },
        { label: "Poland", value: "48" },
        { label: "Portugal", value: "351" },
        { label: "Puerto Rico", value: "1-787, 1-939" },
        { label: "Qatar", value: "974" },
        { label: "Réunion", value: "262" },
        { label: "Romania", value: "40" },
        { label: "Russian Federation", value: "7" },
        { label: "Rwanda", value: "250" },
        { label: "Saint Barthélemy", value: "590" },
        { label: "Saint Helena, Ascension and Tristan da Cunha", value: "290" },
        { label: "Saint Kitts and Nevis", value: "1-869" },
        { label: "Saint Lucia", value: "1-758" },
        { label: "Saint Martin (French part)", value: "590" },
        { label: "Saint Pierre and Miquelon", value: "508" },
        { label: "Saint Vincent and the Grenadines", value: "1-784" },
        { label: "Samoa", value: "685" },
        { label: "San Marino", value: "378" },
        { label: "Sao Tome and Principe", value: "239" },
        { label: "Saudi Arabia", value: "966" },
        { label: "Senegal", value: "221" },
        { label: "Serbia", value: "381" },
        { label: "Seychelles", value: "248" },
        { label: "Sierra Leone", value: "232" },
        { label: "Singapore", value: "65" },
        { label: "Sint Maarten (Dutch part)", value: "1-721" },
        { label: "Slovakia", value: "421" },
        { label: "Slovenia", value: "386" },
        { label: "Solomon Islands", value: "677" },
        { label: "Somalia", value: "252" },
        { label: "South Africa", value: "27" },
        { label: "South Georgia and the South Sandwich Islands", value: "500" },
        { label: "South Sudan", value: "211" },
        { label: "Spain", value: "34" },
        { label: "Sri Lanka", value: "94" },
        { label: "Sudan", value: "249" },
        { label: "Suriname", value: "597" },
        { label: "Svalbard and Jan Mayen", value: "47" },
        { label: "Sweden", value: "46" },
        { label: "Switzerland", value: "41" },
        { label: "Syrian Arab Republic", value: "963" },
        { label: "Taiwan", value: "886" },
        { label: "Tajikistan", value: "992" },
        { label: "Tanzania, United Republic of", value: "255" },
        { label: "Thailand", value: "66" },
        { label: "Timor-Leste", value: "670" },
        { label: "Togo", value: "228" },
        { label: "Tokelau", value: "690" },
        { label: "Tonga", value: "676" },
        { label: "Trinidad and Tobago", value: "1-868" },
        { label: "Tunisia", value: "216" },
        { label: "Turkey", value: "90" },
        { label: "Turkmenistan", value: "993" },
        { label: "Turks and Caicos Islands", value: "1-649" },
        { label: "Tuvalu", value: "688" },
        { label: "Uganda", value: "256" },
        { label: "Ukraine", value: "380" },
        { label: "United Arab Emirates", value: "971" },
        { label: "United Kingdom of Great Britain and Northern Ireland", value: "44" },
        { label: "United States of America", value: "1" },
        { label: "Uruguay", value: "598" },
        { label: "Uzbekistan", value: "998" },
        { label: "Vanuatu", value: "678" },
        { label: "Venezuela (Bolivarian Republic of)", value: "58" },
        { label: "Viet Nam", value: "84" },
        { label: "Wallis and Futuna", value: "681" },
        { label: "Western Sahara", value: "212" },
        { label: "Yemen", value: "967" },
        { label: "Zambia", value: "260" },
        { label: "Zimbabwe", value: "263" }
    ]

    const allDoctors = [
        // { id: 1, name: 'Dr. Emad Mohamed', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'Specialist Dermatologist', languages: ['Arabic', 'English'] },
        { id: 2, name: 'Dr. Omar AlGethami', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 3, name: 'Dr. Neha Sachdev', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        // { id: 4, name: 'Dr. Ritul Agarwal', image: '/Doctors/ritul.png', branch: "Mirdif Branch", speciality: 'Prosthodontist And Implantologist', languages: ['Arabic', 'English'] },
        { id: 5, name: 'Dr. Anil Saragur', image: '/Doctors/anil.jpg', branch: "Mirdif Branch", speciality: 'Specialist Orthodontics', languages: ['Arabic', 'English'] },
        // { id: 6, name: 'Dr. Mina Charopeme', image: '/Doctors/mina.jpg', branch: "Mirdif Branch", speciality: 'Specialist Pediatric Dentist', languages: ['Arabic', 'English'] },
        // { id: 7, name: 'Dr. Mirna Abdelmesieh', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 8, name: 'Jennylyn Evangelista', image: '/Doctors/user.png', branch: "Mirdif Branch", speciality: 'Beauty and Laser Therapist', languages: ['Arabic', 'English'] },

        // { id: 9, name: 'Dr. Pierfrancesco Mancini', image: '/Doctors/user.png', branch: "Jumeirah Branch", speciality: 'Consultant Plastic Surgery', languages: ['Arabic', 'English'] },
        // { id: 10, name: 'Dr. Carmelo Crisafulli', image: '/Doctors/user.png', branch: "Jumeirah Branch", speciality: 'Specialist Plastic Surgery', languages: ['Arabic', 'English'] },
        // { id: 11, name: 'Dr. Rokea Alrifaee', image: '/Doctors/user.png', branch: "Jumeirah Branch", speciality: 'Specialist Dermatology', languages: ['Arabic', 'English'] },
        // { id: 12, name: 'SP. Alena Kuzima', image: '/Doctors/user.png', branch: "Jumeirah Branch", speciality: 'Specialist Dermatology', languages: ['Arabic', 'English'] },

        { id: 13, name: 'Dr. Khaled Rezk', image: '/Doctors/rezq.png', branch: "Marina Branch", speciality: 'Specialist Plastic Surgery', languages: ['Arabic', 'English'] },
        // {
        //     "id": 14,
        //     "name": "Dr. Ahmad Maadarani",
        //     "image": "/Doctors/user.png",
        //     "branch": "Marina Branch",
        //     "speciality": "General Practitioner Aesthetic",
        //     "languages": ["Arabic", "English"],
        //     "core": [
        //         "Diagnosis and treatment of skin conditions.",
        //         "Cosmetic procedures such as Botox, fillers, threads, mesotherapy and chemical peels.",
        //         "Laser treatments for various skin issues.",
        //         "Skincare advice and product recommendations.",
        //         "Knowledge of dermatological medications and their effects."
        //     ],
        //     "profile": [
        //         "Lebanese", "General Aesthethic Practitioner", "3 Years"
        //     ],
        //     "qualification": [
        //         "Bachelor's degree in Medicine.",
        //         "Doctor of Medicine (MD) in Dermatology.",
        //         "Board certification in Dermatology and Cosmetology.",
        //         "3 years of experience in dermatology and cosmetology practice.",
        //         "Continuing education in dermatological advancements and cosmetic procedures.",
        //         "Diploma in aesthetic medicine from American association of continuing medical education.",
        //         "Certificate aesthetic medicine course accredited by DHA."
        //     ],
        //     "lang": ["Arabic", "Russian", "Ukrainian", "English"],

        // },

        // { id: 15, name: 'Dr. Lina Saleh', image: '/Doctors/user.png', branch: "Marina Branch", speciality: 'General Practitioner Aesthetic', languages: ['Arabic', 'English'] },
        { id: 16, name: 'SP. Alla Abu Baker', image: '/Doctors/user.png', branch: "Marina Branch", speciality: 'Beauty and Laser Therapist', languages: ['Arabic', 'English'] },
        { id: 17, name: 'SP. Joy Cadias', image: '/Doctors/user.png', branch: "Marina Branch", speciality: 'Beauty and Laser Therapist', languages: ['Arabic', 'English'] },

        { id: 18, name: 'Dr. Said Hassan', image: '/Doctors/saed.jpg', branch: "Ras Al Khaima Branch", speciality: 'General and Laparoscopic Surgeon', languages: ['Arabic', 'English'] },
        {
            id: 19,
            name: "Dr. Tareq Boghdady",
            image: "/Doctors/tareq.JPG",
            branch: "Ras Al Khaima Branch",
            speciality: "Specialist Dermatology",
            languages: ["Arabic", "English"],
            core: [
                "Cosmetic dermatology",
                "Botox, fillers, Mesotherapy and PRP treatment",
                "Skin lifting, tightening and HIFU face treatment",
                "Microneedling and radiofrequency",
                "Chemical peeling and fractional resurfacing",
                "Acne and acne scars treatment",
                "Hair, nail disorders and common dermatological disorders"
            ],
            profile: [
                "Egyptian",
                "Specialist Dermatologist",
                "25 Years"
            ],
            qualification: [
                "MBBCh .. Ain shams university, Cairo Egypt",
                "MS. Dermatology and venereal diseases .. Ain Shams university, Cairo Egypt",
                "Diploma course in Aesthetic medicine and laser .. Cairo university, Egypt",
                "Facial contouring and aging medicine .. Cairo university, Egypt",
                "Membership in Egyptian medical syndicate and Egyptian dermatologists association"
            ],
            lang: ['Arabic', 'English', 'Dutch']
        }
        ,
        {
            "id": 20,
            "name": "Dr. Hussam Kayali",
            "image": "/Doctors/KayaliH.JPG",
            "branch": "Ras Al Khaima Branch",
            "speciality": "General Dentist",
            "languages": ["Arabic", "English"],
            "core": [
                "Cosmetic Dentistry",
                "Restorative Dentistry",
                "Dental Surgery of Surgical and Non-Surgical Extractions",
                "Certified Invisalign Provider"
            ],
            "profile": {
                "Nationality": "Syrian Arab Republic",
                "Summary": "Dr. Hussam Kayali has 3 years of experience in all fields of practical dentistry with experience across the Emirates of the UAE."
            },
            "qualifications": [
                "DDS (Doctor of Dental Surgery) from Ajman University",
                "ICD (Internship in clinical dentistry)",
                "DHA and MOH license Registered and Evaluation letter holder",
                "Volunteering instructor in both Ajman and RAK dental universities"
            ],
            "lang": ["Arabic", "English", "Urdu-Hindi"],

        }
        ,
        // { id: 21, name: 'Dr. Khaled Shahin', image: '/Doctors/user.png', branch: "Sharjah Branch", speciality: 'Specialist Dermatology', languages: ['Arabic', 'English'] },
        {
            "id": 22,
            "name": "Dr. Asmaa Hendawi",
            "image": "/Doctors/user.png",
            "branch": "Sharjah Branch",
            "speciality": "Specialist Orthodontist",
            "languages": ["Arabic", "English"],
            "core": [
                "Fixed braces",
                "Transparent invisible braces"
            ],
            "profile": [
                "Syria", "Orthodentistry", "5 years"
            ],
            "qualification": [
                "Dental Medicine and Surgery from the University of Aleppo, Syria, 2013",
                "Orthodontics, University of Aleppo, Syria 2019",
                "Orthodontic license DHA",
                "Orthodontic license MOH"
            ],
            "lang": ["Arabic", "English"],

        },
        // { id: 23, name: 'Dr. Razan Sedki', image: '/Doctors/user.png', branch: "Sharjah Branch", speciality: 'Specialist Prosthodontics', languages: ['Arabic', 'English'] },
        {
            "id": 24,
            "name": "Dr. Nijad Naeisseh",
            "image": "/Doctors/user.png",
            "branch": "Sharjah Branch",
            "speciality": "Specialist Implantologist",
            "languages": ["Arabic", "English"],
            "core": [
                "Oral surgery",
                "Implantology",
                "Oral medicine",
                "Advanced dentistry"
            ],
            "profile": [
                "Canadian", "Implantology", "30 Years"
            ],
            "qualification": [
                "CES oral surgery France 1996",
                "Hospital resident 1992-1998 Cannes, France",
                "Consultant OMF, KSA 2002-2004",
                "MOH, DHA UAE since 2013"
            ],
            "lang": ["English", "French", "Arabic"],

        }, {
            id: 25, name: 'Dr. Hazem Kayali', image: '/Doctors/kayali.png', branch: "Sharjah Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'],
            core: ['smile analysis', 'cosmetic Dentistry, Laminate Veneers, Lumineers', 'All types of fixed prosthodontics (crowns, traditional, complex or Maryland bridges)', 'Aesthetic Fillings', 'Endodontic treatment, (Root canal treatment simple and advanced cases, and root canal re-treatment)', 'Preventive Dentistry', 'Dental Prophylaxis and teeth whitening', 'Gum depigmentation, gingivectomy, gingivoplasty and crown lengthening', 'Dental Laser Treatments'],
            qualification: ['Bachelor degree in Dental Medicine & Surgery in May 2010 at Ain Shams University', 'Master degree in fixed prosthodontics at Ain Shams University in September 2015, and thesis defense in January 2016', 'Dental photography course with Michigan state university in 2020', 'Dental laser workshop in 2018', 'Prosthetic Veneer Workshop by prof. Carlos Sabrosa (Brazil) in 2013', 'Basic and Advanced Endodontic Course in 2010', 'In addition to contribution and attendance of the International Dental Congress of the Egyptian Dental Association in 2009, 2011 and 2013'],
            profile: ['Syrian', 'Dentistry (Prosthodontics)', '14 Years'],
            lang: ['Arabic', 'English', 'Dutch']
        },
        // { id: 26, name: 'Dr. Muna Abu Baker', image: '/Doctors/user.png', branch: "Sharjah Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 27, name: 'Dr. Riza Khirandish', image: '/Doctors/reza.png', branch: "Sharjah Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        {
            "id": 28,
            "name": "Dr. Ahmad Sirafi",
            "image": "/Doctors/srarfi.png",
            "branch": "Sharjah Branch",
            "speciality": "General Dentist",
            "languages": ["Arabic", "English"],
            "core": [
                "Pediatric dentistry",
                "Nitrous oxide administration",
                "All routine dental treatments"
            ],
            "profile": [
                "Syrian", "General Dentistry", "5 Years"
            ],
            "qualification": [
                "Bachelor degree in dentistry (DDS)"
            ],
            "lang": ["Arabic", "English"],

        },
        {
            "id": 29,
            "name": "Dr. Nouraldin Al Farra",
            "image": "/Doctors/user.png",
            "branch": "Sharjah Branch",
            "speciality": "General Dentist",
            "languages": ["Arabic", "English"],
            "core": [
                "Cosmetic dentistry (veneers, hollywood smile, and teeth bleaching)",
                "Routine dental treatment (scaling and polishing, fillings, and root canal treatment)",
                "Surgical extraction",
                "Dental implants",
                "Crowns and bridges",
                "Partial and complete dentures"
            ],
            "profile": [
                "Palestine", "General Denstistry", "10 Years"
            ],
            "qualification": [
                "Bachelor of Dentistry (DDS) (2014)",
                "From: Ajman University for Science & Technology (AUST)"
            ],
            "lang": ["Arabic", "English"],

        }
        ,
        // { id: 30, name: 'Dr. Rehab Mzoughi', image: '/Doctors/rihab.JPG', branch: "Ajman Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        {
            id: 31,
            name: "Dr. Tareq Alkurd",
            image: "/Doctors/tariq.JPG",
            branch: "Ajman Branch",
            speciality: "General Dentist",
            languages: ["Arabic", "English"],
            core: [
                "Cosmetic dentistry (Veneers, lumineers)",
                "Dental surgeries (tooth extraction, wisdom & impaction)",
                "Restorative dentistry including fillings, crowns, veneers, and bridges.",
                "Endodontic treatment including root canal treatments.",
                "Periodontal treatment including gum treatments.",
                "Implant"
            ],
            profile: [
                "Palestine",
                "General Dentistry",
                "17 Years"
            ]
            ,
            qualification: [
                "Bachelor of dental surgery B.D.S EGYPT",
                "Post graduate diploma in oral implant DGZI GERMANY",
                "DHA 77234474",
                "MOH D68455"
            ],
            lang: ['Arabic', 'English', 'Dutch']

        }

        ,
        { id: 32, name: 'Dr. Sara Hussein', image: '/Doctors/user.png', branch: "Ajman Branch", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 66, name: 'Dr. Ahmed Khazbak', image: '/Doctors/kazbak.jpg', branch: "Mirdif Branch", speciality: 'Consultant Plastic Surgery', languages: ['Arabic', 'English'] },



    ];

    const navigate = useNavigate();

    const goToLink = (path) => {
        handleClose()
        navigate(path);
    };

    function sendEmail(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
        //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fwgrqew', 'template_4v62mp8', e.target, 'tKbfD7Qc7HH_s5Z5R')
        .then((result) => {
            goToLink("/thankyou")

            // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
        }, (error) => {
            console.log(error.text);
        });

    }

    const [selectedSpeciality, setSelectedSpeciality] = useState('');
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('');


    const specialities = Array.from(new Set(allDoctors.map((doctor) => doctor.speciality)));

    const filteredDoctors = allDoctors.filter((doctor) => {
        return (!selectedSpeciality || doctor.speciality === selectedSpeciality) &&
            (selectedLanguages.length === 0 ||
                selectedLanguages.includes('All') ||
                selectedLanguages.some(language => doctor.languages.includes(language))) &&
            (!selectedBranch || doctor.branch === selectedBranch);
    });

    const handleSpecialityChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedSpeciality(selectedValue);
    };

    const handleLanguageChange = (event) => {
        const selectedValues = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedLanguages(selectedValues);
    };

    const handleBranchChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedBranch(selectedValue);
    };
    return (
        <Modal show={show} onHide={handleClose} size="xl" >
            <Modal.Header>
                <a style={{ backgroundColor: "white", borderColor: "white", color: "black", cursor: 'pointer' }} onClick={() => handleClose()}>
                    <FaX />

                </a>

            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="contact_form_container">
                        {/* <div className="contact_form_title" >Make an Appointment</div> */}
                        <h4 style={{ marginTop: "-120px", textAlign: "center", fontSize: "24px", fontWeight: "600", color: "#ab7f21" }}>Make an Appointment</h4>
                        <form onSubmit={sendEmail} className="contact_form" id="contact_form">
                            <div className="d-flex flex-row align-items-start justify-content-between flex-wrap">
                            <div className="col-md-4 form-group mt-3 mt-md-0">
                                    <input type="hidden" className="form-control" name="sendTarget" value="cosmohealthdubaiga@gmail.com" id="phone" placeholder="Your Phone" data-rule="minlen:4" data-msg="Please enter at least 4 chars" required/>
                                    <div className="validate"></div>
                                </div>
                                <div className="col-md-4 form-group mt-3 mt-md-0">
                                    <input type="hidden" className="form-control" name="sendTargetTwo" value="cosmohealthdubaiga@gmail.com" id="phone" placeholder="Your Phone" data-rule="minlen:4" data-msg="Please enter at least 4 chars" required/>
                                    <div className="validate"></div>
                                </div>
                                <input type="text" name="name" id="name" className="contact_input" placeholder="Your Name" required />
                                <input type="email" name="email" id="email" className="contact_input" placeholder="Your E-mail" required />
                                <select className="contact_select contact_input" required onChange={handleBranchChange}>
                                    <option selected>United Arab Emirates +971</option>
                                    {countries.map((country, index) => (
                                        <option>{country.label} +{country.value}</option>
                                    ))}
                                    
                                    
                                </select>
                                <input type="tel" name="phone" id="phone" className="contact_input" placeholder="Your Phone" maxLength={9} minLength={9} required />
                                <select className="contact_select contact_input" required onChange={handleBranchChange}>
                                    <option disabled selected>Branch</option>
                                    <option>Sharjah Branch</option>
                                    <option>Ajman Branch</option>
                                    <option>Ras Al Khaimah Branch</option>
                                    <option>Marina Branch</option>
                                    <option>Jumeirah Branch</option>
                                    <option>Mirdif Branch</option>
                                </select>
                                <select className="contact_select contact_input" name="service" id="department" required onChange={handleSpecialityChange}>
                                    <option>Speciality</option>
                                    {specialities.map((speciality, index) => (
                                        <option key={index} value={speciality}>
                                            {speciality}
                                        </option>
                                    ))}
                                </select>
                                <select className="contact_select contact_input" name="doctor" id="doctor" required>
                                    <option disabled selected>Doctor</option>
                                    {filteredDoctors.map((doc, index) => (
                                        <option key={index} value={doc.name}>
                                            {doc.name}
                                        </option>
                                    ))}
                                </select>
                                <input type="datetime-local" id="date" name="date" className="contact_input datepicker" placeholder="Date" required />

                            </div>
                            <button className="button button_1 contact_button trans_200" type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            </Modal.Body >

        </Modal >
    )
}
export default Appointment