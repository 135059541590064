// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CardComponent.css */
.hover-card .hover-text {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
  }
  
  .hover-card:hover .hover-text {
    opacity: 1;
    max-height: 200px; /* Adjust to a value that fits your text content */
  }
  
  .hover-card {
    transition: all 0.3s ease-in-out;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/AboutHero/AboutHero.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,UAAU;IACV,aAAa;IACb,gBAAgB;IAChB,iEAAiE;EACnE;;EAEA;IACE,UAAU;IACV,iBAAiB,EAAE,kDAAkD;EACvE;;EAEA;IACE,gCAAgC;EAClC","sourcesContent":["/* CardComponent.css */\r\n.hover-card .hover-text {\r\n    opacity: 0;\r\n    max-height: 0;\r\n    overflow: hidden;\r\n    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;\r\n  }\r\n  \r\n  .hover-card:hover .hover-text {\r\n    opacity: 1;\r\n    max-height: 200px; /* Adjust to a value that fits your text content */\r\n  }\r\n  \r\n  .hover-card {\r\n    transition: all 0.3s ease-in-out;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
