function Slider() {
    return (
                  <div className="owl-item">
                        {/* <div className="background_image" style={{ backgroundImage: "url(images/home_slider.jpg)" }}></div> */}
                        <div className="home_container">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 py-3">
                                        <div className="home_content">
                                            <div className="home_subtitle">Welcome to Cosmo Health Medical Center</div>
                                            <div className="home_text">
                                                <p>At Comso Medical Center, we are committed to providing exceptional medical and aesthetic services that cater to your needs and aspirations. With six conveniently located branches across the United Arab Emirates, we ensure that superior care is always within your reach. Our expert team, state-of-the-art facilities, and personalized approach make us the preferred choice for those seeking excellence in Plastic Surgery, Dermatology & Anti-Aging, Hair Loss treatments, Medical Spa services, Weight Loss & Nutrition guidance, and Dentistry.</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <img src="images/hero.png" style={{width: "600px", objectFit: "cover"}}/>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
   
    )
}
export default Slider