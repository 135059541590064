import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

function Gallery() {
    return (
        <CardGroup style={{ marginBottom: '-50px' }}>
            <Card>
                <Card.Img variant="top" src="Gallery/01.jpg" />
            </Card>
            <Card>
                <Card.Img variant="top" src="Gallery/02.jpg" />
            </Card>
            <Card>
                <Card.Img variant="top" src="Gallery/09.jpg" />
            </Card>
            <Card>
                <Card.Img variant="top" src="Gallery/04.jpg" />
            </Card>
            <Card>
                <Card.Img variant="top" src="Gallery/11.jpg" />
            </Card>

        </CardGroup>
    )

}
export default Gallery