import "./ImageSlider.css"
import React, { useState } from 'react';

import Carousel from 'react-bootstrap/Carousel';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
function ImageSlider({ image }) {


    return (
        <>
            <Swiper
                breakpoints={{
                    // when window width is >= 640px
                    300: {
                        width: 300,
                        slidesPerView: 1,
                    },
                    // when window width is >= 768px
                    768: {
                        width: 768,
                        slidesPerView: 2,
                    },
                }}
                slidesPerView={4}
                
                spaceBetween={0}
                pagination={{
                    clickable: true,
                }}

                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                loop={true}
                loopAdditionalSlides={3}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <img src="beforeAfter/1.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src="beforeAfter/2.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src="beforeAfter/1.jpg" />

                </SwiperSlide>
                <SwiperSlide>
                    <img src="beforeAfter/2.jpg" />

                </SwiperSlide>
                <SwiperSlide>
                    <img src="beforeAfter/1.jpg" />

                </SwiperSlide>
                <SwiperSlide>
                    <img src="beforeAfter/2.jpg" />

                </SwiperSlide>
                <SwiperSlide>
                    <img src="beforeAfter/1.jpg" />

                </SwiperSlide>
                <SwiperSlide>
                    <img src="beforeAfter/2.jpg" />

                </SwiperSlide>
                <SwiperSlide>
                    <img src="beforeAfter/1.jpg" />

                </SwiperSlide>
                <SwiperSlide>
                    <img src="beforeAfter/2.jpg" />

                </SwiperSlide>
                <SwiperSlide>
                    <img src="beforeAfter/1.jpg" />

                </SwiperSlide>
                <SwiperSlide>
                    <img src="beforeAfter/1.jpg" />

                </SwiperSlide>
                <SwiperSlide>
                    <img src="beforeAfter/1.jpg" />

                </SwiperSlide>
                
            </Swiper>
        </>
    );
}
export default ImageSlider