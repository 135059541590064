import Carousel from 'react-bootstrap/Carousel';


function imageCarousel() {

    return (

        // const [sliderPosition, setSliderPosition] = useState('50%'); // Initial slider position

        // // Event handler for slider input change
        // const handleSliderChange = (e) => {
        //     const newPosition = `${e.target.value}%`; // Calculate new position based on input value
        //     setSliderPosition(newPosition); // Update state with new position
        // };
       {/* <div className="container" style={{ '--position': sliderPosition, marginBottom: "100px" }}>


                <div className="image-container" style={{ marginLeft: "auto", marginRight: "auto" }}>

                    <img
                        className="image-before slider-image"
                        src={image.urlBefore}
                        alt="color photo"
                    />
                    <img
                        className="image-after slider-image"
                        src={image.urlAfter}
                        alt="black and white"
                    />
                </div>
                <input
                    type="range"
                    min="0"
                    max="100"
                    value="50"
                    aria-label="Percentage of before photo shown"
                    className="slider"
                    onChange={handleSliderChange}
                />


                <div className="slider-line" aria-hidden="true"></div>
                <div className="slider-button" aria-hidden="true">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        viewBox="0 0 256 256"
                    >
                        <rect width="256" height="256" fill="none"></rect>
                        <line
                            x1="128"
                            y1="40"
                            x2="128"
                            y2="216"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                        ></line>
                        <line
                            x1="96"
                            y1="128"
                            x2="16"
                            y2="128"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                        ></line>
                        <polyline
                            points="48 160 16 128 48 96"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                        ></polyline>
                        <line
                            x1="160"
                            y1="128"
                            x2="240"
                            y2="128"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                        ></line>
                        <polyline
                            points="208 96 240 128 208 160"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                        ></polyline>
                    </svg>
                </div>
            </div> */}


//             *,
// *::after,
// *::before {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }

// iframe {
//   aspect-ratio: 16/9;
// }

// .video-container {
//   margin: 2rem;
//   max-height: 300px;
// }

// img {
//   display: block;
//   max-width: 100%;
// }


// .image-container {
//   max-width: 300px;
//   max-height: 90vh;
//   aspect-ratio: 1/1;
// }

// .slider-image {
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   object-position: left;
// }

// .image-before {
//   position: absolute;
//   inset: 0;
//   width: var(--position);
// }

// .slider {
//   position: absolute;
//   inset: 0;
//   cursor: pointer;
//   opacity: 0;
//   width: 100%;
//   height: 100%;
// }

// .slider:focus-visible ~ .slider-button {
//   outline: 5px solid black;
//   outline-offset: 3px;
// }

// .slider-line {
//   position: absolute;
//   inset: 0;
//   width: .2rem;
//   height: 100%;
//   background-color: #fff;
//   left: var(--position);
//   transform: translateX(-50%);
//   pointer-events: none;
// }

// .slider-button {
//   position: absolute;
//   background-color: #fff;
//   color: black;
//   padding: .5rem;
//   border-radius: 100vw;
//   display: grid;
//   place-items: center;
//   top: 50%;
//   left: var(--position);
//   transform: translate(-50%, -50%);
//   pointer-events: none;
//   box-shadow: 1px 1px 1px hsl(0, 50%, 2%, .5);
// }
    )

}
export default imageCarousel