import "./Counter.css"
import React, { useEffect } from 'react';
import { GrMapLocation } from "react-icons/gr";
import { FaSuitcaseMedical } from "react-icons/fa6";
import { FaUserDoctor } from "react-icons/fa6";
import { FaHandsHelping } from "react-icons/fa";
import { FaMapLocationDot } from "react-icons/fa6";

function Counter() {
    useEffect(() => {
        const counters = document.querySelectorAll('.counting');

        counters.forEach(counter => {
            const countTo = +counter.getAttribute('data-count');
            const duration = 5000;
            const start = Date.now();

            const animateCount = () => {
                const now = Date.now();
                const elapsed = now - start;
                const progress = Math.min(elapsed / duration, 1);
                const value = Math.floor(progress * countTo);

                counter.textContent = value;

                if (progress < 1) {
                    requestAnimationFrame(animateCount);
                } else {
                    counter.textContent = countTo;
                }
            };

            animateCount();
        });
    }, []);
    return (
        <section id="counter-stats" className="container" style={{marginBottom: "50px", marginTop: "20px"}}>
            <div className="container wow fadeInRight" data-wow-duration="1.4s">
                <div className="row justify-content-center" style={{alignItems: "center"}}>
                    <div className="col-lg-3 stats">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <FaMapLocationDot size={30} style={{ marginRight: "10px" }}  />
                            <div className="milestone_counter" data-count="10">+</div>
                            <div className="milestone_counter counting" data-count="6">0</div>
                        </div>
                        <h5 className="milestone_text">Locations</h5>
                    </div>

                    <div className="col-lg-3 stats">
                        <i className="fa fa-building" aria-hidden="true"></i>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <FaSuitcaseMedical style={{ marginRight: "10px" }}  size={30} />
                            <div className="milestone_counter" data-count="10">+</div>
                            <div className="milestone_counter counting" data-count="14">0</div>
                        </div>
                        <h5 className="milestone_text">Specialities</h5>
                    </div>

                    <div className="col-lg-3 stats">
                        <i className="fa fa-graduation-cap" aria-hidden="true"></i>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <FaUserDoctor style={{ marginRight: "10px" }}  size={30} />
                            <div className="milestone_counter" data-count="10">+</div>
                            <div className="milestone_counter counting" data-count="22">0</div>
                        </div>
                        <h5 className="milestone_text">Doctors</h5>
                    </div>

                    <div className="col-lg-3 stats">
                        <i className="fa fa-users" aria-hidden="true"></i>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <FaHandsHelping style={{ marginRight: "10px" }}  size={30} />
                            <div className="milestone_counter" data-count="10">+</div>
                            <div className="milestone_counter counting" data-count="10">0</div>
                            <div className="milestone_counter" data-count="10">K</div>
                        </div>
                        <h5 className="milestone_text">Treatments</h5>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Counter