import { FaMapMarkedAlt } from "react-icons/fa";
import { FaFilter } from "react-icons/fa";



function WhyUs() {
    return (
        <div className="why" >
            <div className="container">
                <div className="row row-eq-height">

                    {/* Left Column */}
                    <div className="col-lg-6 order-lg-2 order-2">
                        <div className="why_image_container">
                            <div className="why_image"><img src="images/why_1.jpg" alt="" /></div>
                        </div>
                    </div>

                    {/* Right Column */}
                    <div className="col-lg-6 order-lg-2 order-1" >
                        <div className="why_content">
                            {/* <div className="section_title_container">
                                <div className="section_title"><h2 style={{fontSize: "27px"}}>Why Choose Comso Health Medical Center?</h2></div>
                            </div> */}
                            <div className="why_text">
                                {/* <p>Odio ultrices ut. Etiam ac erat ut enim maximus accumsan vel ac nisl. Duis feugiat bibendum orci, non elementum urna vestibulum in. Nulla facilisi. Nulla egestas vel lacus sed interdum. Sed mollis, orci elementum eleifend tempor, nunc libero porttitor tellus, vel pharetra metus dolor.</p> */}
                            </div>
                            <div className="why_list">
                            <div className="section_title"><h2 style={{fontSize: "27px", marginBottom: "50px"}}>Why Choose Comso Health Medical Center?</h2></div>

                                <ul>
                                    {/* Reason 1 */}
                                    <li className="d-flex flex-row align-items-center justify-content-start">
                                        <div className="icon_container d-flex flex-column align-items-center justify-content-center">
                                            <div className="icon"><FaMapMarkedAlt size={30} color="white" />
                                            </div>
                                        </div>
                                        <div className="why_list_content">
                                            <div className="why_list_title">Multiple Locations:</div>
                                            <div className="why_list_text">With six branches across the UAE, exceptional care is never far away.</div>
                                        </div>
                                    </li>

                                    {/* Reason 2 */}
                                    <li className="d-flex flex-row align-items-center justify-content-start">
                                        <div className="icon_container d-flex flex-column align-items-center justify-content-center">
                                            <div className="icon"><img src="images/icon_2.svg" alt="https://www.flaticon.com/authors/prosymbols" /></div>
                                        </div>
                                        <div className="why_list_content">
                                            <div className="why_list_title">Expert Team:</div>
                                            <div className="why_list_text">Our highly qualified professionals are dedicated to your health and beauty.</div>
                                        </div>
                                    </li>

                                    {/* Reason 3 */}
                                    <li className="d-flex flex-row align-items-center justify-content-start">
                                        <div className="icon_container d-flex flex-column align-items-center justify-content-center">
                                            <div className="icon"><FaFilter size={30} color="white" /></div>
                                        </div>
                                        <div className="why_list_content">
                                            <div className="why_list_title">Personalized Care:</div>
                                            <div className="why_list_text">We tailor our services to meet your unique needs and aspirations.</div>
                                        </div>
                                    </li>
                                    <li className="d-flex flex-row align-items-center justify-content-start">
                                        <div className="icon_container d-flex flex-column align-items-center justify-content-center">
                                            <div className="icon"><img src="images/icon_3.svg" alt="https://www.flaticon.com/authors/prosymbols" /></div>
                                        </div>
                                        <div className="why_list_content">
                                            <div className="why_list_title">Advanced Technology:</div>
                                            <div className="why_list_text">Utilizing the latest medical advancements for superior results.</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}
export default WhyUs