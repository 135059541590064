import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

function DentistryServices({ onComponentChange }) {
    return (
        <Container>
            <Row style={{ marginTop: "150px" }}>
                <Col lg={4}>
                    <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', 'Orthodontic Braces')}>
                        <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                            <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                                <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/braces.png" /></div>
                            </div>
                            {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                            <Card.Body style={{ textAlign: "center" }}>
                                <Card.Title style={{ color: "black", fontSize: "15px" }}>Orthodontic Braces</Card.Title>

                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col lg={4}>
                    <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', 'Dental Implants')}>
                        <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                            <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                                <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/implant.png" /></div>
                            </div>
                            {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                            <Card.Body style={{ textAlign: "center" }}>
                                <Card.Title style={{ color: "black", fontSize: "15px" }}>Dental Implants</Card.Title>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col lg={4}>
                    <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', 'Sedation Dentistry')}>
                        <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                            <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                                <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/anesthesia.png" /></div>
                            </div>
                            {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                            <Card.Body style={{ textAlign: "center" }}>
                                <Card.Title style={{ color: "black", fontSize: "15px" }} >Sedation Dentistry</Card.Title>

                            </Card.Body>
                        </Card>
                    </Link>
                </Col>

                <Col lg={4}>
                    <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', '3D Guided Dental Implant Surgery')}>

                        <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                            <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                                <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/dental-implant.png" /></div>
                            </div>
                            {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                            <Card.Body style={{ textAlign: "center" }}>
                                <Card.Title style={{ color: "black", fontSize: "15px" }}>3D Guided Implant Surgery</Card.Title>

                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col lg={4}>
                    <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', 'Invisalign')}>

                        <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                            <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                                <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/teeth.png" /></div>
                            </div>
                            {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                            <Card.Body style={{ textAlign: "center" }}>
                                <Card.Title style={{ color: "black", fontSize: "15px" }}>Invisalign</Card.Title>

                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col lg={4}>
                    <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', 'Invisalign First')}>

                        <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                            <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                                <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/teeth.png" /></div>
                            </div>
                            {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                            <Card.Body style={{ textAlign: "center" }}>
                                <Card.Title style={{ color: "black", fontSize: "15px" }}>Invisalign First</Card.Title>

                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col lg={4}>
                    <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', 'Same Day Implants')}>

                        <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                            <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                                <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/SameDayImplant.png" /></div>
                            </div>
                            {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                            <Card.Body style={{ textAlign: "center" }}>
                                <Card.Title style={{ color: "black", fontSize: "15px" }}>Same Day Implant</Card.Title>

                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col lg={4}>
                    <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', 'All on 4 Treatment')}>

                        <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                            <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                                <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/implant.png" /></div>
                            </div>
                            {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                            <Card.Body style={{ textAlign: "center" }}>
                                <Card.Title style={{ color: "black", fontSize: "15px" }}>All-on-4 Implants</Card.Title>

                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col lg={4}>
                <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', 'Invisalign Teen')}>

                    <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                        <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                            <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/teeth.png" /></div>
                        </div>
                        {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                        <Card.Body style={{ textAlign: "center" }}>
                            <Card.Title style={{ color: "black", fontSize: "15px" }}>Invisalign Teen</Card.Title>

                        </Card.Body>
                    </Card>
                    </Link>
                </Col>
                <Col lg={4}>
                <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', 'Cosmetic Dentistry')}>

                    <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                        <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                            <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/healthy-tooth.png" /></div>
                        </div>
                        {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                        <Card.Body style={{ textAlign: "center" }}>
                            <Card.Title style={{ color: "black", fontSize: "15px" }}>Cosmetic Dentistry</Card.Title>

                        </Card.Body>
                    </Card>
                    </Link>
                </Col>
                <Col lg={4}>
                <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', 'TMJ Treatment')}>

                    <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                        <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                            <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/dentist.png" /></div>
                        </div>
                        {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                        <Card.Body style={{ textAlign: "center" }}>
                            <Card.Title style={{ color: "black", fontSize: "15px" }}>TMJ Treatment</Card.Title>

                        </Card.Body>
                    </Card>
                    </Link>
                </Col>
                <Col lg={4}>
                <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', 'Porcelain Veneers')}>

                    <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                        <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                            <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/crown.png" /></div>
                        </div>
                        {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                        <Card.Body style={{ textAlign: "center" }}>
                            <Card.Title style={{ color: "black", fontSize: "15px" }}>Porcelain Veneers</Card.Title>

                        </Card.Body>
                    </Card>
                    </Link>
                </Col>
                <Col lg={4}>
                <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', 'Cosmetic Dentistry')}>

                    <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                        <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                            <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/hygiene.png" /></div>
                        </div>
                        {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                        <Card.Body style={{ textAlign: "center" }}>
                            <Card.Title style={{ color: "black", fontSize: "15px" }}>Teeth Whitening</Card.Title>

                        </Card.Body>
                    </Card>
                    </Link>
                </Col>
                <Col lg={4}>
                <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', 'Dental Crowns and Dental Bridges')}>

                    <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                        <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                            <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/bridge.png" /></div>
                        </div>
                        {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                        <Card.Body style={{ textAlign: "center" }}>
                            <Card.Title style={{ color: "black", fontSize: "15px" }}>Dental Bridges and Crowns</Card.Title>

                        </Card.Body>
                    </Card>
                    </Link>
                </Col>
                <Col lg={4}>
                <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', 'Root Canal Treatment')}>

                    <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                        <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                            <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/root-canal.png" /></div>
                        </div>
                        {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                        <Card.Body style={{ textAlign: "center" }}>
                            <Card.Title style={{ color: "black", fontSize: "15px" }}>Root Canal Treatment</Card.Title>

                        </Card.Body>
                    </Card>
                    </Link>
                </Col>
                <Col lg={4}>
                <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', 'Laser Dentistry')}>

                    <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                        <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                            <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/laser-beam.png" /></div>
                        </div>
                        {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                        <Card.Body style={{ textAlign: "center" }}>
                            <Card.Title style={{ color: "black", fontSize: "15px" }}>Laser Dentistry</Card.Title>

                        </Card.Body>
                    </Card>
                    </Link>
                </Col>
                <Col lg={4}>
                <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', 'Sleep Apnoea and Snoring Treatment')}>

                    <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                        <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                            <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/toothache.png" /></div>
                        </div>
                        {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                        <Card.Body style={{ textAlign: "center" }}>
                            <Card.Title style={{ color: "black", fontSize: "15px" }}>Sleep Apnoea and Snoring</Card.Title>

                        </Card.Body>
                    </Card>
                    </Link>
                </Col>
                <Col lg={4}>
                <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', 'Gum Disease Treatment')}>

                    <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                        <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                            <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/infection.png" /></div>
                        </div>
                        {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                        <Card.Body style={{ textAlign: "center" }}>
                            <Card.Title style={{ color: "black", fontSize: "15px" }}>Gum Disease Treatment</Card.Title>

                        </Card.Body>
                    </Card>
                    </Link>
                </Col>
                <Col lg={4}>
                <Link to="/dentistryInfo" onClick={() => onComponentChange('dentistry', '', 'Routine Dental Checkup')}>

                    <Card style={{ width: '15rem', borderStyle: "none", borderColor: "black" }}>
                        <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto" style={{ marginTop: "25px", width: "60px", height: "60px" }}>
                            <div className="icon" style={{ width: "35px", height: "35px" }}><img src="/icons/dentist-chair.png" /></div>
                        </div>
                        {/* <Card.Img variant="top" src="/icons/dentist-chair.png" style={{width: "100px"}}/> */}
                        <Card.Body style={{ textAlign: "center" }}>
                            <Card.Title style={{ color: "black", fontSize: "15px" }}>Routine Dental Checkups</Card.Title>

                        </Card.Body>
                    </Card>
                    </Link>
                </Col>
            </Row>
        </Container>
    )

}
export default DentistryServices