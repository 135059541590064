import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import { FaSpa } from "react-icons/fa";
import { FaPrescriptionBottleAlt } from "react-icons/fa";
import { FaRegSun } from "react-icons/fa";
import { FaWeight } from "react-icons/fa";
import { FaTooth } from "react-icons/fa";
import { FaMapMarkedAlt } from "react-icons/fa";
import { FaFilter } from "react-icons/fa";
import React, { useState, useEffect } from 'react';

function DentistryInfo({ activeDentistry }) {

    const [activeInfo, setActiveInfo] = useState(null);


    const services = [
        {
            title: "Orthodontic Braces",
            intro: "When your teeth are properly aligned, they’re at a lower risk of tooth decay, , enamel wear, and . Unfortunately, not all of us are lucky enough to have naturally straight teeth. Our variety of dental braces can enhance your smile’s function and aesthetics, transforming it into one of your favorite accessories! Depending on your age, personal preferences, and the severity of your tooth misalignment, our orthodontics speciality has various types of orthodontic braces to choose from. Our UAE orthodontists will walk you through the most appropriate orthodontic treatment for your smile — whether that’s Invisalign, , or something else — so that you can play a role in selecting the best one for your lifestyle.",
            listName: "Types of Orthodontic Treatment Options Available",
            list: [
                {
                    title: "Invisalign",
                    desc: "Invisalign braces are one of the most popular types of orthodontic braces for adults. These clear, removable aligners move your teeth without fixed brackets and wires. Enjoy discreet, comfortable trays that straighten your teeth from every angle; simply change them out as directed and wear them as prescribed. Invisalign is easy to fit into your busy schedule."
                },
                {
                    title: "Invisalign Teen",
                    desc: "Even though Invisalign was designed with adults in mind, there’s a unique line of invisible braces available just for teens. Invisalign Teen is for on-the-go, responsible teenagers who have no problem keeping up with their invisible orthodontic trays."
                },
                {
                    title: "Metal and Ceramic Braces",
                    desc: "Traditional orthodontics are the most popular types of braces we offer in UAE. Available in standard hypoallergenic stainless steel or tooth-colored ceramic, you can choose the one that fits your age and lifestyle. Conventional braces efficiently straighten minor to more severe tooth misalignment, addressing crowding, gaps, and more."
                },
                {
                    title: "Invisible Lingual Braces",
                    desc: "Incognito orthodontic braces are similar to conventional metal orthodontics, except they’re hidden behind your teeth (on the lingual surfaces.) As such, they’re “invisible” to the people around you. Lingual braces are effective for a variety of tooth misalignment needs, but they do take a little getting used to. Each of the brackets is custom-fitted to your unique smile for optimal comfort and efficiency."
                },
                {
                    title: "Damon Braces",
                    desc: "Self-ligating braces are similar to metal and ceramic braces, but with less friction and possibility of tension. Their unique trap-door design allows your orthodontic arch wire to glide between the teeth, reducing pressure on specific areas of your mouth. In turn, the overall orthodontic treatment process is a bit more comfortable for certain patients. Tooth-colored ceramic is the most popular."
                },
                {
                    title: "Myobrace",
                    desc: "Early intervention orthodontics and growth modification reduces the complexity of your child’s orthodontic treatment needs as they develop. Issues such as crowding, shape of their jaw, and tooth misalignment can be intercepted as their mouths grow and mature. By encouraging a healthier growth pattern, serious jaw or tooth complications can be prevented."
                }
            ]

        },
        {
            title: "Invisalign First",
            intro: "Every child deserves healthy teeth and a pleasing smile, that starts with regular dental care. Early dental check-up can lead to the discovery of any malocclusions such as misalignment, spacing, and bite issues before bones have stopped growing. Crooked, crowded misaligned teeth can be a source of physical discomfort for young children and can also be a hindrance in their ability to speak, chew and bite. Invisalign First was created for young children between the ages of 6 and 10 years old, who have a mixture of baby and adult teeth. Also known as Phase 1 treatment, this advanced system uses clear aligners to fix orthodontic issues before they progress into more complex challenges later in adolescence.",
            listName: "Advantages of Invisalign First over Braces",
            list: [
                {
                    desc: "Expands the jaw and creates space for the erupting permanent teeth"
                },
                {
                    desc: "It uses SmartTrack material making it comfortable compared to standard braces with wires"
                },
                {
                    desc: "Improves minor speech problems in kids"
                },
                {
                    desc: "Fewer orthodontic appointments compared to traditional braces, making it flexible for busy parents"
                },
                {
                    desc: "No diet restrictions or activity limitations"
                },
                {
                    desc: "Protects children from oral habits such as tongue thrusting and thumb sucking"
                },
                {
                    desc: "Rectifies various teeth misalignments in children, including crowding, spacing, narrow dental arches, and tooth malposition."
                },
                {
                    desc: "Provides faster results in about six months and may avoid the need for permanent tooth extractions during Phase Two treatment"
                }
            ],

        },
        {
            title: "Same Day Implants",
            intro: "The same day implant treatment starts with determining your suitability for the procedure. On the day of your scheduled appointment, Dental implants will be placed in your jaw, strategically taking advantage of the existing bone structure to eliminate the need for complicated bone grafts. After loading the implants to the jawbone, the teeth restoration (dental bridge) will then be attached to the implants. Your smile will instantly look more beautiful, and you will be able to use the implants and teeth replacements right after the same day teeth treatment.",
            listName: "Why Choose Us",
            list: [
                {
                    title: "40+ Years of Shared Experience",
                },
                {
                    title: "6000+ Implants Placed",
                },
                {
                    title: "Highest Success rate more than 98%",
                },
                {
                    title: "Accredited Specialist Implantologists",
                },
                {
                    title: "Only Premium Dental Implants Used",
                },
                {
                    title: "Flexible Payment Options",
                },
                {
                    title: "10 Year Warranty",
                },
                {
                    title: "Sedation for Nervous Patients",
                },
            ]
        },
        {
            title: "3D Guided Dental Implant Surgery",
            intro: "Dental implant treatment is one of the least invasive ways to replace your missing teeth. Since implants rest inside of the bone (similar to an anatomical tooth) they are completely non-invasive to surrounding tooth structure.When you incorporate our onsite CBCT (in dentistry) and 3D imaging equipment, we can map out your care for a precise and predictable dental implant experience.",
            listName: "What Makes Us Unique",
            list:
                [
                    {
                        desc: "Award winning Center of excellence"
                    },
                    {
                        desc: "Team of highly experienced dental implantologists"
                    },
                    {
                        desc: "Latest technologies for highest success rates"
                    },
                ]
        },
        {
            title: "Dental Implants",
            intro: "Dental implants are the gold standard for modern-day tooth replacement. Since they fuse with your surrounding bone, implants essentially become a permanent part of your new smile. Best of all, dental implants can work together to support one or several teeth at a time. They’re the next best thing to having natural teeth all over again.",
            listName: "Types of Dental Implants",
            list: [
                {
                    title: "All-on-6 Dental Implants",
                    desc: "All the teeth on 6 implants commonly called All-on-Six dental implants provide patients a full arch prosthesis that is installed for a lifetime. All-on-Six dental implants are a better choice when it comes to extensive restoration of the tooth. It is used for patients with more tooth loss and who are discontent with their looks. "
                },
                {
                    title: "All-on-4 Dental Implants",
                    desc: "All the teeth on 4 implants commonly called as All-on-Four dental implants provide patients a full arch prosthesis that is installed permanently.It is used for patients with full dentures who have all their teeth extracted and are very unhappy with them, particularly lower dentures."
                },
                {
                    title: "Same-Day Dental Implants",
                    desc: "Depending on which teeth are missing and how you plan to replace them, it may be possible to place and restore same-day dental implants to minimize the time you spend waiting on your smile reconstruction."
                },
                {
                    title: "Single Tooth Implant",
                    desc: "A single dental implant is ideal when one tooth is missing and you want to replace it for esthetics, comfort, and function. Single implants require one dental crown that connects to the implant screw."
                },
            ]

        },
        {
            title: "Invisalign",
            intro: "Invisalign(Invisible Aligners) is a type of orthodontic treatment where clear aligners are used, instead of fixed brackets and wire braces; it provides an invisible orthodontic alternative to traditional metal appliances.  With Invisalign clear aligners, you simply wear your transparent trays as directed. Each set of Invisalign aligners moves your teeth into the desired position. You change the clear trays out every one to two weeks to continue your tooth movement.  The entire Invisalign system is founded on virtual 3D technology that digitally “moves” your teeth within a special software program. From there, a series of trays are printed to gently and gradually bring your teeth into an overall alignment.",
            listName: "Advantages of Invisalign Clear Aligners",
            list: [
                {
                    desc: "Virtually clear aligners help you feel at ease while socializing"
                },
                {
                    desc: "No diet restrictions as the clear aligners can be removed while having food"
                },
                {
                    desc: "Fewer, shorter trips to the dental office, as braces tightening is not required"
                },
                {
                    desc: "Comfortable and suitable for all malalignments even the complicated ones"
                },
                {
                    desc: "Easier home care as it is removable and easy to clean"
                },
                {
                    desc: "3D Smile preview using the latest iTero scan technology"
                }
            ]


        },
        {
            title: "Pediatric Dentist",
            intro: "Your child’s oral health can impact their smile for the rest of their life. Great habits, good oral hygiene, and access to early preventative care with a children’s dentist can get your child off on the right start.  A pedodontist is a licensed pediatric (kids’) dental specialist. These dental experts have an additional 3 years of formal dental training after four years of dental school. A licensed kids’ dentist will specialize in everything from behavior management to treating the anatomical differences found in a child’s rapidly growing mouth. At Dr. Joy Dental Clinic, our UAE team consists of pediatric dentists as well as other specialists — such as orthodontists — to provide the comprehensive care that your developing child needs.",
            listName: "Pediatric Dentistry Treatments",
            list: [
                {
                    title: "Exams",
                    desc: "Routine evaluations monitor your child’s oral development and allow us to pinpoint concerns when they’re smaller and easier to address. We recommend an exam at least twice a year beginning at age 1. "
                },
                {
                    title: "Dental Fillings ",
                    desc: "Primary (baby) teeth can decay at accelerated rates, allowing the cavity to spread into the nerve or adjacent teeth. Our conservative white fillings repair the area of damage and help stop the spread of infection. Composite fillings are both mercury-free"
                },
                {
                    title: "Orthodontic Evaluations",
                    desc: "We assess your child’s oral development, jaw anatomy, and eruption patterns to pinpoint concerns that might develop into problematic issues once they get older."
                },
                {
                    title: "Crowns",
                    desc: " Protective crowns (sometimes called “caps”) preserve compromised, broken, or badly decayed teeth and prevent premature tooth loss. We provide stainless steel crowns and tooth colored zirconia crowns for our Pediatric patients."
                },
                {
                    title: "Dental Cleanings",
                    desc: "Preventative cleanings every six months keep your child’s smile fresh and clean. Our hygienists will educate your child on ways to care for their teeth and gums between checkups."
                },
                {
                    title: "Pulpotomies",
                    desc: "An abscessed tooth can be extremely painful and a risk to your child’s health. Gentle pulpotomies combined with crowns can preserve your child’s tooth until it’s ready to be replaced with the permanent one. "
                },
                {
                    title: "Extractions and Space Maintainers",
                    desc: "Non-restorable teeth can be gently extracted and a special appliance placed to prevent crowding or blockage to the underlying adult tooth."
                },
                {
                    title: "Early Intervention and Growth Modification",
                    desc: "Specialized orthodontic techniques promote healthy oral development, limiting your child’s risk of complex needs later on."
                },
                {
                    title: "Protective Sealants",
                    desc: "Dental sealants are preventative covers that block out cavity-causing bacteria in the deep grooves on back teeth. A sealant only takes a couple of minutes to apply; we recommend them on all permanent (adult) molars, which erupt around the ages of 6 and 12, respectively."
                },
            ]
        },
        {
            title: 'Sedation Dentistry',
            intro: "Sedation dentistry is a wonderful way to catch up on all of your dental care needs in a calm, safe environment. With dental sedation, you can easily combine treatments into fewer appointments while we provide a relaxing atmosphere to minimize any (dentist) anxiety or phobia related to oral care. Not only are we a licensed sedation dentistry provider, but each of our UAE locations is also designed for maximum comfort. From The Wand painless anesthesia and warm blankets to laser drill-free filings and televisions in the operatories, chances are you’ve never experienced dentistry this relaxing.",
            listName: "Types of Sedation Dentistry",
            list: [
                {
                    title: "Oral Sedation",
                    desc: "A type of conscious sedation, this method uses oral sedation medication taken just prior to your planned procedure and lasts for a few hours. Although you’ll feel extremely sleepy and may not even remember anything about the appointment, you’ll still be partially alert and able to answer basic questions. You might want this sedation for tooth extraction, longer restorative needs, or if you experience dental anxiety. "
                },
                {
                    title: "Nitrous Oxide (Happy Gas)",
                    desc: "Also called “happy gas”, nitrous oxide is one of the most common types of sedation dentistry services that we offer. Since nitrous oxide provides an extremely light conscious sedation, it allows you to relax while still being completely aware of everything going on around you. This “laughing gas” is fast acting and quickly reversible, with no lingering drowsiness; you can get back to your normal routine in no time."
                },
                {
                    title: "Pediatric Dentistry Sedation",
                    desc: "Occasionally children will require medically necessary dental care that they cannot sit through without sedation. If happy gas is not quite strong enough, our Pediatric Specialists can treat your children under General Anesthesia under the supervision of an Anesthesiologist in a hospital set up in UAE. We have tie ups with many reputed hospitals to provide this service.  Pediatric sedation dentistry allows your child to receive the care necessary in a painless, anxiety-free appointment."
                },
            ]
        },
        {
            title: "All on 4 Treatment",
            intro: "All the teeth on 4 implants commonly called as all on 4 dental implants provide patients a full arch prosthesis that is installed permanently. It is used for patients with full dentures who have all their teeth extracted and are very unhappy with them, particularly lower dentures. Many patients are extremely uncomfortable and lose a lot of confidence because of missing teeth. All on 4 Dental Implants are the considered to be the best alternative to dentures or deteriorated teeth.",
            listName: "What is the difference between All on 4 & conventional dental implants?",
            list: [
                {
                    "title": "Minimal Implant Placement",
                    "desc": "All-on-4 Dental Implants utilize only four implants per arch, whereas traditional implants may require between 5 to 8 implants per arch. This reduction in the number of implants makes the procedure less invasive and more cost-effective."
                },
                {
                    "title": "Shorter Treatment and Recovery Time",
                    "desc": "With fewer implants to place, All-on-4 procedures typically require less time in the dental chair and shorter recovery periods compared to traditional implant treatments. This means patients can enjoy their new smile sooner and with less discomfort."
                },
                {
                    "title": "Cost-Effectiveness",
                    "desc": "Since All-on-4 implants require fewer implants and less extensive surgery, they tend to be more affordable than traditional implant procedures. This makes them a more accessible option for patients seeking a complete new smile."
                },
                {
                    "title": "Reduced Need for Bone Grafting",
                    "desc": "Traditional implants often require bone grafting procedures to build up bone volume in the jaw, especially if there has been bone loss. However, the strategic placement of All-on-4 implants utilizes the existing bone volume more effectively, reducing the need for additional bone grafting surgeries. This not only saves time but also minimizes discomfort and risk associated with additional procedures."
                },
                {
                    "title": "Sufficient Support from Existing Bone",
                    "desc": "The strategic arrangement of All-on-4 implants provides sufficient support for the new teeth without relying on extensive bone grafting. This simplifies the treatment process and ensures that patients can receive their new smile with confidence in the stability and longevity of the implants."
                }
            ]
        },
        {
            title: "Cosmetic Dentistry",
            intro: "Our state-of-the-art facility provides a Digital Smile Design experience, where you can virtually “try on” cosmetic dentistry treatments like Porcelain Veneers or professional teeth whitening. With a few photographs and scans of your smile — and a couple of clicks of a button — your virtual makeover shows you what’s possible. Think of it like a digital makeover or cosmetic smile preview before the treatment starts. With your digital smile design, you can compare and contrast different types of aesthetic dental treatment. Take the digital photos with you to get input from your friends or family if you prefer. That way you feel completely confident about your aesthetic dentistry investment.",
            listName: "The Advantage of Investing in Yourself",
            list: [
                {
                    "title": "Minimal Implant Placement",
                    "desc": "All-on-4 Dental Implants utilize only four implants per arch, streamlining the procedure and minimizing invasiveness. Traditional implants, in contrast, may necessitate placing between 5 to 8 implants per arch, resulting in a more extensive and costly process."
                },
                {
                    "title": "Shorter Treatment and Recovery Time",
                    "desc": "With fewer implants required, All-on-4 procedures offer shorter treatment sessions and quicker recovery periods. This means patients can embrace their rejuvenated smile sooner, experiencing less discomfort and interruption to their daily lives compared to traditional implant treatments."
                },
                {
                    "title": "Cost-Effectiveness",
                    "desc": "Opting for All-on-4 implants not only enhances your smile but also makes financial sense. By requiring fewer implants and less extensive surgery, All-on-4 procedures are more affordable than traditional implant treatments. This investment in your smile translates to improved confidence and quality of life."
                },
                {
                    "title": "Reduced Need for Bone Grafting",
                    "desc": "Unlike traditional implants, which often necessitate bone grafting procedures to augment bone volume, All-on-4 implants leverage existing bone more effectively. This strategic placement minimizes the need for additional surgeries, saving time and reducing discomfort while ensuring long-term stability."
                },
                {
                    "title": "Sufficient Support from Existing Bone",
                    "desc": "The innovative arrangement of All-on-4 implants provides ample support for new teeth without relying on extensive bone grafting. This not only simplifies the treatment process but also instills confidence in the longevity and stability of the implants, enabling patients to embrace their new smile with ease and assurance."
                }
            ]
        },
        {
            title: "Porcelain Veneers",
            intro: "Ultra-thin porcelain dental veneers are crafted from durable, attractive shades of porcelain. They’re precisely shaped, shaded, and bonded to the front of the teeth in your “smile zone” (usually 6-8 at a time.) Once affixed into place, your smile looks dramatically different, but the natural teeth are just hidden behind your porcelain dental veneers. Porcelain veneers are ideal for someone who has healthy teeth and gums but wants to enhance their smile. You could say they are the most advanced and attractive type of cosmetic dentistry treatment worldwide. Veneer teeth can be used on their own, or in combination with other types of cosmetic dentistry treatment.",
            listName: "The Porcelain Veneers Process",
            list: [
                {
                    title: "Digital Smile Design",
                    desc: 'First, we provide you with a digital preview of what your smile could look like with dental veneers. Or, we create a model of your bite and have the lab work up what’s called a “wax up”, which allows you to see the proposed veneers before the permanent version is designed. '
                },
                {
                    title: 'The “Prep” Visit',
                    desc: "Once you’re ready to move forward with your treatment, we schedule a visit to prep your teeth and take a series of scans or impressions. Since an extremely thin layer of enamel is buffed away, we’ll place temporary veneers to reduce sensitivity while your permanent ones are being designed. "
                },
                {
                    title: "Final Placement of Your Veneers",
                    desc: "Our high-quality porcelain veneers in UAE are handcrafted by our master ceramist in our in-office dental lab, from the most attractive, durable materials. Once veneers ready, we have you return to our clinic to remove the temporaries and permanently bond the porcelain veneers into place. The moment you look into the mirror, you’ll have a brand new smile shining back at you."
                }
            ]
        },
        {
            title: "Dental Crowns and Dental Bridges",
            intro: "A full-coverage dental crown is a protective restoration that completely covers the visible tooth above your gumlines. Some people refer to crowns as “caps” because of how they mimic a hat or helmet that slips over them. Crowns are recommended in instances where a tooth cannot structurally withstand normal, everyday wear.",
            listName: "Types of Dental Crowns",
            list: [
                {
                    title: "3D CAD/CAM (Same-Day) Dental Crowns",
                    desc: 'Dr. Joy Dental Clinics has our own in-house dental lab that uses the latest technology to provide single-visit same-day crowns in addition to hand-designed ceramic dental crowns. The type of dental crown you choose will depend on your timeline, whether you’re local or visiting internationally, and the overall aesthetic design you’re looking for.'
                },
                {
                    title: 'Ceramic Dental Bridge (for Missing Tooth)',
                    desc: "Dental bridges are used to fill in the “teeth gap” created by a missing tooth. A fixed dental bridge almost looks like 3-4 crowns in a row, but only the ones on either end will fit over a tooth or dental implant. The pontic crowns suspended between them span the length of your teeth gap, filling in the open space. Bridges are used for replacing a missing tooth or pair of teeth. They require a healthy natural tooth on either end for support. But in the instance of 3-4 missing teeth, the ceramic bridge can be supported by a pair of dental implants. "
                }
            ]
        },
        {
            title: "Invisalign Teen",
            intro: 'Invisalign Teen is similar to traditional Invisalign treatment, but with a few built-in additional perks. For instance, if your busy student accidentally misplaces an aligner at school or during an extracurricular activity, their treatment includes a set number of complimentary replacement aligners to get things back on track.  The unique design of Invisalign Teen also includes a special wear indicator. This small, color-coded marker shows your teen when it’s time to progress to the next set of clear aligners in their treatment series. That way it’s not as easy to forget to change them out as prescribed (which is great for families with busy schedules!). A lot of teens prefer  over fixed metal or clear braces. For teens, oral hygiene may be especially challenging. Since Invisalign is easy to clean and care for, it has a significantly lower impact on normal daily activities.  If your teen is self-conscious about the way their smile looks, Invisalign is a comfortable way to help them feel more confident. As their smile is brought into a better alignment, the discreet design makes it so that it’s almost impossible for other people to know they’re wearing braces to begin with.',
            listName: "Advantages of Invisalign Teen",
            list: [
                {
                    "title": "A more comfortable orthodontic option"
                },
                {
                    "title": "Discreet, so no one notices them"
                },
                {
                    "title": "Easier to clean and care for"
                },
                {
                    "title": "Fewer trips to the dentist’s office"
                },
                {
                    "title": "Designed specifically for teens"
                },
                {
                    "title": "Won’t interfere with instruments or sports"
                }
            ]

        },
        {
            title: "TMJ Treatment",
            intro: 'TMJ Disorder/TMD is a complex of symptoms and conditions indicating an impairment of the normal functioning of the temporomandibular joints, ligaments, and muscles of the head and neck. Sometimes this condition can proceed without symptoms.Not all TMJ pain is caused by temporomandibular joint dislocation or deterioration. In many cases, TMJ symptoms can overlap with other injuries. However, through some investigative work and expert examination, our TMJ Specialists can determine if TMD treatment is necessary.',
            listName: "Symptoms associated with TMJ dysfunction",
            list: [
                {
                    "title": "Ear pain"
                },
                {
                    "title": "Pain on one side of your jaw"
                },
                {
                    "title": "Popping or clicking noise"
                },
                {
                    "title": "Difficulty opening your mouth"
                },
                {
                    "title": "Joint pain"
                },
                {
                    "title": "Flat, worn teeth"
                },
                {
                    "title": "Pain when chewing"
                },
                {
                    "title": "Crooked or misaligned teeth"
                },
                {
                    "title": "Symptoms associated with TMJ dysfunction"
                },
                {
                    "title": "Headaches"
                },
                {
                    "title": "Dizziness"
                },
                {
                    "title": "Ear congestion"
                },
                {
                    "title": "Neck pain"
                },
                {
                    "title": "Poor posture"
                },
                {
                    "title": "Sleep problems, snoring, apnea"
                },
                {
                    "title": "Swallowing disorder"
                },
                {
                    "title": "Noise and ringing in the ears (tinnitus)"
                }
            ]
        },
        {
            title: "Teeth Whitening",
            intro: "Feeling confident about the way your smile looks is priceless. But if your teeth are dark and discolored, it can detract from your self-confidence. A professional, effective teeth whitening treatment can take your smile to the next level, providing a youthful, healthy-looking glow. Teeth whitening is one of the first things we recommend when you’re considering a smile makeover. Many times, teeth brightening is a perfect stand-alone aesthetic service. It also jump-starts the makeover process, allowing our cosmetic dentists in UAE to match updated dental work against. ",
            listName: "Types of Teeth Whitening Treatments",
            list: [
                {
                    title: "Professional Take-Home Whitening Kits",
                    desc: "Our custom whitening kits include personalized trays and professional-grade whitening gel. Wear your trays once a day (or overnight) and you’ll start to see results within just a few applications. Full results are visible in about 10-14 days. Keep your trays on hand to touch up whenever you need them and your smile will stay bright for years to come."
                },
                {
                    title: "Zoom! Same Day Teeth Whitening",
                    desc: "Want maximum whitening results without the “hassle” of wearing trays at home? If you’re always on the go or have an important event coming up, same-day Zoom! teeth whitening can give you several shades of brighter teeth in just one appointment. The typical visit takes about an hour and a half to complete and gives you the same level of results that you would see from a professional take-home whitening system. The only major difference is that it saves you time."
                }
            ]
        },
        {
            title: "Root Canal Treatment",
            intro: "If you have a dying tooth or infected dental nerve, endodontic therapy (root canal treatment) is typically the only way to preserve your tooth. Instead of removing and replacing it, you can extend the function of your tooth by restoring it from the inside. You might almost think about root canal treatment like a filling that extends deeper into the tooth. It fills the empty nerve chambers down the length of the roots, as opposed to just an opening caused by decay. ",
            listName: "Symptoms of Dying, Abscessed Teeth",
            list: [
                {
                    "title": "A dental abscess"
                },
                {
                    "title": "Decay that extends into the nerve"
                },
                {
                    "title": "Past trauma, resulting in tooth death"
                },
                {
                    "title": "Chronic dental pain"
                },
                {
                    "title": "Deep cracks or fractured roots"
                },
                {
                    "title": "Significant discoloration, due to tooth death"
                }
            ]

        },
        {
            title: "Laser Dentistry",
            intro: "Utilizing the latest in dental laser technology, our UAE dentists and dental specialists are able to provide minimally invasive, advanced care techniques for patients of all backgrounds. Laser assisted dentistry is extremely safe and effective. With the use of medical-grade dental lasers, we’re able to treat both hard and soft tissues, offering a wide variety of therapies that boast better results and faster recovery times.",
            listName: "Types of Laser Dental Treatment",
            list: [
                {
                    title: "LANAP for Gum Disease",
                    desc: "Incorporating laser gum surgery into your periodontal therapy plan (deep cleanings) can eradicate infection-causing bacterial while also promoting a healthy bond between treated gum tissues and the roots of teeth. LANAP significantly shortens the depth of periodontal pockets, increasing your chances of tooth retention and gum disease reversal."
                },
                {
                    title: "Aesthetic Gum Surgery",
                    desc: 'Do you have a “gummy” smile? Excessive gingival tissues can make your teeth look short and uneven. Laser gum surgery allows for quick, accurate, comfortable gum recontouring without the need for sutures or lengthy healing times. Results are instant. And since lasers seal your gum tissues as the area is treated, you’ll experience significantly less bleeding or swelling than traditional gum surgery.'
                },
                {
                    title: "Gum Depigmentation",
                    desc: "Biolase soft tissue laser treatment allows us to treat gum discoloration for cosmetic purposes. If your gums are splotchy colored, dark, or you feel self-conscious about their pigmentation (as it relates to the rest of your smile) we can apply the laser to your gum tissue at those locations to significantly lighten the gingiva to a healthier-looking hue."
                },
                {
                    title: "Dental Implant Placement",
                    desc: "Although dental implant treatment is minimally invasive, we still have to make a small opening in your gums to access the bone below it. Using a laser prevents the need for a surgical incision; as such, you’ll experience less irritation to the gum tissues, which is usually the only area of slight discomfort for something like implant placement."
                },
                {
                    title: "Lip and Tongue Ties",
                    desc: "The Biolase soft tissue laser also works well for traditional surgical correction of lip and tongue ties. If your baby cannot nurse well due to a tongue tie (causing difficulty in latching) or your toddler shows signs of delayed speech patterns, a laser can gently release the soft tissue at the base of the tongue to allow for better mobility. Results are immediate and there is little to zero discomfort."
                },
                {
                    title: "Wisdom Tooth Removal",
                    desc: 'Impacted wisdom teeth often require surgical removal to preserve the integrity of their neighboring teeth or because of pain. By incorporating a laser into the planned oral surgery, the tooth can be exposed in a gentler and less invasive manner, allowing for improved care and post-operative comfort during your recovery.'
                }
            ]

        },
        {
            title: "Sleep Apnoea and Snoring",
            intro: "When you sleep, obstructive apnoea is a specific type of disorder that blocks oxygen flow to your lungs and brain. OSA is caused by soft tissues at the back of your mouth — like your soft palate, tonsils, and tongue — physically collapsing against one another and sealing off your upper airway. In turn, your body can’t get enough oxygen while you’re sleeping and your blood pressure and overall body inflammation begin to increase. OSA can be life-threatening, placing you at a statistically higher risk of heart attack and stroke. Even though you may feel like sleep apnoea and snoring are not a “big” issue to worry about, treating them could potentially save your life.",
            listName: "Common warning signs",
            list: [
                {
                    "title": "Fatigue"
                },
                {
                    "title": "Depression"
                },
                {
                    "title": "Weight gain"
                },
                {
                    "title": "TMJ disorder"
                },
                {
                    "title": "Ear pain"
                },
                {
                    "title": "Flat, worn teeth"
                },
                {
                    "title": "Broken dental work"
                },
                {
                    "title": "High blood pressure"
                },
                {
                    "title": "Large neck circumference"
                },
                {
                    "title": "Waking up tired"
                }
            ]
        },
        {
            title: "Gum Disease Treatment",
            intro: "If you're experiencing signs of gum disease, it's crucial to seek treatment from a periodontist. These signs may include bleeding gums, gum recession, long teeth, halitosis (chronic bad breath), heavy tartar buildup, swollen, sensitive gums, spaces between teeth, food trapping between teeth, and tooth mobility or loss.During your examination, diagnostic X-rays and specialized tools will be used to assess the attachment levels of your gum and bone tissues around each tooth. Depending on the extent of structural loss or detachment, you may fall into categories such as healthy gums, gingivitis, or varying degrees of periodontal disease, ranging from mild to severe. Seeking timely treatment from a periodontist can help address these issues and preserve your oral health.",
            listName: "Some Treatment Options",
            list: [
                {
                    title: "Deep Cleaning",
                    desc: "Routine six-month cleanings are meant to prevent the onset of gum disease. But in the instance of heavy tartar buildup and gum pocketing, a series of deep cleanings are the only way to remove the bacteria responsible for the infection. Most deep cleanings are spaced out over two or more appointments since we will numb the affected teeth for optimal comfort."
                },
                {
                    title: "Pinhole Gum Rejuvenation",
                    desc: "Pinhole gum surgery is a cosmetic and soft tissue therapy technique that addresses areas of gum recession. Instead of a graft, the Pinhole technique gently tugs the gum tissues adjacent to the tooth to cover exposed root surfaces."
                },
                {
                    title: "Laser Dentistry for Gum Disease",
                    desc: "Laser dentistry (particularly soft tissue therapy) is an effective add-on for treating periodontal infections. When we combine laser therapy with your deep cleaning procedure, we can further eliminate and reduce the number of infectious bacteria present underneath delicate gum tissues."
                },
                {
                    title: "Periodontal Maintenance",
                    desc: "Once we’ve stabilized your periodontal infection, you’ll want to plan on a maintenance cleaning every 3-4 months to prevent relapse and further bone or tooth loss."
                },
                {
                    title: "Bone Grafting",
                    desc: 'If significant bone loss has occurred due to advanced periodontal disease, it may be necessary to place a bone graft to reinforce the root of the adjacent tooth/teeth.'
                },

            ]
        },
        {
            title: "Routine Dental Checkup",
            intro: "There’s nothing “routine” about a routine dental checkup. Although these intermittent visits seem like a normal, everyday activity, they’re integral in promoting good oral health for decades to come. When you incorporate our onsite CBCT (in dentistry) and 3D imaging equipment, we can map out your care for a precise and predictable dental implant experience.What most people don’t realize is that by keeping routine dental checkups, we can prevent major dental needs like tooth decay, bone loss, periodontal disease, fractured teeth, and decay. By equipping you with the resources you need to care for your smile — and routinely assessing your oral health — you can maintain a natural set of teeth for an entire lifetime. Even if your parents lost their teeth at a young age, preventative dentistry can help break the cycle for you and your children.",
            listName: "Symptoms of Gum Disease",
            list: [
                {
                    "title": "Bleeding when you brush or floss"
                },
                {
                    "title": "Tartar buildup"
                },
                {
                    "title": "Halitosis (bad breath)"
                },
                {
                    "title": "Sensitive gum tissues"
                },
                {
                    "title": "Receding gums"
                },
                {
                    "title": "Spaces between teeth"
                },
                {
                    "title": "Bone loss seen on your X-rays"
                },
                {
                    "title": "Detached gum tissues"
                }
            ]

        }


    ]

    useEffect(() => {
        console.log(activeDentistry);
        if (activeDentistry && activeDentistry !== activeInfo?.title) {
            const foundService = services.find(service => service.title === activeDentistry);
            setActiveInfo(foundService);
        }
    }, [activeDentistry, activeInfo?.title, services]);


    return (
        <>
            {activeInfo && (
                <>
                    {/* <img src="/images/girl.jpg" style={{ width: '100%', marginBottom: "50px" }} /> */}
                    <Container>
                        <Row className="justify-content-md-center" style={{marginTop: "150px"}}>
                            <Col md="auto" style={{ textAlign: "center" }}>
                                <div className="home_content">
                                    <div className="home_subtitle" style={{ marginBottom: "15px" }}>What is {activeInfo.title}</div>
                                    <div className="home_text">
                                        <p>{activeInfo.intro}</p>

                                    </div>

                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center" style={{ marginTop: "50px" }}>
                            <Col md="auto" style={{ textAlign: "center" }}>
                                <div className="home_content">
                                    <div className="home_subtitle" style={{ marginBottom: "15px" }}>{activeInfo.listName}</div>
                                </div>
                            </Col>
                        </Row>

                        <div className="row services_row">
                            {activeInfo.list.map((item, index) => (
                                <div className="col-xl-4 col-md-6 service_col" >
                                    <div className="service text-center">
                                        <div className="service">
                                            <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                                <div className="icon"><img src="images/icon_4.svg" alt="https://www.flaticon.com/authors/prosymbols" /></div>
                                            </div>
                                            <div className="service_title" style={{ fontSize: "15px", marginBottom: "10px" }}>{item.title}</div>
                                            <p>{item.desc}</p>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Container>
                </>
            )}
        </>
    )
}
export default DentistryInfo