import React, { useState } from 'react';
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import hero from "./banner.jpg"


function Contact() {

    const countries = [
        { label: "Afghanistan", value: "93" },
        { label: "Åland Islands", value: "358" },
        { label: "Albania", value: "355" },
        { label: "Algeria", value: "213" },
        { label: "American Samoa", value: "1-684" },
        { label: "Andorra", value: "376" },
        { label: "Angola", value: "244" },
        { label: "Anguilla", value: "1-264" },
        { label: "Antarctica", value: "672" },
        { label: "Antigua and Barbuda", value: "1-268" },
        { label: "Argentina", value: "54" },
        { label: "Armenia", value: "374" },
        { label: "Aruba", value: "297" },
        { label: "Australia", value: "61" },
        { label: "Austria", value: "43" },
        { label: "Azerbaijan", value: "994" },
        { label: "Bahamas", value: "1-242" },
        { label: "Bahrain", value: "973" },
        { label: "Bangladesh", value: "880" },
        { label: "Barbados", value: "1-246" },
        { label: "Belarus", value: "375" },
        { label: "Belgium", value: "32" },
        { label: "Belize", value: "501" },
        { label: "Benin", value: "229" },
        { label: "Bermuda", value: "1-441" },
        { label: "Bhutan", value: "975" },
        { label: "Bolivia (Plurinational State of)", value: "591" },
        { label: "Bonaire, Sint Eustatius and Saba", value: "599" },
        { label: "Bosnia and Herzegovina", value: "387" },
        { label: "Botswana", value: "267" },
        { label: "Bouvet Island", value: "47" },
        { label: "Brazil", value: "55" },
        { label: "British Indian Ocean Territory", value: "246" },
        { label: "Brunei Darussalam", value: "673" },
        { label: "Bulgaria", value: "359" },
        { label: "Burkina Faso", value: "226" },
        { label: "Burundi", value: "257" },
        { label: "Cabo Verde", value: "238" },
        { label: "Cambodia", value: "855" },
        { label: "Cameroon", value: "237" },
        { label: "Canada", value: "1" },
        { label: "Cayman Islands", value: "1-345" },
        { label: "Central African Republic", value: "236" },
        { label: "Chad", value: "235" },
        { label: "Chile", value: "56" },
        { label: "China", value: "86" },
        { label: "Christmas Island", value: "61" },
        { label: "Cocos (Keeling) Islands", value: "61" },
        { label: "Colombia", value: "57" },
        { label: "Comoros", value: "269" },
        { label: "Congo", value: "242" },
        { label: "Congo (Democratic Republic of the)", value: "243" },
        { label: "Cook Islands", value: "682" },
        { label: "Costa Rica", value: "506" },
        { label: "Croatia", value: "385" },
        { label: "Cuba", value: "53" },
        { label: "Curaçao", value: "599" },
        { label: "Cyprus", value: "357" },
        { label: "Czech Republic", value: "420" },
        { label: "Denmark", value: "45" },
        { label: "Djibouti", value: "253" },
        { label: "Dominica", value: "1-767" },
        { label: "Dominican Republic", value: "1-809, 1-829, 1-849" },
        { label: "Ecuador", value: "593" },
        { label: "Egypt", value: "20" },
        { label: "El Salvador", value: "503" },
        { label: "Equatorial Guinea", value: "240" },
        { label: "Eritrea", value: "291" },
        { label: "Estonia", value: "372" },
        { label: "Eswatini", value: "268" },
        { label: "Ethiopia", value: "251" },
        { label: "Falkland Islands (Malvinas)", value: "500" },
        { label: "Faroe Islands", value: "298" },
        { label: "Fiji", value: "679" },
        { label: "Finland", value: "358" },
        { label: "France", value: "33" },
        { label: "French Guiana", value: "594" },
        { label: "French Polynesia", value: "689" },
        { label: "French Southern Territories", value: "262" },
        { label: "Gabon", value: "241" },
        { label: "Gambia", value: "220" },
        { label: "Georgia", value: "995" },
        { label: "Germany", value: "49" },
        { label: "Ghana", value: "233" },
        { label: "Gibraltar", value: "350" },
        { label: "Greece", value: "30" },
        { label: "Greenland", value: "299" },
        { label: "Grenada", value: "1-473" },
        { label: "Guadeloupe", value: "590" },
        { label: "Guam", value: "1-671" },
        { label: "Guatemala", value: "502" },
        { label: "Guernsey", value: "44-1481" },
        { label: "Guinea", value: "224" },
        { label: "Guinea-Bissau", value: "245" },
        { label: "Guyana", value: "592" },
        { label: "Haiti", value: "509" },
        { label: "Heard Island and McDonald Islands", value: "672" },
        { label: "Holy See", value: "379" },
        { label: "Honduras", value: "504" },
        { label: "Hong Kong", value: "852" },
        { label: "Hungary", value: "36" },
        { label: "Iceland", value: "354" },
        { label: "India", value: "91" },
        { label: "Indonesia", value: "62" },
        { label: "Iran (Islamic Republic of)", value: "98" },
        { label: "Iraq", value: "964" },
        { label: "Ireland", value: "353" },
        { label: "Isle of Man", value: "44-1624" },
        { label: "Israel", value: "972" },
        { label: "Italy", value: "39" },
        { label: "Jamaica", value: "1-876" },
        { label: "Japan", value: "81" },
        { label: "Jersey", value: "44-1534" },
        { label: "Jordan", value: "962" },
        { label: "Kazakhstan", value: "7" },
        { label: "Kenya", value: "254" },
        { label: "Kiribati", value: "686" },
        { label: "Korea (Democratic People's Republic of)", value: "850" },
        { label: "Korea (Republic of)", value: "82" },
        { label: "Kuwait", value: "965" },
        { label: "Kyrgyzstan", value: "996" },
        { label: "Lao People's Democratic Republic", value: "856" },
        { label: "Latvia", value: "371" },
        { label: "Lebanon", value: "961" },
        { label: "Lesotho", value: "266" },
        { label: "Liberia", value: "231" },
        { label: "Libya", value: "218" },
        { label: "Liechtenstein", value: "423" },
        { label: "Lithuania", value: "370" },
        { label: "Luxembourg", value: "352" },
        { label: "Macao", value: "853" },
        { label: "Madagascar", value: "261" },
        { label: "Malawi", value: "265" },
        { label: "Malaysia", value: "60" },
        { label: "Maldives", value: "960" },
        { label: "Mali", value: "223" },
        { label: "Malta", value: "356" },
        { label: "Marshall Islands", value: "692" },
        { label: "Martinique", value: "596" },
        { label: "Mauritania", value: "222" },
        { label: "Mauritius", value: "230" },
        { label: "Mayotte", value: "262" },
        { label: "Mexico", value: "52" },
        { label: "Micronesia (Federated States of)", value: "691" },
        { label: "Moldova (Republic of)", value: "373" },
        { label: "Monaco", value: "377" },
        { label: "Mongolia", value: "976" },
        { label: "Montenegro", value: "382" },
        { label: "Montserrat", value: "1-664" },
        { label: "Morocco", value: "212" },
        { label: "Mozambique", value: "258" },
        { label: "Myanmar", value: "95" },
        { label: "Namibia", value: "264" },
        { label: "Nauru", value: "674" },
        { label: "Nepal", value: "977" },
        { label: "Netherlands", value: "31" },
        { label: "New Caledonia", value: "687" },
        { label: "New Zealand", value: "64" },
        { label: "Nicaragua", value: "505" },
        { label: "Niger", value: "227" },
        { label: "Nigeria", value: "234" },
        { label: "Niue", value: "683" },
        { label: "Norfolk Island", value: "672" },
        { label: "North Macedonia", value: "389" },
        { label: "Northern Mariana Islands", value: "1-670" },
        { label: "Norway", value: "47" },
        { label: "Oman", value: "968" },
        { label: "Pakistan", value: "92" },
        { label: "Palau", value: "680" },
        { label: "Palestine, State of", value: "970" },
        { label: "Panama", value: "507" },
        { label: "Papua New Guinea", value: "675" },
        { label: "Paraguay", value: "595" },
        { label: "Peru", value: "51" },
        { label: "Philippines", value: "63" },
        { label: "Pitcairn", value: "64" },
        { label: "Poland", value: "48" },
        { label: "Portugal", value: "351" },
        { label: "Puerto Rico", value: "1-787, 1-939" },
        { label: "Qatar", value: "974" },
        { label: "Réunion", value: "262" },
        { label: "Romania", value: "40" },
        { label: "Russian Federation", value: "7" },
        { label: "Rwanda", value: "250" },
        { label: "Saint Barthélemy", value: "590" },
        { label: "Saint Helena, Ascension and Tristan da Cunha", value: "290" },
        { label: "Saint Kitts and Nevis", value: "1-869" },
        { label: "Saint Lucia", value: "1-758" },
        { label: "Saint Martin (French part)", value: "590" },
        { label: "Saint Pierre and Miquelon", value: "508" },
        { label: "Saint Vincent and the Grenadines", value: "1-784" },
        { label: "Samoa", value: "685" },
        { label: "San Marino", value: "378" },
        { label: "Sao Tome and Principe", value: "239" },
        { label: "Saudi Arabia", value: "966" },
        { label: "Senegal", value: "221" },
        { label: "Serbia", value: "381" },
        { label: "Seychelles", value: "248" },
        { label: "Sierra Leone", value: "232" },
        { label: "Singapore", value: "65" },
        { label: "Sint Maarten (Dutch part)", value: "1-721" },
        { label: "Slovakia", value: "421" },
        { label: "Slovenia", value: "386" },
        { label: "Solomon Islands", value: "677" },
        { label: "Somalia", value: "252" },
        { label: "South Africa", value: "27" },
        { label: "South Georgia and the South Sandwich Islands", value: "500" },
        { label: "South Sudan", value: "211" },
        { label: "Spain", value: "34" },
        { label: "Sri Lanka", value: "94" },
        { label: "Sudan", value: "249" },
        { label: "Suriname", value: "597" },
        { label: "Svalbard and Jan Mayen", value: "47" },
        { label: "Sweden", value: "46" },
        { label: "Switzerland", value: "41" },
        { label: "Syrian Arab Republic", value: "963" },
        { label: "Taiwan", value: "886" },
        { label: "Tajikistan", value: "992" },
        { label: "Tanzania, United Republic of", value: "255" },
        { label: "Thailand", value: "66" },
        { label: "Timor-Leste", value: "670" },
        { label: "Togo", value: "228" },
        { label: "Tokelau", value: "690" },
        { label: "Tonga", value: "676" },
        { label: "Trinidad and Tobago", value: "1-868" },
        { label: "Tunisia", value: "216" },
        { label: "Turkey", value: "90" },
        { label: "Turkmenistan", value: "993" },
        { label: "Turks and Caicos Islands", value: "1-649" },
        { label: "Tuvalu", value: "688" },
        { label: "Uganda", value: "256" },
        { label: "Ukraine", value: "380" },
        { label: "United Arab Emirates", value: "971" },
        { label: "United Kingdom of Great Britain and Northern Ireland", value: "44" },
        { label: "United States of America", value: "1" },
        { label: "Uruguay", value: "598" },
        { label: "Uzbekistan", value: "998" },
        { label: "Vanuatu", value: "678" },
        { label: "Venezuela (Bolivarian Republic of)", value: "58" },
        { label: "Viet Nam", value: "84" },
        { label: "Wallis and Futuna", value: "681" },
        { label: "Western Sahara", value: "212" },
        { label: "Yemen", value: "967" },
        { label: "Zambia", value: "260" },
        { label: "Zimbabwe", value: "263" }
    ]

    const branches = [
        {
            id: "1", branch: "Sharjah Branch", map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14421.875321561089!2d55.4235301!3d25.3555992!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f59749f0de9c1%3A0xaa6f3287e2d5e7e!2sCosmo%20Health%20Medical%20Center!5e0!3m2!1sen!2sae!4v1707290602729!5m2!1sen!2sae", location: "Villa B 568, Culture Roundabout, Al Ramla, Sharjah", number: "971 65 669 910", instagram: ""
        },
        {
            id: "2", branch: "Ajman Branch", map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14414.86765910823!2d55.4863548!3d25.4142848!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f59223e723595%3A0xb12c8fe8c125dd60!2sCosmo%20Health%20Medical%20Center%20-%20Ajman!5e0!3m2!1sen!2sae!4v1707290757613!5m2!1sen!2sae", location: "V5, Al Ittihad road 47755 11687 Jurf 2, Ajman", number: "971 44 150 515"
        },
        {
            id: "3", branch: "Ras Al Khaimah Branch", map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14379.018010712132!2d55.8380658!3d25.7125511!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef60b93daa01bf1%3A0x497cb8476f8cae4e!2sCosmo%20Health%20Medical%20Center%20-%20Ras%20Al%20Khaimah!5e0!3m2!1sen!2sae!4v1707290832194!5m2!1sen!2sae", location: "Al Ettihad Street - Alrafaea", number: "971 44 150 544"
        },
        {
            id: "4", branch: "Marina Branch", map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14453.873775678543!2d55.1373551375122!3d25.08599943731932!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b358665d02d%3A0xeb5453e8bca077af!2sCosmo%20health%20Medical%20Center%20-%20Marina!5e0!3m2!1sen!2sae!4v1707290893749!5m2!1sen!2sae", location: "Dusit Princess Residence, Marina, Al Emreef Street", number: "971 44 150 500"
        },
        {
            id: "5", branch: "Jumeirah Branch", map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14444.134015971928!2d55.21556618511962!3d25.168346536461776!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b891c64ecbf%3A0xca1cfc9eed7b83fb!2sCosmo%20Health%20Jumeirah!5e0!3m2!1sen!2sae!4v1707290993386!5m2!1sen!2sae", location: "890 Al Wasl Road, Umm Suqeim, Umm Suqeim 1", number: "971 4 4150505"
        },
        {
            id: "6", branch: "Mirdif Branch", map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14436.478764745656!2d55.4324124!3d25.2328933!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f61309cc93209%3A0x6a2cb2eac6446dba!2sCosmo%20Health%20Medical%20Center%20(Dental%20%26%20Dermatology)-%20Mirdif!5e0!3m2!1sen!2sae!4v1707291092676!5m2!1sen!2sae", location: "2nd floor, Avenue Mall, Mirdif", number: "971 44 150 550"
        }
    ]
    const [selectedBranch, setSelectedBranch] = useState(branches[0]);

    const changeBranch = (x) => {
        setSelectedBranch(branches[x])
    }

    return (
        <>
        <div className="intro_image">
                <img src={hero} alt="Liposuction" style={{ zIndex: "-1" }} />
            </div>
        <div className="contact">
            
            <div className="container">
                <div className="row" style={{ marginBottom: "50px" }}>
                    <div className="col-lg-2">
                        <button style={{ borderRadius: "0px", backgroundColor: 'white', borderColor: "white", color: '#ab7f21', fontSize: "12px" }} className="button button_1 contact_button trans_200" onClick={() => changeBranch(0)}>Sharjah Branch</button>

                    </div>
                    <div className="col-lg-2">
                        <button style={{ borderRadius: "0px", backgroundColor: 'white', borderColor: "white", color: '#ab7f21', fontSize: "12px" }} className="button button_1 contact_button trans_200" onClick={() => changeBranch(1)}>Ajman Branch</button>

                    </div>
                    <div className="col-lg-2">
                        <button style={{ borderRadius: "0px", backgroundColor: 'white', borderColor: "white", color: '#ab7f21', fontSize: "12px" }} className="button button_1 contact_button trans_200" onClick={() => changeBranch(2)}>Ras Al Khaimah Branch</button>

                    </div>
                    <div className="col-lg-2">
                        <button style={{ borderRadius: "0px", backgroundColor: 'white', borderColor: "white", color: '#ab7f21', fontSize: "12px" }} className="button button_1 contact_button trans_200" onClick={() => changeBranch(3)}>Marina Branch</button>

                    </div>
                    <div className="col-lg-2">
                        <button style={{ borderRadius: "0px", backgroundColor: 'white', borderColor: "white", color: '#ab7f21', fontSize: "12px" }} className="button button_1 contact_button trans_200" onClick={() => changeBranch(4)}>Jumeirah Branch</button>

                    </div>
                    <div className="col-lg-2">
                        <button style={{ borderRadius: "0px", backgroundColor: 'white', borderColor: "white", color: '#ab7f21', fontSize: "12px" }} className="button button_1 contact_button trans_200" onClick={() => changeBranch(5)}>Mirdif Branch</button>

                    </div>
                </div>
                <div className="row">

                    {/* Left Column */}


                    {/* Right Column */}
                    <div className="col-lg-5  contact_col" style={{ marginBottom: "50px" }}>
                        <div className="contact_content">
                            <div className="contact_content_title" style={{ marginTop: "-3px" }}>{selectedBranch.branch}</div>
                            {/* <div className="contact_content_text">
                                <p>Odio ultrices ut. Etiam ac erat ut enim maximus accumsan vel ac nisl. Duis feugiat bibendum orci, non elementum urna. Cras sit amet sapien aliquam.</p>
                            </div> */}
                            {/* <div className="direct_line d-flex flex-row align-items-center justify-content-start" style={{borderRadius: "0px", backgroundColor: 'white', borderColor: "#ab7f21", color: '#ab7f21'}}>
                                <div className="direct_line_title text-center" style={{backgroundColor: "white", color: "#ab7f21"}}>Direct Line</div>
                                <div className="direct_line_num text-center" style={{backgroundColor: "white", color: "#ab7f21"}}>{selectedBranch.number}</div>
                            </div> */}
                            <div className="contact_info">
                                <ul>
                                    <li className="d-flex flex-row align-items-start justify-content-start">
                                        <div>Direct Line:</div>
                                        <div>{selectedBranch.number}</div>
                                    </li>
                                    <li className="d-flex flex-row align-items-start justify-content-start">
                                        <div>Address</div>
                                        <div>{selectedBranch.location}</div>
                                    </li>
                                    {/* <li className="d-flex flex-row align-items-start justify-content-start">
                                        <div>Phone</div>
                                        <div>+53 345 7953 32453</div>
                                    </li> */}
                                    {/* <li className="d-flex flex-row align-items-start justify-content-start">
                                        <div>E-mail</div>
                                        <div><a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="d9a0b6acabb4b8b0b599beb4b8b0b5f7bab6b4">[email&#160;protected]</a></div>
                                    </li> */}
                                </ul>
                            </div>

                            <div className="social header_social" style={{ marginTop: "20px" }}>
                                <ul className="d-flex flex-row align-items-center justify-content-start">
                                    <li><a target="_blank" href="https://www.instagram.com/cosmohealthmc/"><i className="fa fa-facebook" aria-hidden="true"><FaInstagram /></i></a></li>
                                    <li><a target="_blank" href="https://www.facebook.com/CosmoHealthMedicalCenter/"><i className="fa fa-facebook" aria-hidden="true"><FaFacebookF /></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <h5 style={{ textAlign: "center", fontSize: "24px", fontWeight: "600", color: "#ab7f21" }}>Contact Us</h5>
                        <div className="contact_form_container" >

                            {/* <div className="contact_form_title" style={{backgroundColor: "white", color: "#ab7f21", marginLeft: "30px"}}>Contact Us</div> */}
                            <form action="#" className="contact_form" id="contact_form" style={{ marginTop: "-100px" }}>
                                <div className="d-flex flex-row align-items-start justify-content-between flex-wrap">
                                    <input type="text" className="contact_input" placeholder="Your Name" required />
                                    <input type="email" className="contact_input" placeholder="Your E-mail" required />
                                    <select className="contact_select contact_input" required >
                                        <option selected>United Arab Emirates +971</option>
                                        {countries.map((country, index) => (
                                            <option>{country.label} +{country.value}</option>
                                        ))}


                                    </select>
                                    <input type="tel" className="contact_input" placeholder="Your Phone" required minLength={9} maxLength={9} />

                                    <textarea type="text" className="contact_input" placeholder="Your message" required />

                                    {/* <input type="text" id="datepicker" className="contact_input datepicker" placeholder="Date" required /> */}
                                </div>
                                <button className="button button_1 contact_button trans_200" style={{ borderRadius: "0px", backgroundColor: '#ab7f21', borderColor: "#ab7f21", color: 'white' }}>make an appointment</button>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
            <div className='row' style={{ marginTop: "100px" }}>
                <iframe
                    src={selectedBranch.map}

                    style={{ border: 0, width: "100%", height: "500px" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Cosmo Health Medical Center Mirdif Map"
                ></iframe>
            </div>

        </div>
        </>
    )

}
export default Contact